import * as _usr_const from '../../../config/usr-constant';
import * as _form from '../../../helper/form';
import * as _debug from '../../../helper/debug';
import axios from 'axios';

/**
 * getAddParams
 * 
 * @param obj 
 */
type USersGetAddParamsProps = {
  unmounted: any;
  formData: any;
  setFormData: any;
  setLoadings: any;
  setRoles: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>;
  setRoleNames: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>;
  setRoleActions: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>;
  setPersonalRole: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>;
  setAuthorities: React.Dispatch<React.SetStateAction<{}>>;
  setStatuses: React.Dispatch<React.SetStateAction<{}>>;
  setRoleValues: React.Dispatch<React.SetStateAction<{ [key: string]: boolean; }>>;
  setStatusesError: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
export function getAddParams({
  unmounted,
  formData,
  setFormData,
  setLoadings,
  setRoles,
  setRoleNames,
  setRoleActions,
  setPersonalRole,
  setAuthorities,
  setStatuses,
  setRoleValues,
  setStatusesError,
  setLoading
}: USersGetAddParamsProps): any {
  // get data
  axios
    .get(
      _usr_const.ApiUrl + 'users/add/index.json'
    )
    .then((response) => {
      if (typeof response.data !== 'undefined') {
        if (!unmounted.current) {

          let tmpFormData: any = Object.assign({}, formData);
          let roleValues: { [key: string]: boolean; } = {};
          let personal_role: { [key: string]: string; } = {};

          // set roles values
          if (Object.keys(response.data.roles).length > 0) {
            Object.keys(response.data.roles).forEach((id: string) => {
              if (id === '1') {
                roleValues[id] = true;
              } else {
                roleValues[id] = false;
              }
            });
          }
          // set personal role
          if (Object.keys(response.data.role_names).length > 0) {
            Object.keys(response.data.role_names).forEach((roleName: string) => {
              let tmpPersonalRole: any = {};
              if (Object.keys(response.data.role_actions).length > 0) {
                Object.keys(response.data.role_actions).forEach((roleAction: string) => {
                  let personal_role: string = 'none';
                  if (roleAction === 'delete') {
                    personal_role = 'disable';
                  }
                  tmpPersonalRole[roleAction] = personal_role;
                });
              }
              personal_role[roleName] = tmpPersonalRole;
            });
          }
          tmpFormData.personal_role = personal_role;

          setRoles(response.data.roles);
          setRoleNames(response.data.role_names);
          setRoleActions(response.data.role_actions);
          setPersonalRole(response.data.personal_role);
          setAuthorities(response.data.authorities);
          setStatuses(response.data.user_status);
          setRoleValues(roleValues);
          setFormData({ ...formData, personal_role });
          setLoadings({
            roles: false,
            roleNames: false,
            authorities: false,
            statuses: false,
          });
          setRoleValuesToFormData({
            roleValues,
            formData: tmpFormData,
            setFormData
          });
          setLoading(false);
        }
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
      if (!unmounted.current) {
        setStatusesError(true);
      }
    });
}

/**
 * getEditParams
 * 
 * @param obj 
 */
type UsersGetEditParamsProps = {
  unmounted: any;
  actionName: string;
  id: string;
  formData: any;
  setFormData: any;
  setLoadings: any;
  setRoles: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>;
  setRoleNames: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>;
  setRoleActions: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>;
  setPersonalRole: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>;
  setAuthorities: React.Dispatch<React.SetStateAction<{}>>;
  setStatuses: React.Dispatch<React.SetStateAction<{}>>;
  setRoleValues: React.Dispatch<React.SetStateAction<{ [key: string]: boolean; }>>;
  setStatusesError: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setdataMounted: React.Dispatch<React.SetStateAction<boolean>>;
}
export function getEditParams({
  unmounted,
  actionName,
  id,
  formData,
  setFormData,
  setLoadings,
  setRoles,
  setRoleNames,
  setRoleActions,
  setPersonalRole,
  setAuthorities,
  setStatuses,
  setRoleValues,
  setStatusesError,
  setLoading,
  setdataMounted
}: UsersGetEditParamsProps): void {
  // set data
  setLoading(true);
  axios
    .get(_usr_const.ApiUrl + actionName + '/' + id + '.json')
    .then((response: any) => {
      if (!unmounted.current) {
        const responseRoleValues: any = response.data.user.roles;
        // set form data
        const tmpFormData: any = _form.setFormData(formData, response.data.user);
        tmpFormData.roles = {
          _ids: []
        };
        // format personal role
        if (typeof tmpFormData.personal_role === 'string' && tmpFormData.personal_role !== '') {
          tmpFormData.personal_role = JSON.parse(tmpFormData.personal_role); 
        } else {
          tmpFormData.personal_role = {};
        }
        // set roles value
        let tmpRoleValues: string[] = [];
        if (responseRoleValues.length > 0) {
          responseRoleValues.forEach((element: any) => {
            tmpRoleValues.push(String(element.id));
          });
        }
        let roleValues: any = {};
        if (Object.keys(response.data.roles).length > 0) {
          Object.keys(response.data.roles).forEach((id: string) => {
            if (tmpRoleValues.indexOf(id) !== -1) {
              roleValues[id] = true;
            } else {
              roleValues[id] = false;
            }
          });
        }

        setRoles(response.data.roles);
        setRoleNames(response.data.role_names);
        setRoleActions(response.data.role_actions);
        setPersonalRole(response.data.personal_role);
        setAuthorities(response.data.authorities);
        setStatuses(response.data.user_status);
        setRoleValues(roleValues);
        setFormData(tmpFormData);
        setLoadings({
          roles: false,
          roleNames: false,
          authorities: false,
          statuses: false,
        });
        setRoleValuesToFormData({
          roleValues,
          formData: tmpFormData,
          setFormData
        });
        setdataMounted(true);
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
      if (!unmounted.current) {
        setStatusesError(true);
      }
    })
    .finally(() => {
      if (!unmounted.current) {
        setLoading(false);
      }
    });
}

/**
 * setRoleValuesToFormData
 * 
 * @param obj 
 */
type UsersSetRoleValuesToFormDataProps = {
  roleValues: { [key: string]: boolean; };
  formData: any;
  setFormData: any;
}
export function setRoleValuesToFormData({
  roleValues,
  formData,
  setFormData
}: UsersSetRoleValuesToFormDataProps): void {
  let ids: any = [];
  Object.keys(roleValues).forEach((id: string) => {
    if (roleValues[id] === true) {
      ids.push(id)
    }
  });
  let tmpFormData: any = formData;
  tmpFormData.roles._ids = ids;
  setFormData(tmpFormData);
}

/**
 * handlePersonalRoleChange
 * 
 * @param obj 
 * @param event 
 */
type UsersSetHandlePersonalRoleChangeProps = {
  formData: any;
  setFormData: any;
  event: any;
}
export function handlePersonalRoleChange({
  formData,
  setFormData,
  event
}: UsersSetHandlePersonalRoleChangeProps): void {
  let personal_role: any = Object.assign({}, formData.personal_role);
  const name: string = event.target.name;
  const splitName: string[] = name.split('-');
  if (personal_role[splitName[0]] === undefined) {
    personal_role[splitName[0]] = {};
  }
  personal_role[splitName[0]][splitName[1]] = event.target.value;
  setFormData({ ...formData, personal_role });
}