import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import { ValidateErrorsProps } from '../../types/state';
import AttributeGroupCategorySelect from '../../components/Form/AttributeGroupCategorySelect';
import AttributeGroupConditionInputs, { AttributeGroupConditionProps } from '../../components/Form/AttributeGroupConditionInputs';
import AttributeGroupConditionPreview from '../../components/Form/AttributeGroupConditionPreview';
import Paper from '@material-ui/core/Paper';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';
import axios from 'axios';

type formDataProps = {
  attribute_group_category_id: string | number;
  name: string;
  memo: string;
  conditions: AttributeGroupConditionProps[];
}

export default function AttributeGroupsAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const backActionName: string = '/attribute-groups';

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [formData, setFormData] = useState<formDataProps>({
    attribute_group_category_id: '',
    name: '',
    memo: '',
    conditions: []
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleChangeConditions = (conditions: AttributeGroupConditionProps[]): void => {
    setFormData({
      ...formData,
      conditions
    });
  }

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'attribute-groups',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const handleSelectAttributeGroupCategory = (node: any): void => {
    setFormData({ ...formData, attribute_group_category_id: node.id });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="attribute-groups" className="content-1">
      <form onSubmit={handleSubmit}>
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in form-content">
              <table className="form-table">
                <tbody>
                  <tr>
                    <th>カテゴリ</th>
                    <td>
                      <div className="form-input-group">
                        <AttributeGroupCategorySelect
                          name="attribute_group_category_id"
                          handleSelectAttributeGroupCategory={handleSelectAttributeGroupCategory}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="required-th">グループ名称</th>
                    <td>
                      <div className="form-input-group">
                        <ZshTextField
                          value={formData.name}
                          name="name"
                          handleChange={handleChange}
                          required
                          validateErrors={validateErrors}
                          fullWidth
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>メモ</th>
                    <td>
                      <div className="form-input-group">
                        <ZshTextField
                          value={formData.memo}
                          name="memo"
                          handleChange={handleChange}
                          validateErrors={validateErrors}
                          fullWidth
                          multiline={true}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <AttributeGroupConditionInputs
            conditions={formData.conditions}
            onChange={handleChangeConditions}
          />
          <AttributeGroupConditionPreview
            conditions={formData.conditions}
          />
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
        </div>
      </form>
    </div>
  )
}