import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import { debugAxiosError } from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { User } from '../../types/model';

type UserAuthInformation = {
  authority?: number;
}

export default function UsersView({ history, match }: RouteComponentProps<{ id: string }>) {

  const authInformationsSelector: any = useSelector(state => state.AuthInformations);

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<User>({
    id: 0,
    authority: undefined,
    username_display: '',
    authority_display: '',
    status_display: '',
    roles: [],
    account_locked: false,
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    common_parent: undefined,
    user_roles: {}
  });
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);
  const [roleActions, setRoleActions] = useState<{ [key: string]: string; }>({});
  const [roleNames, setRoleNames] = useState<{ [key: string]: string }>({});
  const [openRoles, setOpenRoles] = useState<boolean>(false);
  const [authInformations, setAuthInformations] = useState<UserAuthInformation | undefined>(undefined);
  const [btnActions, setBtnActions] = useState<HeadButtonGroupBtnActionsProps[] | undefined>(undefined);

  const handleRolesClose = (): void => {
    setOpenRoles(false);
  }

  const handleRolesOpen = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    setOpenRoles(true);
  }

  const handleEdit = useCallback(() => {
    history.push('/users/' + match.params.id + '/edit');
  }, [history,  match.params.id]);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<User>({
        unmounted,
        source,
        dataKey: 'user',
        path: _usr_const.ApiUrl + 'users/' + match.params.id + '.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading,
        successCallbackBeforeSetData: (results) => {
          if (typeof results.data.role_actions !== 'undefined') {
            setRoleActions(results.data.role_actions); 
          } else {
            setRoleActions({});
          }
          if (typeof results.data.role_names !== 'undefined') {
            setRoleNames(results.data.role_names); 
          } else {
            setRoleNames({});
          }
        }
      })
        .catch((error: any) => {
          debugAxiosError(error);
        });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  // set auth informaitons
  useEffect(() => {
    if (authInformations === undefined) {
      if (authInformationsSelector !== undefined) {
        authInformationsSelector.forEach((element: any) => {
          if (element.isLoded) {
            if (element.error === undefined && element.data.auth_informations !== undefined) {
              if (authInformations !== element.data.auth_informations) {
                setAuthInformations(element.data.auth_informations);
              }
            } else {
              if (typeof element.error !== 'undefined') {
                console.log(element.error); 
              }
            }
          }
        });
      } 
    }
  },
    [
      authInformationsSelector,
      authInformations
    ]
  );

  useEffect(() => {
    if (
      typeof authInformations === 'object' && 
      btnActions === undefined &&
      typeof data.authority !== 'undefined'
    ) {
      let tmpBtnActions: HeadButtonGroupBtnActionsProps[] = [];
      if (typeof authInformations.authority !== 'undefined') {
        if (authInformations.authority >= data.authority) {
          tmpBtnActions = [
            {
              type: 'edit',
              func: handleEdit,
              role: {
                name: 'Users',
                action: 'edit',
              },
            }
          ];
        }
      }
      setBtnActions(tmpBtnActions);
    }
  }, [
    authInformations,
    btnActions,
    data.authority,
    dataMounted,
    handleEdit
  ]);
  
  const hrefLink: string = '#';

  return (
    <div id="users" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <th>ユーザー名</th>
                    <td>{data.username_display}</td>
                  </tr>
                  <tr>
                    <th>ユーザー区分</th>
                    <td>{data.authority_display}</td>
                  </tr>
                  <tr>
                    <th>ステータス</th>
                    <td>{data.status_display}</td>
                  </tr>
                  <tr>
                    <th>権限グループ</th>
                    <td>
                      <ul className="ul-1">
                        {
                          data.roles &&
                          data.roles.map((role: any) => (
                            <li key={role.id}>
                              <Link to={"/roles/" + role.id}>{role.name}</Link>
                            </li>
                          ))
                        }
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>権限設定</th>
                    <td>
                      <a href={hrefLink} onClick={handleRolesOpen}>表示</a>
                    </td>
                  </tr>
                  <tr>
                    <th>アカウントロック</th>
                    <td>
                      {
                        data.account_locked &&
                        <span className="red">ロック</span>
                      }
                      {
                        data.account_locked === false &&
                        <span>正常</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>姓</th>
                    <td>{data.last_name}</td>
                  </tr>
                  <tr>
                    <th>名</th>
                    <td>{data.first_name}</td>
                  </tr>
                  <tr>
                    <th>姓（カナ）</th>
                    <td>{data.last_name_kana}</td>
                  </tr>
                  <tr>
                    <th>名（カナ）</th>
                    <td>{data.first_name_kana}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          {
            data.common_parent !== undefined && data.common_parent.logs !== undefined &&
            <ZshLogs logs={data.common_parent.logs} />
          }
          <Dialog
            onClose={handleRolesClose}
            open={openRoles}
            fullWidth
          >
            <DialogContent>
              <div>
                {
                  Object.keys(roleNames).map((k: string) => (
                    <div key={k}>
                      <h3>{roleNames[k]}</h3>
                      <table className="table-1">
                        <tbody>
                          {
                            Object.keys(roleActions).map((ak: string) => (
                              <tr key={ak}>
                                <th>{roleActions[ak]}</th>
                                <td>
                                  {(() => {
                                    if (
                                      data.user_roles !== undefined &&
                                      typeof data.user_roles[k] !== 'undefined' &&
                                      data.user_roles[k].indexOf(ak) !== -1
                                    ) {
                                      return <span className="success-text-cl">許可</span>
                                    } else {
                                      return <span className="error-text-cl">拒否</span>
                                    }
                                  })()}
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  ))
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRolesClose} color="primary">
                閉じる
                  </Button>
            </DialogActions>
          </Dialog>
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}