import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Help from '@material-ui/icons/Help';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      fontSize: '12px',
    },
    icon: {
      fontSize: '17px',
      cursor: 'pointer',
    }
  })
);

type ZshTooltipProps = {
  text: string;
}

export default function ZshTooltip({ text }: ZshTooltipProps) {

  const [showText, setShowText] = useState<any>(text);

  useEffect(() => {
    let tmpText: any = text;
    if (text.indexOf('¥n') !== -1) {
      tmpText = text.split('¥n').map((line: string, index: number) => (
        <span key={index}>
          {line}<br />
        </span>
      ));
    }
    setShowText(tmpText);
  }, [text]);

  const classes = useStyles();

  return (
    <div className="zshTooltip">
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        title={
          <React.Fragment>
            {showText}
          </React.Fragment>
        }
        placement="bottom-start"
        classes={{
          tooltip: classes.tooltip,
        }}
      >
        <Help
          className={classes.icon}
        />
      </Tooltip>
    </div>
  )
}