import React from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, {
  DataTableRowsProps,
  DataTableActionsProps,
  DataTableSearchInputParamsProps,
  DataTableActionsFuncProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps } from '../../components/View/DataTableFilters';
import * as _data_actions from '../../helper/dataActions';
import { MailMagazine } from '../../types/model';

export default function MailMagazinesIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const clickCell = (params: DataTableHandleClickCellProps<MailMagazine>): void => {
    history.push(location.pathname + '/' + params.record.id);
  }

  const handleToAdd = (): void => {
    history.push('/mail-magazines/add');
  }

  const confirmDelete = (params: DataTableActionsFuncProps<MailMagazine>): void => {
    if (params.selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete(params);
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = (params: DataTableActionsFuncProps<MailMagazine>): void => {
    _data_actions.deleteData({
      deleteUrl: 'mail-magazines/delete/',
      selected: params.selected,
      getData: params.getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const rows: DataTableRowsProps<MailMagazine>[] = [
    {
      label: 'ID',
      value: 'id',
      sortField: 'MailMagazines.id',
      align: 'left',
    },
    {
      label: 'メールタイトル',
      value: 'subject',
      align: 'left'
    },
    {
      label: '状況',
      value: 'status_display',
      align: 'left',
      html: true
    },
    {
      label: '配信日時',
      value: 'send_date',
      filter: 'YMDHm',
      align: 'left',
      sortField: 'MailMagazines.send_date',
    },
    {
      label: 'メルマガグループ',
      value: 'mail_magazine_group_name',
      align: 'left',
    },
    {
      label: '配信対象数',
      value: 'objects_number',
      sortField: 'MailMagazines.objects_number',
      filter: 'number_format'
    },
    {
      label: '配信数',
      value: 'sent_number',
      sortField: 'MailMagazines.sent_number',
      filter: 'number_format'
    },
    {
      label: '配信エラー数',
      value: 'sent_error_number',
      sortField: 'MailMagazines.sent_error_number',
      filter: 'number_format'
    },
    {
      label: '開封数（開封率）',
      value: 'sent_open_number_display'
    },
    {
      label: '更新日時',
      value: 'modified',
      filter: 'YMDHm',
      sortField: 'MailMagazines.modified',
      align: 'left',
    },
  ];

  const searchInputParams: DataTableSearchInputParamsProps[] = [
    {
      title: 'メールタイトル',
      text: '部分一致',
    },
  ];

  const dataTableParams: DataTableFiltersProps[] = [
    {
      key: 'status',
      dataKey: 'statuses',
      title: '状況',
      type: 'inlineSelect',
      getOptionUrl: 'mail-magazines/get-statuses.json',
    },
    {
      key: 'mail_magazine_group_id',
      dataKey: 'mail_magazine_groups',
      title: 'メルマガグループ',
      type: 'inlineSelect',
      getOptionUrl: 'mail-magazines/get-mail-magazine-group-list.json',
    },
  ];

  const actions: DataTableActionsProps<MailMagazine>[] = [
    {
      title: '新規追加',
      func: handleToAdd,
      color: 'primary',
      role: {
        name: 'MailMagazines',
        action: 'add',
      },
    },
    {
      title: '削除',
      func: confirmDelete,
      color: 'default',
      role: {
        name: 'MailMagazines',
        action: 'delete',
      },
    },
  ];

  return (
    <div id="mail-magazines" className="content-1">
      <DataTable<MailMagazine>
        rows={rows}
        checkbox={true}
        jsonPath='mail-magazines.json'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        actions={actions}
        searchInputParams={searchInputParams}
        dataTableParams={dataTableParams}
      />
    </div>
  )
}