import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import { ValidateErrorsProps } from '../../types/state';
import { CustomerFormData, getStatuses } from './func/CustomersForm'
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import AddessInputs from '../../components/Form/AddressInputs';
import AjaxSelect from '../../components/Form/AjaxSelect';
import ZshTextField from '../../components/Form/ZshTextField';
import axios from 'axios';
import { CustomerStatus } from '../../types/model';

export default function CustomersAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const backActionName: string = '/customers';

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<CustomerFormData>({
    customer_status_id: '',
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    tel: '',
    email: '',
    zipcode_1: '',
    zipcode_2: '',
    prefecture: '',
    address_1: '',
    address_2: '',
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});
  const [statuses, setStatuses] = useState<CustomerStatus[] | undefined>(undefined);
  const [statusesError, setStatusesError] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const setValues = (values: { [key: string]: string }): void => {
    setFormData({
      ...formData,
      ...values
    });
  };

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'customers',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  // set statuses
  useEffect(() => {
    if (statuses === undefined) {
      const initFunc = async () => {
        await getStatuses({
          unmounted: unmounted.current,
          setStatuses,
          setStatusesError,
          source
        });
        await ((): any => {
          setLoading(false);
          return Promise.resolve(1);
        })();
      }
      initFunc();
    }
  }, [statuses]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="customers" className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          loading === false &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">ステータス</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="customer_status_id"
                            menuItems={statuses}
                            formData={formData}
                            handleChange={handleChange}
                            getError={statusesError}
                            validateErrors={validateErrors}
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">姓</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.last_name}
                            name="last_name"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.first_name}
                            name="first_name"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>姓 （カナ）</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.last_name_kana}
                            name="last_name_kana"
                            handleChange={handleChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>名 （カナ）</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.first_name_kana}
                            name="first_name_kana"
                            handleChange={handleChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>電話番号</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.tel}
                            name="tel"
                            handleChange={handleChange}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>メールアドレス</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.email}
                            name="email"
                            handleChange={handleChange}
                            fullWidth
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>住所</th>
                      <td>
                        <AddessInputs
                          formData={formData}
                          handleChange={handleChange}
                          setValues={setValues}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}