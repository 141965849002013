import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import { ValidateErrorsProps } from '../../types/state';
import Paper from '@material-ui/core/Paper';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';

export default function MailFromsAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const backActionName: string = '/mail-froms';

  const unmounted = useRef<boolean>(false);

  const [formData, setFormData] = useState({
    email: '',
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'mail-froms',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    return () => {
      unmounted.current = true;
    }
  }, []);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="mail-froms" className="content-1">
      <form onSubmit={handleSubmit}>
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in form-content">
              <table className="form-table">
                <tbody>
                  <tr>
                    <th className="required-th">From</th>
                    <td>
                      <div className="form-input-group">
                        <ZshTextField
                          value={formData.email}
                          name="email"
                          handleChange={handleChange}
                          required
                          validateErrors={validateErrors}
                          fullWidth
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
        </div>
      </form>
    </div>
  )
}