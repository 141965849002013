import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import {
  MailMagazineGroupFormProps,
  MailMagazineGroupFormParamsProps,
  getMailMagazineGroupsFormParams
} from './func/MailMagazineGroupsForm';
import { ValidateErrorsProps } from '../../types/state';
import AttributeGroupSelect from '../../components/Form/AttributeGroupSelect';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';
import AjaxSelect from '../../components/Form/AjaxSelect';
import axios from 'axios';
import { AttributeGroup } from '../../types/model';

export default function MailMagazineGroupsAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const backActionName: string = '/mail-magazine-groups';

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<MailMagazineGroupFormProps>({
    name: '',
    attribute_group_id: '',
    from_name: '',
    mail_from_id: '',
    signature_id: '',
    reply_to: ''
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});
  const [formParams, setFormParams] = useState<MailMagazineGroupFormParamsProps>({
    mailFroms: {},
    signatures: {}
  });
  const [formParamsError, setFormParamsError] = useState<boolean>(false);

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'mail-magazine-groups',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const handleSelectAttributeGroup = (node: AttributeGroup): void => {
    setFormData({ ...formData, attribute_group_id: node.id });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await getMailMagazineGroupsFormParams({
          unmounted: unmounted.current,
          setFormParams,
          setFormParamsError,
          source
        });
        await ((): any => {
          setLoading(false);
          return Promise.resolve(1);
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="mail-magazine-groups" className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          loading === false &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">メルマガグループ名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.name}
                            name="name"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">配信先の属性グループ</th>
                      <td>
                        <div className="form-input-group">
                          <AttributeGroupSelect
                            name="attribute_group_id"
                            handleSelectAttributeGroup={handleSelectAttributeGroup}
                            validateErrors={validateErrors}
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">From</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="mail_from_id"
                            menuItems={formParams.mailFroms}
                            formData={formData}
                            handleChange={handleChange}
                            getError={formParamsError}
                            validateErrors={validateErrors}
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">送信者名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.from_name}
                            name="from_name"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>署名</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="signature_id"
                            menuItems={formParams.signatures}
                            formData={formData}
                            handleChange={handleChange}
                            getError={formParamsError}
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Reply To</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.reply_to}
                            name="reply_to"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}