import React, { useState, useRef, useCallback, useEffect } from 'react';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import * as _debug from '../../helper/debug';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Loading from '../View/Loading';
import axios from 'axios';
import ZshDateTimePicker from './ZshDateTimePicker';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
});

export type AttributeGroupConditionDate = {
  date: string;
}

export type AttributeGroupConditionDateTime = {
  date: string;
  hour: string;
  minute: string;
}

export type AttributeGroupConditionProps = {
  field: string;
  input: 0 | 1 | 2;
  operator: string;
  value: string | number | boolean | AttributeGroupConditionDate | AttributeGroupConditionDateTime;
  picker: {
    type: 'date' | 'datetime';
    date: Date | null
  };
  match_conditions?: string | null | undefined;
  OR?: AttributeGroupConditionProps[];
}

type AttributeGroupConditionFieldProps = {
  display: string;
  field: string;
  type: string;
  options?: any[];
  encrypt?: boolean;
  field_type?: string;
  sql_type?: string;
  hide_input?: boolean;
  hide_match_conditions?: boolean;
  conditions?: any[];
}

type AttributeGroupConditionFieldSelectProps = {
  display: string;
  options: { [key: string]: string };
}

type AttributeGroupOperatorPorps = {
  display: string;
  type: number;
}

type AttributeGroupConditionInputsProps = {
  title?: string;
  conditions: AttributeGroupConditionProps[];
  onChange: ((conditions: AttributeGroupConditionProps[]) => void);
}

export default function AttributeGroupConditionInputs({
  title = 'グループ条件',
  conditions,
  onChange,
}: AttributeGroupConditionInputsProps) {

  const classes = useStyles();

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [fields, setFields] = useState<{ [key: string]: AttributeGroupConditionFieldProps }>({});
  const [fieldSelect, setFieldSelect] = useState<{ [key: string]: AttributeGroupConditionFieldSelectProps }>({});
  const [operators, setOperators] = useState<{ [key: string]: AttributeGroupOperatorPorps }>({});
  const [matchConditions, setMatchConditions] = useState<{ [key: string]: string }>({});
  const [options, setOptions] = useState<{ [key: string]: any[] }>({});
  const [getParamsError, setGetParamsError] = useState<boolean>(false);
  const [orConditions, setOrConditions] = useState<AttributeGroupConditionProps[]>([]);
  const [orConditionIndex, setOrConditionIndex] = useState<number | null>(null);

  const defaultConditions: AttributeGroupConditionProps = {
    field: '',
    input: 1,
    operator: '=',
    value: '',
    picker: {
      type: 'date',
      date: null
    },
    match_conditions: 'perfect'
  };

  const handleAddSingleCondition = (): void => {
    let tmpConditions: AttributeGroupConditionProps[] = conditions.slice();
    tmpConditions.push(defaultConditions);
    onChange(tmpConditions);
  }

  const handleAddOrCondition = (): void => {
    let tmpConditions: AttributeGroupConditionProps[] = orConditions.slice();
    tmpConditions.push(defaultConditions);
    setOrConditions(tmpConditions);
  }

  const handleChange = (event: any): void => {

    const name: string = event.target.name;
    const splitName: string[] = name.split('-');
    const index: number = Number(splitName[0]);
    const field: string = splitName[1];
    const isOr: boolean = typeof splitName[2] === 'undefined' ? false : true;

    let setData: any = {};

    // reset values
    if (field === 'field') {
      setData = {
        ...defaultConditions,
        field: event.target.value
      };
    } else {
      setData[field] = event.target.value;
      if (field === 'input') {
        if (event.target.value === '1') {
          setData['operator'] = '=';
          setData['match_conditions'] = 'perfect';
        } else {
          setData['operator'] = '';
          setData['value'] = '';
          setData['match_conditions'] = '';
        }
      }
    }

    if (isOr) {
      let tmpConditions: AttributeGroupConditionProps[] = orConditions.slice();
      tmpConditions[index] = {
        ...tmpConditions[index],
        ...setData
      };
      setOrConditions(tmpConditions);
    } else {
      let tmpConditions: AttributeGroupConditionProps[] = conditions.slice();
      tmpConditions[index] = {
        ...tmpConditions[index],
        ...setData
      };
      onChange(tmpConditions);
    }
  }

  const handleChangeConditionDate = (date: any, name: string, type: 'date' | 'datetime'): void => {
    const splitName: string[] = name.split('-');
    const index: number = Number(splitName[0]);
    const isOr: boolean = typeof splitName[2] === 'undefined' ? false : true;
    const dateObj: Date = new Date(date);
    if (isOr) {
      let tmpConditions: AttributeGroupConditionProps[] = orConditions.slice();
      tmpConditions[index] = {
        ...tmpConditions[index],
        value: _form.formatISO8601(dateObj),
        picker: {
          type,
          date: dateObj
        }
      };
      setOrConditions(tmpConditions);
    } else {
      let tmpConditions: AttributeGroupConditionProps[] = conditions.slice();
      tmpConditions[index] = {
        ...tmpConditions[index],
        value: _form.formatISO8601(dateObj),
        picker: {
          type,
          date: dateObj
        }
      };
      onChange(tmpConditions);
    }
  }

  const selectField = (condition: AttributeGroupConditionProps, name: string): JSX.Element => {
    return (
      <div className="agc-field agc-input-control">
        <FormControl>
          <Select
            value={condition.field}
            onChange={handleChange}
            native
            required
            inputProps={{
              name: name,
            }}
          >
            <option value=""></option>
            {
              Object.keys(fieldSelect).map((key: string, feli: number) => (
                <optgroup
                  label={fieldSelect[key].display}
                  key={`agc-field-select-${name}-option-${feli}`}
                >
                  {
                    Object.keys(fieldSelect[key].options).map((filedKey: string, feopi: number) => (
                      <option
                        key={`agc-field-select-${name}-option-${feli}-${feopi}`}
                        value={filedKey}
                      >
                        {fieldSelect[key].options[filedKey]}
                      </option>
                    ))
                  }
                </optgroup>
              ))
            }
          </Select>
        </FormControl>
      </div>
    );
  }

  const selectInput = (condition: AttributeGroupConditionProps, name: string): JSX.Element => {
    let isShow: boolean = true;
    if (fields[condition.field] !== undefined) {
      const thisField: AttributeGroupConditionFieldProps = fields[condition.field];
      if (typeof thisField.hide_input !== 'undefined' && thisField.hide_input === true) {
        isShow = false;
      }
    }
    return (
      <div className="agc-input agc-input-control">
        {
          isShow &&
          <FormControl>
            <Select
              value={condition.input}
              onChange={handleChange}
              native
              inputProps={{
                name: name,
              }}
            >
              <option value="1">入力値指定</option>
              <option value="2">入力値あり</option>
              <option value="0">入力なし</option>
            </Select>
          </FormControl>
        }
      </div>
    );
  }

  const selectOperator = (condition: AttributeGroupConditionProps, name: string): JSX.Element => {
    return (
      <div className="agc-operator agc-input-control">
        {
          condition.input === 1 &&
          <FormControl>
            <Select
              value={condition.operator}
              onChange={handleChange}
              native
              inputProps={{
                name: name,
              }}
            >
              {
                Object.keys(operators).map((conKey: string, coni: number) => (
                  <option
                    value={conKey}
                    key={`agc-operator-${name}-option-${coni}`}
                    dangerouslySetInnerHTML={{ __html: operators[conKey].display }}
                  >
                  </option>
                ))
              }
            </Select>
          </FormControl>
        }
      </div>
    );
  }

  const inputValue = (condition: AttributeGroupConditionProps, name: string): JSX.Element => {
    let type: string = 'text';
    let selectOptions: any = {};
    if (fields[condition.field] !== undefined) {
      const thisField: AttributeGroupConditionFieldProps = fields[condition.field];
      if (typeof thisField.type !== 'undefined') {
        type = thisField.type;
      }
      if (type === 'cutomer_status') {
        type = 'select';
      }
      if (type === 'select') {
        if (thisField.options !== undefined) {
          selectOptions = Object.assign({}, thisField.options);
        } else {
          if (options[condition.field] !== undefined) {
            selectOptions = Object.assign({}, options[condition.field]);
          }
        }
      }
    }
    return (
      <div className="agc-value agc-input-control">
        {
          condition.input === 1 &&
          <FormControl>
            {
              type === 'text' &&
              <TextField
                name={name}
                value={condition.value}
                onChange={handleChange}
              />
            }
            {
              type === 'int' &&
              <TextField
                name={name}
                value={condition.value}
                onChange={handleChange}
                type="number"
              />
            }
            {
              type === 'select' &&
              <Select
                value={condition.value}
                onChange={handleChange}
                native
                inputProps={{
                  name: name,
                }}
              >
                <option value=""></option>
                {
                  Object.keys(selectOptions).map((conKey: string, coni: number) => (
                    <option
                      value={conKey}
                      key={`agc-value-${name}-option-${coni}`}
                      dangerouslySetInnerHTML={{ __html: selectOptions[conKey] }}
                    >
                    </option>
                  ))
                }
              </Select>
            }
            {
              type === 'date' &&
              <ZshDateTimePicker
                value={condition.picker.date}
                name={name}
                handleChange={(date: any) => {
                  handleChangeConditionDate(date, name, 'date');
                }}
                format="YYYY/MM/DD"
                type="date"
              />
            }
            {
              type === 'datetime' &&
              <ZshDateTimePicker
                value={condition.picker.date}
                name={name}
                handleChange={(date: any) => {
                  handleChangeConditionDate(date, name, 'datetime');
                }}
                format="YYYY/MM/DD HH:mm"
                views={['date', 'hours', 'minutes']}
                type="datetime"
              />
            }
          </FormControl>
        }
      </div>
    );
  }

  const selectMatchCondition = (condition: AttributeGroupConditionProps, name: string): JSX.Element => {
    let isShow: boolean = true;
    if (condition.operator !== '=') {
      isShow = false;
    }
    if (fields[condition.field] !== undefined) {
      const thisField: AttributeGroupConditionFieldProps = fields[condition.field];
      if (typeof thisField.hide_match_conditions !== 'undefined') {
        isShow = false;
      }
    }
    return (
      <div className="agc-match-condition agc-input-control">
        {
          isShow &&
          <FormControl>
            <Select
              value={condition.match_conditions}
              onChange={handleChange}
              native
              inputProps={{
                name: name,
              }}
            >
              {
                Object.keys(matchConditions).map((matchCondition: string, mconi: number) => (
                  <option
                    value={matchCondition}
                    key={`agc-match-conditions-${name}-option-${mconi}`}
                  >
                    {matchConditions[matchCondition]}
                  </option>
                ))
              }
            </Select>
          </FormControl>
        }
      </div>
    );
  }

  const handleClearCondition = (index: number): void => {
    let tmpConditions = conditions.filter(
      (val: AttributeGroupConditionProps, conIndex: number) => conIndex !== index
    );
    onChange(tmpConditions);
  }

  const handleClearOrCondition = (index: number): void => {
    let tmpConditions = orConditions.filter(
      (val: AttributeGroupConditionProps, conIndex: number) => conIndex !== index
    );
    setOrConditions(tmpConditions);
  }

  const handleOrView = (orConditions: AttributeGroupConditionProps[] | undefined, index: number): void => {
    if (orConditions !== undefined) {
      setOrConditions(orConditions);
      setOrConditionIndex(index);
      setShowModal(true); 
    }
  }

  const handleClose = (): void => {
    setOrConditions([]);
    setOrConditionIndex(null);
    setShowModal(false);
  }

  const handleOrChoice = (): void => {
    let tmpConditions: AttributeGroupConditionProps[] = conditions.slice();
    if (orConditionIndex === null) {
      // create
      let insertCondition: AttributeGroupConditionProps = Object.assign({}, defaultConditions);
      insertCondition.OR = orConditions;
      tmpConditions.push(insertCondition);
    } else {
      // edit
      if (tmpConditions[orConditionIndex] !== undefined) {
        tmpConditions[orConditionIndex].OR = orConditions;
      }
    }
    onChange(tmpConditions);
    handleClose();
  }

  const orConditionsText = (conditions: AttributeGroupConditionProps[]): string => {
    let result: string = '';
    conditions.forEach((condition: AttributeGroupConditionProps) => {
      let thisInputs: any = {
        field: '',
        operator: '',
        value: '',
        match_conditions: ''
      };
      let inputType: string = 'text';
      // set field
      if (fields[condition.field] !== undefined) {
        thisInputs.field = fields[condition.field].display;
        inputType = fields[condition.field].type;
      }
      // switch input type
      if (condition.input === 1) {
        // set operator
        if (operators[condition.operator] !== undefined) {
          thisInputs.operator = operators[condition.operator].display;
        }
        // value
        if (inputType === 'text' || inputType === 'int') {
          thisInputs.value = condition.value;
        }
        if (inputType === 'date' || inputType === 'datetime') {
          if (condition.picker.date !== null) {
            if (inputType === 'date') {
              thisInputs.value = condition.picker.date.toLocaleDateString('ja-JP');
            }
            if (inputType === 'datetime') {
              thisInputs.value = condition.picker.date.toLocaleDateString('ja-JP') + ' ' + condition.picker.date.toLocaleTimeString('ja-JP');
            }
          }
        }
        if (inputType === 'cutomer_status' || inputType === 'select') {
          if (
            typeof condition.value === 'string' ||
            typeof condition.value === 'number' ||
            typeof condition.value === 'boolean'
          ) {
            const selectValue: any = condition.value;
            if (
              options[condition.field] !== undefined &&
              options[condition.field][selectValue] !== undefined
            ) {
              thisInputs.value = options[condition.field][selectValue];
            }
            if (
              fields[condition.field] !== undefined &&
              fields[condition.field].options !== undefined
            ) {
              const thisOptoins: any[] | undefined = fields[condition.field].options;
              if (thisOptoins !== undefined && thisOptoins[selectValue] !== undefined) {
                thisInputs.value = thisOptoins[selectValue];
              }
            }
          }
        }
        // set match conditions
        if (
          condition.operator === '=' && 
          condition.match_conditions !== undefined &&
          condition.match_conditions !== null &&
          matchConditions[condition.match_conditions] !== undefined
        ) {
          thisInputs.match_conditions = matchConditions[condition.match_conditions];
        }
      } else {
        if (condition.input === 2) {
          thisInputs.value = '入力値あり'
        }
        if (condition.input === 0) {
          thisInputs.value = '入力なし'
        }
      }
      result += Object.values(thisInputs).join(' ') + '　';
    });
    return result;
  }

  const getConditionParams = useCallback(async () => {
    await axios
      .get(
        _usr_const.ApiUrl + 'attribute-groups/get-condition-params.json', {
        cancelToken: source.current.token
      }
      )
      .then((response) => {
        if (typeof response.data === 'object') {
          if (!unmounted.current) {
            setFields(response.data.fields);
            setFieldSelect(response.data.field_select);
            setOperators(response.data.operator);
            setMatchConditions(response.data.match_conditions);
            setOptions(response.data.options);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (!unmounted.current) {
          setGetParamsError(true);
        }
      })
      .finally(() => {
        if (!unmounted.current) {
          setLoading(false);
        }
        return Promise.resolve(1);
      });
  }, [source, unmounted]);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await getConditionParams();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, getConditionParams]);

  return (
    <Paper className="content-2 attribute-group-condition-selects">
      <h2 className="h-2 attribute-group-condition-select-title">{title}</h2>
      <Loading loading={loading} />
      {
        loading === false &&
        <div>
          {
            getParamsError &&
            <div>
              <p className="red">項目を取得できませんでした。</p>
            </div>
          }
          {
            getParamsError === false &&
            <div style={{ width: '100%' }}>
              <div className="agcs-head-btn-wr">
                <Button
                  color="default"
                  variant="outlined"
                  onClick={handleAddSingleCondition}
                  className="gcs-head-add-btn"
                >
                  条件追加(単一)
                </Button>
                <Button
                  color="default"
                  variant="outlined"
                  onClick={(event: any) => setShowModal(true)}
                  className="gcs-head-or-add-btn"
                >
                  条件追加(OR条件)
                </Button>
              </div>
              <div>
                <TableContainer>
                  <Table className={classes.table} aria-label="conditions table" size="small">
                    <TableBody>
                      {
                        conditions.map((condition: AttributeGroupConditionProps, index: number) => (
                          <StyledTableRow key={'at-conditoins-' + index}>
                            <StyledTableCell align="left" className="agc-index">
                              {index + 1}
                            </StyledTableCell>
                            {
                              condition.OR === undefined &&
                              <StyledTableCell align="left" className="agc-inputs">
                                {
                                  selectField(
                                    condition,
                                    index + '-field',
                                  )
                                }
                                {
                                  selectInput(
                                    condition,
                                    index + '-input',
                                  )
                                }
                                {
                                  selectOperator(
                                    condition,
                                    index + '-operator',
                                  )
                                }
                                {
                                  inputValue(
                                    condition,
                                    index + '-value',
                                  )
                                }
                                {
                                  selectMatchCondition(
                                    condition,
                                    index + '-match_conditions',
                                  )
                                }
                              </StyledTableCell>
                            }
                            {
                              condition.OR !== undefined &&
                              <StyledTableCell>
                                <div className="agc-or-conditions-view">
                                  <span>
                                    <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={(event:any) => handleOrView(condition.OR, index)}
                                    >
                                      詳細
                                    </Button>
                                  </span>
                                  <span>
                                    【OR条件】
                                  </span>
                                  <span
                                  className="agc-or-conditions-view-text"
                                  dangerouslySetInnerHTML={{ __html: orConditionsText(condition.OR) }}
                                  ></span>
                                </div>
                              </StyledTableCell>
                            }
                            <StyledTableCell align="left" className="agc-delete">
                              <IconButton
                                aria-label="delete"
                                onClick={(event: any) => handleClearCondition(index)}
                              >
                                <ClearIcon />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          }
        </div>
      }
      <Dialog
        open={showModal}
        onClose={handleClose}
        className="attribute-group-condition-selects attribute-group-condition-or"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>条件追加(OR条件)</DialogTitle>
        <DialogContent>
          <div className="agcs-head-btn-wr">
            <Button
              color="default"
              variant="outlined"
              onClick={handleAddOrCondition}
            >
              条件追加
            </Button>
          </div>
          <div>
            <TableContainer>
              <Table className={classes.table} aria-label="conditions table" size="small">
                <TableBody>
                  {
                    orConditions.map((orCondition: AttributeGroupConditionProps, orIndex: number) => (
                      <StyledTableRow key={'at-or-conditoins-' + orIndex}>
                        <StyledTableCell align="left" className="agc-or-index">
                          {orIndex + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="agc-inputs">
                          {
                            selectField(
                              orCondition,
                              orIndex + '-field-or',
                            )
                          }
                          {
                            selectInput(
                              orCondition,
                              orIndex + '-input-or',
                            )
                          }
                          {
                            selectOperator(
                              orCondition,
                              orIndex + '-operator-or',
                            )
                          }
                          {
                            inputValue(
                              orCondition,
                              orIndex + '-value-or',
                            )
                          }
                          {
                            selectMatchCondition(
                              orCondition,
                              orIndex + '-match_conditions-or',
                            )
                          }
                        </StyledTableCell>
                        <StyledTableCell align="left" className="agc-delete">
                          <IconButton
                            aria-label="delete"
                            onClick={(event: any) => handleClearOrCondition(orIndex)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <DialogActions>
            <Button onClick={handleClose} color="default">閉じる</Button>
            <Button
              onClick={handleOrChoice}
              color="primary"
              disabled={orConditions.length === 0}
            >
              保存
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Paper>
  );
}