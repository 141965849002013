import * as _usr_const from '../config/usr-constant';
import * as _debug from '../helper/debug';
import axios from 'axios';

/**
 * setFormData
 * 
 */
export function setFormData<T extends { [key: string]: any }, U extends  { [key: string]: any }>(formData: T, data: U): T {
  let results: any = Object.assign({},  formData);
  if (typeof data === 'object' && typeof formData === 'object') {
    Object.keys(formData).forEach((key: string) => {
      if (data.hasOwnProperty(key)) {
        if (data[key] === null || data[key] === '') {
          if (data[key] === null) {
            results[key] = null;
          } else {
            results[key] = '';
          }
        } else {
          results[key] = data[key];
        }
      }
      if (key === 'pickers') {
        results['pickers'] = formData[key];
        if (typeof formData[key] === 'object') {
          Object.keys(formData[key]).forEach((pickerKey: string) => {
            if (data.hasOwnProperty(pickerKey)) {
              if (data[pickerKey] === null) {
                results['pickers'][pickerKey] = null;
              } else {
                results['pickers'][pickerKey] = data[pickerKey];
              }
            }
          });
        }
      } 
    });
  }
  return results;
}

/**
 * setValue
 * 
 */
type setValueProps = {
  formData: any,
  name: string,
  value: any,
  setFormData: (formData: any) => void
}
export function setValue({ formData, name, value, setFormData }: setValueProps): void {
  let tmpFormData: any = Object.assign({}, formData);
  if (tmpFormData[name] !== undefined && value !== undefined) {
    tmpFormData[name] = value;
    setFormData({ ...tmpFormData });
  }
}

/**
 * handleSubmit
 * 
 */
type handleSubmitParams = {
  action: string;
  method?: 'post' | 'put' | 'delete' | 'patch';
  id?: string | number;
  formData: any;
  history?: any;
  callbackSuccess?: any;
  callbackError?: any;
  backActionName?: string;
  setOverlayProppress: any;
  setFlashMessageSuccess: any;
  setFlashMessageError: any;
  setValidateErrors?: any;
  successFlashMessage?: string;
  errorFlashMessage?: string;
  redirectLogin?: boolean;
}
export function handleSubmit({
  action,
  method = 'post',
  id,
  formData,
  history,
  callbackSuccess,
  callbackError,
  backActionName,
  setOverlayProppress,
  setFlashMessageSuccess,
  setFlashMessageError,
  setValidateErrors,
  successFlashMessage = '保存しました',
  errorFlashMessage = '保存できませんでした',
  redirectLogin = true
}: handleSubmitParams) {

  window.scrollTo(0, 0);

  setOverlayProppress(true);

  const tmpBackActionName: string = backActionName !== undefined ? backActionName : '';

  let formAction: string = action;
  if (id !== undefined) {
    formAction += '/' + id;
  }
  formAction += '.json';

  let postFormData: any = {};
  if (Object.keys(formData).length > 0) {
    Object.keys(formData).forEach((key: string) => {
      if (key !== 'pickers') {
        postFormData[key] = formData[key];
      }
    });
  }
  
  axios({
    method,
    url: _usr_const.ApiUrl + formAction,
    data: postFormData
  })
    .then((response: any) => {
      setFlashMessageSuccess(successFlashMessage);
      if (tmpBackActionName !== '' && history !== undefined) {
        history.push(tmpBackActionName);
      }
      if (callbackSuccess !== undefined) {
        callbackSuccess(response);
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
      let errorMessage: string[] = [];
      if (error.response) {
        // set error message
        if (error.response.data.message !== undefined) {
          const errorJson = error.response.data.message;
          try {
            const parseErrorJson = JSON.parse(errorJson);
            if (typeof parseErrorJson === 'object' &&  parseErrorJson.length > 0) {
              parseErrorJson.forEach((message: string) => {
                errorMessage.push(message);
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
        // auth error
        if (error.response.status === 401 && redirectLogin === true) {
          window.location.href = '/users/login';
        }
        // validate error
        if (error.response.status === 400 && setValidateErrors !== undefined) {
          setValidateErrors(formatValidateError(error));
        }
      }
      // set flash message
      if (errorMessage.length > 0) {
        setFlashMessageError(errorMessage.join('¥n'));
      } else {
        setFlashMessageError(errorFlashMessage);
      }
      // console.log(error.config);
      if (callbackError !== undefined) {
        callbackError(error);
      }
    })
    .finally(() => {
      setOverlayProppress(false);
    });
}

/**
 * formatISO8601
 * 
 */
export function formatISO8601(date: Date): string {
  const offset = (function (d) {
    var o = d.getTimezoneOffset() / -60;
    return ((0 < o) ? '+' : '-') + ('00' + Math.abs(o)).substr(-2) + ':00';
  })(date);
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-'),
    'T',
    [
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ].join(':'),
    offset
  ].join('');
}

/**
 * formatDate
 * 
 */
export function formatDate(date: Date): string {
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-')
  ].join('');
}

/**
 * formatDateTime
 * 
 */
export function formatDateTime(date: Date): string {
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-'),
    ' ',
    [
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ].join(':')
  ].join('');
}

/**
 * handleDatePickerChange
 * 
 */
export function handleDatePickerChange<T extends { [key: string]: any }>(
  formData: T,
  date: Date | string | null,
  name: string
): T {
  let tmpFormData: any = Object.assign({}, formData);
  let dateObj: Date | null = null;
  if (typeof tmpFormData[name] !== 'undefined') {
    if (date !== null) {
      if (typeof date === 'string') {
        dateObj = new Date(date); 
      } else {
        dateObj = date;
      }
      tmpFormData[name] = formatISO8601(dateObj);
    } else {
      tmpFormData[name] = dateObj;
    } 
  }
  if (typeof tmpFormData['pickers'][name] !== 'undefined') {
    tmpFormData['pickers'][name] = date;
  }
  return tmpFormData;
}

/**
 * setDatePickerValue
 * 
 */
export type setDatePickerValueProps = {
  formData: any;
  date: any;
  name: string;
  type: "date" | "datetime" | "iso";
}
export function setDatePickerValue({
  formData,
  date,
  name,
  type = 'iso'
}: setDatePickerValueProps): any {
  let tmpFormData: any = Object.assign({}, formData);
  let dateObj = null;
  if (typeof tmpFormData[name] !== 'undefined') {
    if (date !== null) {
      dateObj = new Date(date);
      if (type === 'date') {
        tmpFormData[name] = formatDate(dateObj); 
      }
      if (type === 'datetime') {
        tmpFormData[name] = formatDateTime(dateObj); 
      }
      if (type === 'iso') {
        tmpFormData[name] = formatISO8601(dateObj); 
      }
    } else {
      tmpFormData[name] = dateObj;
    } 
  }
  if (typeof tmpFormData['pickers'][name] !== 'undefined') {
    tmpFormData['pickers'][name] = dateObj; 
  }
  return tmpFormData;
}


/**
 * formatValidateError
 * 
 * @param error 
 */
export function formatValidateError(error: any): { [key: string]: string[] } {
  let errors: { [key: string]: string[] } = {};
  if (error.response.data !== undefined && typeof error.response.data === 'object') {
    Object.keys(error.response.data).forEach((name: any) => {
      // set error message
      const errorMessage: string[] = Object.values(error.response.data[name]);
      errors[name] = errorMessage;
    });
  }
  return errors;
}

/**
 * checkUploadCsvFile
 * 
 * @param event 
 */
type checkUploadCsvFileResponce = {
  file: any;
  er: boolean;
  errorMessage: string[];
}
export function checkUploadCsvFile(event: any): checkUploadCsvFileResponce {
  let results: checkUploadCsvFileResponce = {
    file: {},
    er: false,
    errorMessage: []
  }
  const file_size_limit = 20971520;
  if (event === undefined || event.target === undefined || event.target.files[0] === undefined) {
    results.errorMessage.push('ファイル対象を取得できません');
  } else {
    results.file = event.target.files[0];
    if (results.file.length === 0) {
      results.errorMessage.push('ファイルを読み込めません');
    } else {
      //check file format
      var file_types = ['text/csv', 'application/vnd.ms-excel'];
      if (file_types.indexOf(results.file.type) === -1) {
        results.errorMessage.push('CSVファイルではありません');
      }
      //check file size
      if (results.file.size > file_size_limit) {
        results.errorMessage.push('インポート出来るファイルサイズは20MBまでです');
      }
    }
  }
  if (results.errorMessage.length > 0) {
    results.er = true;
  }
  return results;
}

/**
 * getFormEventNameValue
 * 
 * @param event 
 */
export function getFormEventNameValue(event: any): { [key: string]: any } {

  if (typeof event.target === 'undefined') {
    return {
      '': ''
    }
  }

  let inputName: string = '';
  if (typeof event.target.name !== 'undefined') {
   inputName = event.target.name; 
  }

  let type: string = '';
  let value: any = '';
  if (typeof event.target.type !== 'undefined') {
    type =  event.target.type;
  }
  if (type === "checkbox") {
    if (typeof event.target.checked !== 'undefined') {
      value = event.target.checked;
    }
  } else {
    if (typeof event.target.value !== 'undefined') {
      value = event.target.value; 
    }
  }
  // change boolean value
  if (value === 'true') {
    value = true;
  }
  if (value === 'false') {
    value = false;
  }
  
  return {
    [inputName]: value
  };
}