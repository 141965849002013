import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import * as _filter from '../../helper/filter';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups from '../../components/View/HeadButtonGroups';
import ErrorView from '../../components/View/ErrorView';
import { ShMailLog } from '../../types/model';

type ShMailLogApiDataProps = {
  timestamp: string;
  event: string;
  display: string;
  content: { [key: string]: string };
}

export default function ShMailLogsView({ history, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ShMailLog>({
    id: 0,
    common_parent_id: 0,
    customer_name: '',
    created: '',
    sent_status: '',
    target_id: 0,
    target_name: '',
    error_message: null,
    status: 0,
    status_code: '',
    mail_from: '',
    from_name: '',
    reply_to: '',
    mail_to: '',
    cc: '',
    bcc: '',
    bounce: false,
    bounce_datetime: '',
    is_open: false,
    open_datetime: '',
    messageid: '',
    bounce_datetime_ja: '',
    open_datetime_ja: '',
    customer: undefined,
    displays: {
      object_name: '',
      send_method: '',
    },
    common_parent: undefined
  });
  const [apiData, setApiData] = useState<ShMailLogApiDataProps[]>([]);
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<ShMailLog>({
        unmounted,
        source,
        dataKey: 'shMailLog',
        path: _usr_const.ApiUrl + 'sh-mail-logs/' + match.params.id + '.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading,
        successCallbackAftereSetData: (results: any) => {
          if (results.data.apiData !== undefined) {
            setApiData(results.data.apiData);
          }
        }
      })
        .catch((error) => {
          _debug.debugAxiosError(error);
        });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  return (
    <div id="sh-mail-logs" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <th>日時</th>
                    <td>
                      {_filter.ShFilter(data.created, 'YMDHms')}
                    </td>
                  </tr>
                  <tr>
                    <th>種別</th>
                    <td>
                      {
                        data.displays !== undefined &&
                        <span>
                          {data.displays.object_name}
                        </span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>顧客</th>
                    <td>
                      {
                        data.customer !== undefined && data.customer !== null &&
                        <Link to={"/customers/" + data.customer.id}>{data.customer.display_field}</Link>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>状況</th>
                    <td>
                      {data.sent_status}
                    </td>
                  </tr>
                  <tr>
                    <th>対象</th>
                    <td>
                      {
                        data.target_id !== 0 &&
                        <Link to={"/mail-magazines/" + data.target_id}>{data.target_name}</Link>
                      }
                    </td>
                  </tr>
                  {
                    data.error_message !== null &&
                    <tr>
                      <th>エラーメッセージ</th>
                      <td>
                        {data.error_message}
                      </td>
                    </tr>
                  }
                  <tr>
                    <th>Status</th>
                    <td>
                      {data.status}
                    </td>
                  </tr>
                  <tr>
                    <th>Status Code</th>
                    <td>
                      {data.status_code}
                    </td>
                  </tr>
                  <tr>
                    <th>Mail From</th>
                    <td>
                      {data.mail_from}
                    </td>
                  </tr>
                  <tr>
                    <th>From Name</th>
                    <td>
                      {data.from_name}
                    </td>
                  </tr>
                  <tr>
                    <th>Reply To</th>
                    <td>
                      {data.reply_to}
                    </td>
                  </tr>
                  <tr>
                    <th>To</th>
                    <td>
                      {data.mail_to}
                    </td>
                  </tr>
                  <tr>
                    <th>Cc</th>
                    <td>
                      {data.cc}
                    </td>
                  </tr>
                  <tr>
                    <th>Bcc</th>
                    <td>
                      {data.bcc}
                    </td>
                  </tr>
                  <tr>
                    <th>Bounce</th>
                    <td>
                      {data.bounce ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  {
                    data.bounce &&
                    <tr>
                      <th>Bounce日時</th>
                      <td>
                        {data.bounce_datetime_ja}
                      </td>
                    </tr>
                  }
                  <tr>
                    <th>開封</th>
                    <td>
                      {data.is_open ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  {
                    data.is_open &&
                    <tr>
                      <th>初回開封日時</th>
                      <td>
                        {data.open_datetime_ja}
                      </td>
                    </tr>
                  }
                  <tr>
                    <th>Message ID</th>
                    <td>
                      {data.messageid}
                    </td>
                  </tr>
                  <tr>
                    <th>配信方法</th>
                    <td>
                      {
                        data.displays !== undefined &&
                        <span>
                          {data.displays.send_method}
                        </span>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <Paper className="content-2">
            <h2 className="h-2">履歴</h2>
            {
              apiData.length > 0 &&
              <table className="table-1 data-table">
                <tbody>
                  {
                    apiData.map((aData: ShMailLogApiDataProps, index: number) => (
                      <tr key={`api-data-${index}`}>
                        <td>
                          <div className="data-table-inline-content">
                            <div className="data-table-inline-content-text">
                              <div>
                                {aData.timestamp}
                              </div>
                              <div>
                                {aData.event}
                              </div>
                              <div>
                                {aData.display}
                              </div>
                            </div>
                          </div>
                          {
                            Object.keys(aData.content).length > 0 &&
                            <div>
                              {
                                Object.keys(aData.content).map((contentKey: string) => (
                                  <div
                                    className="sh-mail-log-api-content"
                                    key={`api-data-content-${contentKey}`}
                                  >
                                    <div className="smlac-title">{contentKey} : </div>
                                    <div className="smlac-content">{aData.content[contentKey]}</div>
                                  </div>
                                ))
                              }
                            </div>
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            }
          </Paper>
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}