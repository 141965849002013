import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: '0.9rem'
    },
  })
);

type RoleActionInputsProps = {
  roleKey: string;
  actions: any;
  formDataRole: any;
  handleChangeRoleAction: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    roleKey: string
  ) => void;
}

function RoleActionInputs({ roleKey, actions, formDataRole, handleChangeRoleAction }: RoleActionInputsProps) {

  const setChecked = (actionKey: string): boolean => {
    if (formDataRole[roleKey] !== undefined) {
      if (formDataRole[roleKey].indexOf(actionKey) !== -1) {
        return true;
      }
    }
    return false;
  }

  const setDisabled = (actionKey: string): boolean => {
    const checkDisableactions = ['edit', 'delete', 'csv_download'];
    if (checkDisableactions.indexOf(actionKey) !== -1) {
      if (formDataRole[roleKey] !== undefined) {
        if (formDataRole[roleKey].indexOf('view') !== -1) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  const classes = useStyles();

  return (
    <div>
      <ul className="role-action-input">
        <>
          {
            typeof actions === 'object' && Object.keys(actions).length > 0 &&
            Object.keys(actions).map((actionKey: string) => (
              <li key={actionKey} className="role-action-li">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={ (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        handleChangeRoleAction(event, checked, roleKey);
                      } }
                      name={actionKey}
                      checked={setChecked(actionKey)}
                      disabled={setDisabled(actionKey)}
                    />
                  }
                  label={<Typography classes={{root: classes.label}}>{actions[actionKey]}</Typography>}
                />
              </li>
            ))
          }
        </>
      </ul>
    </div>
  )
}

export default RoleActionInputs