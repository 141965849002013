import React from 'react';
import Paper from '@material-ui/core/Paper';
import * as _filter from '../../helper/filter';
import DataTable, {
  DataTableHandleClickCellProps,
  DataTableFuncTbodyRowProps
} from '../../components/View/DataTable';
import { ShMailLog } from '../../types/model';

type MailLogsProps = {
  objectCommonParentId?: number;
  customerId?: number;
  history: any;
  type: 'Customers' | 'MailMagazines';
}

export default function MailLogs({
  objectCommonParentId,
  customerId,
  history,
  type
}: MailLogsProps) {

  const targetName = (params: DataTableFuncTbodyRowProps<ShMailLog>): JSX.Element => {
    switch (type) {
      case 'MailMagazines':
        return (
          <div className="in-content">
            <div className="in-content">{params.record.customer_name}</div>
          </div>
        );
      default:
        return (
          <div className="in-content">
            {
              params.record.displays !== undefined &&
              <div className="in-content">【{params.record.displays.object_name}】</div>
            }
            <div className="in-content">{params.record.target_name}</div>
          </div>
        );
    }
  }

  const showTbodyRow = (params: DataTableFuncTbodyRowProps<ShMailLog>): JSX.Element => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div className="in-content">{_filter.ShFilter(params.record.created, 'YMDHms')}</div>
          <div className="in-content">&lt; {params.record.sent_status} &gt;</div>
          <div className="in-content">
            {
              params.record.is_open &&
              <span><i className="far fa-envelope-open"></i>開封</span>
            }
            {
              params.record.is_open === false &&
              <span><i className="far fa-envelope"></i>未開封</span>
            }
          </div>
          {targetName(params)}
        </div>
      </div>
    );
  }

  const clickCell = (params: DataTableHandleClickCellProps<ShMailLog>): void => {
    if (params.record !== undefined && params.record.id !== undefined) {
      history.push('/sh-mail-logs/' + params.record.id);
    }
  }

  const isObjectCommonParentId: number | "" = objectCommonParentId === undefined ? '' : objectCommonParentId;

  return (
    <Paper className="content-2 mail-logs">
      <h2 className="h-2">メール配信履歴</h2>
      <DataTable<ShMailLog>
        checkbox={false}
        jsonPath={'sh-mail-logs.json'}
        urlQuery={false}
        handleClickCell={clickCell}
        funcTbodyRow={showTbodyRow}
        limit={5}
        size="small"
        defaultQueryValues={
          {
            object_common_parent_id: isObjectCommonParentId,
            customer_id: customerId
          }
        }
      />
    </Paper>
  )
}