import React, { useState, useEffect, useCallback, useRef, createRef } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _formatter from '../../helper/formatter';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import queryString from 'query-string';
import SearchInput from './SearchInput';
import SearchFilters, { SearchFilterProps } from './SearchFilter';
import DataTableFilters, { DataTableFiltersProps } from './DataTableFilters';
import Button from '@material-ui/core/Button';
import * as _roleHelper from '../../helper/role';
import { PaginateProps } from '../../types/state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    table: {
      width: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    row: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.background.default,
      },
      cursor: 'pointer',
    },
  })
);

type HeadProps = {
  rows: any;
  checkbox?: boolean;
  numSelected?: any;
  rowCount?: any;
  onCheckAll?: any;
  order: string;
  orderBy: string;
  loading: boolean;
  onRequestSort: any
}

function DataTableHead({
  rows,
  checkbox,
  numSelected,
  rowCount,
  onCheckAll,
  order,
  orderBy,
  loading,
  onRequestSort
}: HeadProps) {

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  let rowsLength: number = 1;
  if (rows !== undefined) {
    rowsLength = checkbox ? rows.length + 1 : rows.length;
  }

  const loadingCellStyle: any = {
    borderBottom: 'none',
    padding: '0px',
  };

  let setOrder: any = order;

  return (
    <TableHead>
      {
        rows !== undefined &&
        <TableRow>
          {
            checkbox &&
            <TableCell padding="none" className="data-table-checkAll">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onCheckAll}
              />
            </TableCell>
          }
          {
            rows.map((row: DataTableRowsProps, index: number) => (
              <TableCell
                key={'thead-' + index}
                align={row.align}
                padding="none"
              >
                {
                  row.sortField !== undefined &&
                  <TableSortLabel
                    active={orderBy === row.sortField}
                    direction={setOrder}
                    onClick={createSortHandler(row.sortField)}
                  >
                    {row.label}
                  </TableSortLabel>
                }
                {
                  row.sortField === undefined &&
                  <div>{row.label}</div>
                }
              </TableCell>
            ))
          }
        </TableRow>
      }
      {
        loading &&
        <TableRow className="data-table-head-loading-row">
          <TableCell
            colSpan={rowsLength}
            padding='default'
            style={loadingCellStyle}
            className="data-table-head-loading">
            <LinearProgress />
          </TableCell>
        </TableRow>
      }
    </TableHead>
  )
}

export type DataTableHandleClickCellProps<T = any> = {
  record: T;
  getData: (() => void);
  query: any;
}

export type DataTableFuncTbodyRowProps<T = any> = {
  record: T;
  getData: (() => void);
  query: any;
}

export type DataTableActionsFuncProps<T = any> = {
  selected: number[];
  records: T[];
  getData: (() => void);
  query: any;
}

export type DataTableRowsProps<T = any> = {
  label: string;
  value?: string;
  filter?: 'YMDHms' | 'YMDHm' | 'YMD' | 'YM' | 'YYYY' | 'MM' | 'DD' | 'number_format';
  sortField?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  maxLength?: number;
  html?: boolean;
  node?: (({
    record,
    getData,
    query
  }: DataTableFuncTbodyRowProps<T>) => React.ReactNode) | undefined
}

export type DataTableActionsProps<T = any> = {
  title: string;
  func: ({
    selected,
    records,
    getData,
    query
  }: DataTableActionsFuncProps<T>) => void;
  color: 'inherit' | 'default' | 'primary' | 'secondary';
  role?: {
    name: string;
    action: 'view' | 'add' | 'edit' | 'delete' | 'csv_download' | 'csv_import';
  }
}

export type DataTableSearchInputParamsProps = {
  title: string;
  text: string;
}

export type DataTableResponseDataProps<T = any> = {
  data: {
    records: T[];
    paginate: PaginateProps;
  }
}

interface DataTableProps<T = any> {
  init?: boolean;
  rows?: DataTableRowsProps<T>[];
  jsonPath: string;
  urlQuery?: boolean;
  parentProps?: any;
  checkbox?: boolean;
  handleClickCell?: ({
    record,
    getData,
    query
  }: DataTableHandleClickCellProps<T>) => void;
  searchInputParams?: DataTableSearchInputParamsProps[];
  searchFilters?: SearchFilterProps[];
  dataTableParams?: DataTableFiltersProps[];
  actions?: DataTableActionsProps<T>[];
  funcTbodyRow?: ({
    record,
    getData,
    query
  }: DataTableFuncTbodyRowProps<T>) => React.ReactNode;
  filterGroupsInline?: boolean;
  limit?: number;
  size?: 'small' | 'medium' | undefined;
  defaultQueryValues?: {[key: string]: any} | undefined;
}

export default function DataTable<T>({
  init = true,
  rows,
  jsonPath,
  urlQuery,
  parentProps,
  checkbox = true,
  handleClickCell,
  searchInputParams,
  searchFilters,
  dataTableParams,
  actions,
  funcTbodyRow,
  filterGroupsInline,
  limit,
  size,
  defaultQueryValues = {}
}: DataTableProps<T>) {

  const defaultLimit = limit === undefined ? 100 : limit;
  const isSize = size === undefined ? 'medium' : size;

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());
  const refParentProps = useRef(parentProps);
  const refSearchProps = useRef(searchFilters);

  const AuthRoles: any = useSelector(state => state.AuthRoles);

  const [loading, setLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<T[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [paginate, setPaginate] = useState<PaginateProps>({
    mounted: false,
    count: 0,
    prevPage: false,
    nextPage: true,
    start: 0,
    end: 0,
    direction: 'asc',
    directionDefault: 'asc',
    limit: null,
    sort: '',
    sortDefault: '',
    page: 0,
    pageCount: 0
  });
  const [domRectTop, setDomRectTop] = useState<number>(0);
  const [parentDomRect, setParentDomRect] = useState<any>([]);
  const [searchValues, setSearchValues] = useState<any>({});
  const [isInit, setInit] = useState<boolean>(init);
  const [dataTableActions, setDataTableActions] = useState<DataTableActionsProps<T>[]>([]);
  const [queries, setQueries] = useState({
    limit: 100,
    page: 0,
    sort: '',
    direction: 'asc'
  });

  const dataTableRef: any = createRef();

  const setUrlQuery = useCallback((query: any) => {
    if (urlQuery === true) {
      let tmpQuery = Object.assign({}, query);
      if (tmpQuery.page !== undefined) {
        tmpQuery.page += 1;
      }
      const history = refParentProps.current.history === undefined ? '' : refParentProps.current.history;
      const pathname = refParentProps.current.location === undefined ? '' : refParentProps.current.location.pathname;
      history.push({
        pathname: pathname,
        search: '?' + queryString.stringify(tmpQuery)
      });
    }
  }, [urlQuery]);

  const moveDomTop = useCallback(() => {
    if (window.scrollY > domRectTop) {
      window.scrollTo(0, parentDomRect.top)
    }
  }, [domRectTop, parentDomRect.top]);

  const getData = useCallback((setParams?: any) => {
    if (!unmounted.current) {
      setLoading(true);
    }
    let params = setParams === undefined ? Object.assign({}, queries) : setParams;
    const apiUrl: string = _usr_const.ApiUrl === undefined ? '' : _usr_const.ApiUrl;
    if (params.page !== undefined) {
      params.page = params.page + 1;
    }
    axios
      .get(apiUrl + jsonPath, {
        params: params,
        cancelToken: source.current.token
      })
      .then((results: DataTableResponseDataProps<T>) => {
        if (results.data.records !== undefined && !unmounted.current) {
          setRecords(results.data.records);
          setPaginate({
            ...results.data.paginate,
            mounted: true
          });
        }
      })
      .catch((error: any) => {
        _debug.debugAxiosError(error);
      })
      .finally(() => {
        if (!unmounted.current) {
          setSelected([]);
          setLoading(false);
        }
      });
  }, [
    jsonPath,
    unmounted,
    source,
    queries
  ]);

  const handleRequestSort = (event: any, property: any): void => {
    if (unmounted.current) {
      return;
    }
    const sort = property;
    let direction: string = 'asc';
    if (queries.direction === 'asc') {
      direction = 'desc';
    }
    setQueries({
      ...queries,
      sort,
      direction
    });
  };

  const handleCheckAll = (event: any): void => {
    if (unmounted.current) {
      return;
    }
    if (event.target.checked) {
      let newSelected: any = records.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleCheck = (id: any): void => {
    if (unmounted.current) {
      return;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, page: number): void => {
    if (unmounted.current) {
      return;
    }
    if (event !== null) {
      setQueries({ ...queries, page });
    }
  };

  const handleChangeRowsPerPage = (event: any): void => {
    if (unmounted.current) {
      return;
    }
    setQueries({
      ...queries,
      page: 0,
      limit: event.target.value
    });
  };

  const roothandleClickCell = (record: T): void => {
    if (unmounted.current) {
      return;
    }
    if (handleClickCell) {
      handleClickCell({
        record,
        getData,
        query: queries,
      });
    }
  }

  const setSearch = useCallback((key: any, value: any) => {
    if (unmounted.current) {
      return;
    }
    setSearchValues({
      ...searchValues,
      [key]: value
    });
  }, [searchValues, unmounted, setSearchValues]);

  const onSearchSubmit = (event: any): void => {
    if (unmounted.current) {
      return;
    }
    event.preventDefault();
    setQueries({
      ...queries,
      ...searchValues,
      page: 0
    });
  }

  const handleChangeFilters = (values: any) => {
    if (unmounted.current) {
      return;
    }
    let tmpSearchValues: any = {
      ...searchValues,
      ...values
    }
    setSearchValues(tmpSearchValues);
    setQueries({
      ...queries,
      ...tmpSearchValues,
      page: 0
    });
  }

  const getPaginationPageNum = (): number => {
    if (paginate.mounted) {
      return queries.page;
    }
    return 0;
  }

  const getParentQueries = useCallback(() => {
    if (unmounted.current) {
      return {};
    }
    let params: any = {};
    if (urlQuery === true) {
      if (refParentProps.current.location.search !== undefined) {
        const parentDataParams = queryString.parse(refParentProps.current.location.search);
        if (parentDataParams.page !== undefined) {
          params['page'] = Number(parentDataParams.page) - 1;
        }
        if (parentDataParams.limit !== undefined) {
          params['limit'] = Number(parentDataParams.limit);
        }
        if (parentDataParams.direction !== undefined) {
          params['direction'] = parentDataParams.direction;
        }
        if (parentDataParams.sort !== undefined) {
          params['sort'] = parentDataParams.sort;
        }
        // set search values
        if (refSearchProps.current) {
          let parentSearchValues: any = {};
          refSearchProps.current.forEach((sp: any) => {
            if (parentDataParams[sp.key] !== undefined) {
              params[sp.key] = parentDataParams[sp.key];
              parentSearchValues[sp.key] = parentDataParams[sp.key];
            }
          });
          if (Object.keys(parentSearchValues).length > 0) {
            setSearchValues({
              ...searchValues,
              ...parentSearchValues
            });
          }
        }
        // set defaultLimit
        if (parentDataParams.limit === undefined) {
          params['limit'] = defaultLimit;
        }
      }
    }
    return params;
  }, [urlQuery, unmounted, searchValues, defaultLimit, setSearchValues]);

  const setDataTableActionsFunc = useCallback(() => {
    let dataTableBtnAction: DataTableActionsProps<T>[] = [];
    if (actions !== undefined) {
      actions.forEach((element: DataTableActionsProps<T>) => {
        if (element.role === undefined) {
          dataTableBtnAction.push(element);
        } else {
          if (_roleHelper.checkRole(element.role, AuthRoles)) {
            dataTableBtnAction.push(element);
          }
        }
      });
    }
    setDataTableActions(dataTableBtnAction);
  }, [actions, AuthRoles]);

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  // init
  useEffect(() => {
    if (isInit) {
      // set dom rect
      const el: any = dataTableRef.current;
      const domRect: any = el.getBoundingClientRect();

      // set parent dom rect
      const parentEl: any = el.parentNode;
      const parentDomRect: any = parentEl.getBoundingClientRect();

      // set dafaulr queries
      let tmpQueries: any = {
        limit: defaultLimit,
        page: 0,
        sort: '',
        direction: 'asc'
      };
      if (refSearchProps.current !== undefined) {
        refSearchProps.current.forEach((sp: any) => {
          tmpQueries[sp.key] = '';
          if (searchValues[sp.key] !== undefined) {
            tmpQueries[sp.key] = searchValues[sp.key];
          }
        });
      }

      const parentQueries = getParentQueries();
      const tmpDefaultQueryValues = defaultQueryValues === undefined ? {} : defaultQueryValues;
      
      if (!unmounted.current) {
        setInit(false);
        setParentDomRect(parentDomRect);
        setDomRectTop(domRect.top);
        setDataTableActionsFunc();
        setQueries({
          ...tmpQueries,
          ...tmpDefaultQueryValues,
          ...parentQueries
        });
        if (Object.keys(tmpDefaultQueryValues).length > 0) {
          setSearchValues({ ...tmpDefaultQueryValues });
        }
      }
    }
  },
    [
      isInit,
      dataTableRef,
      searchValues,
      parentProps,
      urlQuery,
      defaultLimit,
      defaultQueryValues,
      getData,
      getParentQueries,
      setDataTableActionsFunc
    ]);

  // get data when change queries
  useEffect(() => {
    if (isInit === false) {
      moveDomTop();
      getData();
      setUrlQuery(queries);
    }
  }, [
    isInit,
    queries,
    moveDomTop,
    getData,
    setUrlQuery
  ]);

  const classes = useStyles();

  const isSelected = (id: number): boolean => {
    return selected.indexOf(id) !== -1;
  }

  const perPageOptions: number[] = [100];
  const filterGroupsClassName: 'filter-groups-inline' |  'filter-groups' = filterGroupsInline ? 'filter-groups-inline' : 'filter-groups';

  // set show data type
  let showDataType: 'rows' | 'func' = 'rows';
  if (funcTbodyRow !== undefined) {
    showDataType = 'func';
  }

  const cellData = (row: DataTableRowsProps<T>, record: any, valueKey: string): JSX.Element => {
    let value: any = '';
    if (valueKey.indexOf('.') !== -1) {
      let valueKeys: string[] = valueKey.split('.');
      let tmpValue: any = record;
      while (valueKeys.length > 0) {
        const findKey: string | undefined = valueKeys.shift();
        if (findKey === undefined || typeof tmpValue[findKey] === 'undefined') {
          valueKeys = [];
        }
        if (typeof findKey === 'string') {
          tmpValue = tmpValue[findKey]; 
        }
      }
      if (typeof tmpValue !== 'object') {
        value = tmpValue;
      }
    } else {
      if (typeof record[valueKey] !== 'undefined') {
        value = record[valueKey];
      }
    }
    value = _filter.ShFilter(value, row.filter);
    if (row.maxLength !== undefined) {
      value = value.substr(0, row.maxLength) + '...';
    }
    if (row.html) {
      return (
        <span className="data-table-value" dangerouslySetInnerHTML={{__html: value}}></span>
      );
    }
    return (
      <span className="data-table-value">{value}</span>
    );
  }

  // set body data
  const bodyData = records.map((record: any): JSX.Element => {
    const dataId: number = typeof record.id === 'undefined' ? 0 : record.id;
    const thisSelected: boolean = isSelected(dataId);
    let trStyle = {};
    if (typeof record.color_style !== 'undefined') {
      trStyle = _formatter.formatCssStyleToReactStyle(record.color_style);
    }
    return (
      <TableRow
        hover
        key={dataId}
        aria-checked={thisSelected}
        tabIndex={-1}
        selected={thisSelected}
        role="checkbox"
        className={classes.row}
      >
        {
          checkbox &&
          <TableCell
            padding="none"
            style={trStyle}
            className="data-table-tbody-td-ch"
          >
            <Checkbox
              checked={thisSelected}
              onClick={event => handleCheck(dataId)}
            />
          </TableCell>
        }
        {
          showDataType === 'rows' && rows !== undefined &&
          rows.map((row: DataTableRowsProps<T>, index: number) => (
            <TableCell
              align={row.align}
              key={'tbody-' + index}
              padding="none"
              onClick={(event: any) => roothandleClickCell(record)}
              style={trStyle}
              className="data-table-datd"
            >
              {
                row.label.length > 0 &&
                <span className="data-table-sp-label">{row.label}</span>
              }
              {
                row.node === undefined && row.value !== undefined &&
                <span>
                  {cellData(row, record, row.value)}
                </span>
              }
              {
                row.node !== undefined &&
                <span>
                  {row.node({
                    record,
                    getData: getData,
                    query: queries,
                  })}
                </span>
              }
            </TableCell>
          ))
        }
        {
          showDataType === 'func' && funcTbodyRow !== undefined &&
          <TableCell
            padding="none"
            onClick={(event: any) => roothandleClickCell(record)}
            style={trStyle}
          >
            {funcTbodyRow({
              record,
              getData: getData,
              query: queries,
            })}
          </TableCell>
        }
      </TableRow>
    )
  });

  let dataTableClass: string = "data-table";
  if (checkbox === undefined || checkbox === false) {
    dataTableClass += " data-table-nch"
  }

  return (
    <div className="data-table" ref={dataTableRef}>
      <form
        className={filterGroupsClassName}
        onSubmit={(event) => onSearchSubmit(event)}
        autoComplete="off"
      >
        {
          dataTableActions !== [] &&
          <div className="index-btn-group">
            {
              dataTableActions.map((element: DataTableActionsProps<T>, index: number) => (
                <Button
                  variant="contained"
                  className="btn"
                  color={element.color}
                  onClick={() => {
                    element.func({
                      selected,
                      records,
                      getData: getData,
                      query: queries,
                    })
                  }}
                  key={"data-table-action-btn-" + index}
                >
                  {element.title}
                </Button>
              ))
            }
          </div>
        }
        {
          typeof searchInputParams !== 'undefined' && searchInputParams.length > 0 &&
          <SearchInput
            infoParams={searchInputParams}
            setSearch={setSearch}
            searchValues={searchValues}
            onSearchSubmit={onSearchSubmit}
          />
        }
        {
          typeof searchFilters !== 'undefined' && searchFilters.length > 0 &&
          <SearchFilters
            searchFilters={searchFilters}
            setSearch={setSearch}
            searchValues={searchValues}
            onSearchSubmit={onSearchSubmit}
            queries={queries}
          />
        }
      </form>
      {/* <div className="data-table-head-info">
        <span>件数：</span>
        <span>{paginate.count}件</span>
        <span className="dt-hi-start">{paginate.start}</span>
        <span>-</span>
        <span className="dt-hi-end">{paginate.end}</span>
      </div> */}
      {
        dataTableParams !== undefined &&
        <DataTableFilters
          dataTableParams={dataTableParams}
          handleChangeFilters={handleChangeFilters}
          setSearch={setSearch}
          queries={queries}
        />
      }
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={dataTableClass} aria-labelledby="tableTitle" size={isSize}>
            <DataTableHead
              rows={rows}
              checkbox={checkbox}
              numSelected={selected.length}
              order={queries.direction}
              orderBy={queries.sort}
              onCheckAll={handleCheckAll}
              rowCount={records.length}
              loading={loading}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {bodyData}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={perPageOptions}
          component="div"
          count={paginate.count}
          rowsPerPage={queries.limit}
          page={getPaginationPageNum()}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="表示数"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} / ${count}`;
          }}
        />
      </Paper>
    </div>
  )
}