import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import * as _filter from '../../helper/filter';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import DataTable, {
  DataTableFuncTbodyRowProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
  AttributeGroup,
  AttributeGroupCondition,
  Customer,
  MailMagazineDeliveryHistory
} from '../../types/model'

export default function AttributeGroupsView({ history, match, location }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<AttributeGroup>({
    id: 0,
    common_parent_id: 0,
    name: '',
    memo: '',
    common_parent: undefined
  });
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);
  const [categoryPath, setCategoryPath] = useState<string[]>([]);
  const [conditions, setConditions] = useState<AttributeGroupCondition[]>([]);
  const [orConditions, setOrConditions] = useState<AttributeGroupCondition[]>([]);

  const handleClickOrConditon = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, condition: AttributeGroupCondition): void => {
    event.stopPropagation();
    if (condition.OR !== undefined) {
      const orConditions: AttributeGroupCondition[] = condition.OR;
      setOrConditions(orConditions);
    }
  }

  const handleDialogClose = (): void => {
    setOrConditions([]);
  }

  const renderConditions = (conditionsParam: AttributeGroupCondition[]): JSX.Element => {
    return (
      <ol className="attribute-group-conditions">
        {
          conditionsParam.length > 0 &&
          conditionsParam.map((condition: AttributeGroupCondition, index: number) => (
            <li key={'ag-conditon-' + index}>
              {
                condition.OR === undefined &&
                <div>
                  <span>{condition.name}</span>
                  <span dangerouslySetInnerHTML={{ __html: condition.operator }}></span>
                  <span>{condition.value}</span>
                  <span>{condition.match_conditions}</span>
                </div>
              }
              {
                condition.OR !== undefined &&
                <span
                  className="trim a-span"
                  onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClickOrConditon(event, condition)}
                >
                  {
                    condition.OR.map((or: AttributeGroupCondition, orIndex: number) => (
                      <span key={'ag-or-conditon-' + index + '-' + orIndex}>
                        <span>{or.name}</span>
                        <span dangerouslySetInnerHTML={{ __html: or.operator }}></span>
                        <span>{or.value}</span>
                      </span>
                    ))
                  }
                </span>
              }
            </li>
          ))
        }
      </ol>
    );
  }

  const clickCustomerCell = (callbackParams: DataTableHandleClickCellProps<Customer>): void => {
    history.push('/customers/' + callbackParams.record.id);
  }

  const clickMailMagazineCell = (callbackParams: DataTableHandleClickCellProps<MailMagazineDeliveryHistory>): void => {
    history.push('/mail-magazines/' + callbackParams.record.id);
  }

  const showMailMagazineTbodyRow = (params: DataTableFuncTbodyRowProps<MailMagazineDeliveryHistory>): JSX.Element => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div className="in-content">{_filter.ShFilter(params.record.send_date, 'YMDHms')}</div>
          <div className="in-content">{params.record.subject}</div>
          <div className="in-content"><span dangerouslySetInnerHTML={{ __html: params.record.status_display }}></span></div>
          <div className="in-content">配信数：{_filter.ShFilter(params.record.sent_number, 'number_format')}</div>
        </div>
      </div>
    );
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<AttributeGroup>({
        unmounted,
        source,
        dataKey: 'attributeGroup',
        path: _usr_const.ApiUrl + 'attribute-groups/' + match.params.id + '.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading,
        successCallbackAftereSetData: (results: any) => {
          if (results.data.category_path !== undefined) {
            setCategoryPath(results.data.category_path);
          }
          if (results.data.conditions !== undefined) {
            setConditions(results.data.conditions);
          }
        }
      })
        .catch((error) => {
          _debug.debugAxiosError(error);
        });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'edit',
      func: (): void => {
        history.push('/attribute-groups/' + match.params.id + '/edit');
      },
      role: {
        name: 'AttributeGroups',
        action: 'edit',
      },
    },
  ];

  return (
    <div id="attribute-groups" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>カテゴリ</th>
                    <td>
                      {
                        categoryPath.length > 0 &&
                        <div dangerouslySetInnerHTML={{ __html: categoryPath.join(' &gt; <br />') }}></div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>グループ名称</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>メモ</th>
                    <td>{data.memo}</td>
                  </tr>
                  <tr>
                    <th>条件</th>
                    <td>
                      {renderConditions(conditions)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <Paper className="content-2">
            <DataTable<Customer>
              rows={_usr_const.customerRows}
              checkbox={false}
              jsonPath={`customers/previewAttributeGroup/${match.params.id}/index.json`}
              urlQuery={false}
              limit={5}
              size="small"
              parentProps={{ history, location }}
              handleClickCell={clickCustomerCell}
              defaultQueryValues={
                {
                  attribute_group_id: match.params.id
                }
              }
            />
          </Paper>
          <Paper className="content-2">
            <h2 className="h-2">メール配信履歴</h2>
            <DataTable<MailMagazineDeliveryHistory>
              checkbox={false}
              jsonPath={'mail-magazines.json'}
              urlQuery={false}
              handleClickCell={clickMailMagazineCell}
              funcTbodyRow={showMailMagazineTbodyRow}
              limit={5}
              size="small"
              defaultQueryValues={
                {
                  attribute_group_id: match.params.id,
                  status: 'sent'
                }
              }
            />
          </Paper>
          {
            data.common_parent !== undefined && data.common_parent.logs !== undefined &&
            <ZshLogs logs={data.common_parent.logs} />
          }
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
      <Dialog
        fullWidth={true}
        open={orConditions.length > 0}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">OR条件</DialogTitle>
        <DialogContent>
          {renderConditions(orConditions)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="default">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}