import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import {
  handleChangeRoleAction,
  getRoleNames,
  RoleNamesProps,
  RoleFormDataProps
} from './func/RolesForm';
import * as _usr_const from '../../config/usr-constant';
import { ValidateErrorsProps } from '../../types/state';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import axios from 'axios';
import RoleActionInputs from './RoleActionInputs';
import ZshTextField from '../../components/Form/ZshTextField';

export default function RolesEdit({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const backActionName: string = '/roles';

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());
  const dataSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<RoleFormDataProps>({
    name: '',
    role: {}
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});
  const [roleNames, setRoleNames] = useState<{ [key: string]: RoleNamesProps }>({});
  const [roleNamesError, setRoleNamesError] = useState<boolean>(false);
  const [loadings, setLoadings] = useState({
    roleNames: true
  });

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'roles',
      id: match.params.id,
      method: 'put',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const handleChangeRole = (event?: React.ChangeEvent<HTMLInputElement>, checked?: boolean, roleKey?: string): void => {
    let role: { [key: string]: string[] } = handleChangeRoleAction({
      event,
      checked,
      roleKey,
      formData
    });
    setFormData({ ...formData, role });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      clDSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        await getRoleNames({
          unmounted: unmounted.current,
          setRoleNames,
          setLoadings,
          setRoleNamesError,
          source
        });
        if (dataMounted === false) {
          const tepFormData = Object.assign({}, formData);
          await axios
            .get(_usr_const.ApiUrl + 'roles/' + match.params.id + '.json', {
              cancelToken: dataSource.current.token
            })
            .then((results: any) => {
              if (!unmounted.current) {
                const formData = _form.setFormData(tepFormData, results.data.role);
                setFormData(formData);
              }
            })
            .finally(() => {
              if (!unmounted.current) {
                setLoading(false);
                setDataMounted(true);
              }
              return Promise.resolve(1);
            });
        }
        await ((): any => {
          if (!unmounted.current) {
            setLoading(false);
          }
          return Promise.resolve(1);
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, dataMounted, formData, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="roles" className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                {
                  loadings.roleNames === false &&
                  roleNamesError === false &&
                  <div>
                    <table className="form-table">
                      <tbody>
                        <tr>
                          <th className="required-th">権限グループ名</th>
                          <td>
                            <div className="form-input-group">
                              <ZshTextField
                                value={formData.name}
                                name="name"
                                handleChange={handleChange}
                                required
                                fullWidth
                                validateErrors={validateErrors}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>権限設定</th>
                          <td>
                            <div className="role-actions-wr">
                              {
                                Object.keys(roleNames).length > 0 &&
                                Object.keys(roleNames).map((roleKey: string) => (
                                  <div key={roleKey}>
                                    <div className="role-actions-title">{roleNames[roleKey].title}</div>
                                    <RoleActionInputs
                                      roleKey={roleKey}
                                      actions={roleNames[roleKey].actions}
                                      formDataRole={formData.role}
                                      handleChangeRoleAction={handleChangeRole}
                                    />
                                  </div>
                                ))
                              }
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }
                {
                  loadings.roleNames === false &&
                  roleNamesError === true &&
                  <div>
                    データを取得できませんでした。<br />ページリロードをお願いします。
                </div>
                }
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}