import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import * as _form from '../../helper/form';
import * as _usr_const from '../../config/usr-constant';
import * as _data_actions from '../../helper/dataActions';
import { ValidateErrorsProps } from '../../types/state';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import IconArrowDropUp from '@material-ui/icons/ArrowDropUp';
import IconArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ZshTextField from '../../components/Form/ZshTextField';
import AjaxSelect from '../../components/Form/AjaxSelect';
import DataTable, {
  DataTableActionsProps,
  DataTableRowsProps,
  DataTableFuncTbodyRowProps,
  DataTableActionsFuncProps
} from '../../components/View/DataTable';
import Axios from 'axios';

type CustomerStatusesDataType = {
  id: string;
  name: string;
  alert: string;
  is_default: boolean;
  is_end: boolean;
  color: string;
  background_color: string;
  level: number;
}

type CustomerStatusesFormDataType = {
  name: string;
  alert: string;
  is_default: boolean;
  is_end: boolean;
  color: string;
  background_color: string;
}

type ColorType = {
  name: string;
  color: string;
  background_color: string;
  border_color: string;
}

const initialFormDara: CustomerStatusesFormDataType = {
  name: '',
  alert: '',
  is_default: false,
  is_end: false,
  color: '',
  background_color: '',
}

const initialDara: CustomerStatusesDataType = {
  id: '',
  name: '',
  alert: '',
  is_default: false,
  is_end: false,
  color: '',
  background_color: '',
  level: 0,
}

export default function CustomerStatuses() {

  const unmounted = useRef(false);
  const alertsSource = useRef(Axios.CancelToken.source());
  const colorsSource = useRef(Axios.CancelToken.source());

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const [dataTableCallbackParams, setDataTableCallbackParams] = useState({
    getData: () => {},
    query: {},
  });
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showViewDialog, setShowViewDialog] = useState<boolean>(false);
  const [showEditLevelDialog, setShowEditLevelDialog] = useState<boolean>(false);
  const [formData, setFormData] = useState<CustomerStatusesFormDataType>(initialFormDara);
  const [data, setData] = useState<CustomerStatusesDataType>(initialDara);
  const [editLevelData, setEditLevelData] = useState<{[key: number]: CustomerStatusesDataType} | undefined>(undefined);
  const [formType, setFormType] = useState<'add' | 'edit'>('add');
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});
  const [alerts, setAlerts] = useState<{[key: string]: string} | undefined>(undefined);
  const [colors, setColors] = useState<{[key: string]: ColorType} | undefined>(undefined);

  const clickCell = (callbackParams: DataTableFuncTbodyRowProps) => {
    setDataTableCallbackParams(callbackParams);
    setData(callbackParams.record);
    setShowViewDialog(true);
  }

  const handleChangeFormData = (event: any): void  => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  }

  const handleClickAdd = (callbackParams: DataTableActionsFuncProps): void => {
    setDataTableCallbackParams(callbackParams);
    setFormType('add');
    setFormData({
      ...initialFormDara
    });
    setValidateErrors({});
    setShowDialog(true);
  }

  const handleClickEditLevel = (callbackParams: DataTableActionsFuncProps<CustomerStatusesDataType>): void => {
    setDataTableCallbackParams(callbackParams);
    setShowDialog(false);
    setShowViewDialog(false);
    let tmp: {[key: number]: CustomerStatusesDataType} = {};
    if (callbackParams.records.length > 0) {
      callbackParams.records.forEach((d: CustomerStatusesDataType) => {
        tmp[d.level] = d;
      });
    }
    setEditLevelData(tmp);
    setShowEditLevelDialog(true);
  }

  const handleClickColor = (color: ColorType): void => {
    setFormData({
      ...formData,
      color: color.color,
      background_color: color.background_color
    });
  }

  const colorDemoBackgroundColor = (): React.CSSProperties => {
    let style: React.CSSProperties = {
      padding: '10px'
    };
    if (formData.background_color !== '') {
      style.backgroundColor = formData.background_color;
    }
    return style;
  }

  const colorDemoColor = (): React.CSSProperties => {
    let style: React.CSSProperties = {};
    if (formData.color !== '') {
      style.color = formData.color;
    }
    return style;
  }

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    setShowViewDialog(false);
    setFormType('edit');
    setFormData({
      ...data
    });
    setValidateErrors({});
    setShowDialog(true);
  }

  const formFinished = (): void => {
    dataTableCallbackParams.getData();
    setShowDialog(false);
    setShowViewDialog(false);
    setShowEditLevelDialog(false);
  }

  const handleAddSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'customer-statuses',
        formData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError,
        setValidateErrors
      },
    );
  }

  const handleEditSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'customer-statuses',
        formData,
        id: data.id,
        method: 'put',
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError,
        setValidateErrors
      },
    );
  }

  const handleDeletePost = () => {
    if (data.id !== undefined) {
      const selected: number[] = [Number(data.id)];
      _data_actions.deleteData({
        deleteUrl: 'customer-statuses/isdelete/',
        selected: selected,
        getData: dataTableCallbackParams.getData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      });
    } else {
      setFlashMessageError('削除対象を取得できませんでした');
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '削除してよろしいですか？¥nこの操作は元に戻せません。',
      closeText: 'キャンセル',
      agreeText: '削除する',
      funcAgree: () => {
        handleDeletePost();
      },
      setZshDialog
    });
  }

  const handleChangeLevel = (d: CustomerStatusesDataType, type: 'up' | 'down'): void => {
    if (editLevelData !== undefined) {
      let tmpSetData: {[key: number]: CustomerStatusesDataType} = {};
      let tmpData: {[key: number]: CustomerStatusesDataType} = {};
      if (Object.keys(editLevelData).length > 0) {
        if (type === 'up') {
          if (d.level > 0) {
            const beforeLevel: number = d.level - 1;
            Object.values(editLevelData).forEach((ed: CustomerStatusesDataType) => {
              let thisLevel: number = ed.level;
              if (ed.level === d.level) {
                thisLevel = d.level - 1;
              } else if (beforeLevel === ed.level) {
                thisLevel = d.level;
              }
              tmpData[thisLevel] = ed;
            }); 
          }
        }
        if (type === 'down') {
          const afterLevel: number = d.level + 1;
          Object.values(editLevelData).forEach((ed: CustomerStatusesDataType) => {
            let thisLevel: number = ed.level;
            if (ed.level === d.level) {
              thisLevel = d.level + 1;
            } else if (afterLevel === ed.level) {
              thisLevel = d.level;
            }
            tmpData[thisLevel] = ed;
          });
        }
      }
      // reset level
      if (Object.keys(tmpData).length > 0) {
        Object.values(tmpData).forEach((tmd: CustomerStatusesDataType, index: number) => {
          tmd.level = index;
          tmpSetData[index] = tmd;
        })
      }
      setEditLevelData(tmpSetData);
    }
  }

  const handleEditLevelSubmit = (): void => {
    if (typeof editLevelData === 'object' && Object.keys(editLevelData).length > 0) {
      let postData: {
        id: string;
        level: number
      }[] = [];
      Object.values(editLevelData).forEach((d: CustomerStatusesDataType) => {
        if (d.id !== undefined) {
          postData.push({
            id: d.id,
            level: d.level
          });
        }
      });
      _form.handleSubmit(
        {
          action: 'settings/customer-statuses-edit-level',
          formData: {
            data: postData
          },
          callbackSuccess: formFinished,
          setOverlayProppress,
          setFlashMessageSuccess,
          setFlashMessageError,
          setValidateErrors
        },
      );
    } else {
      alert('対象データがありません');
    }
  }

  // clean up
  useEffect(() => {
    const alSource = Object.assign({}, alertsSource.current);
    const clSource = Object.assign({}, colorsSource.current);
    return () => {
      // cancel axios get
      alSource.cancel();
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  // get alerts
  useEffect(() => {
    if (alerts === undefined) {
      Axios.get(
        _usr_const.ApiUrl + 'activity-statuses/alerts.json',
        {
          cancelToken: alertsSource.current.token
        }
      )
      .then((results: any) => {
        if (!unmounted.current) {
          setAlerts(results.data.alerts);
        }
      })
      .catch(() => {
        setAlerts({});
      });
    }
  }, [alerts, unmounted]);

  // get colors
  useEffect(() => {
    if (colors === undefined) {
      Axios.get(
        _usr_const.ApiUrl + 'activity-statuses/colors.json',
        {
          cancelToken: colorsSource.current.token
        }
      )
      .then((results: any) => {
        if (!unmounted.current) {
          setColors(results.data.colors);
        }
      })
      .catch(() => {
        setColors({});
      });
    }
  }, [colors, unmounted]);

  const isDefaultNode = (callbackParams: DataTableFuncTbodyRowProps): React.ReactNode => {
    return (
      <span>
        {
          callbackParams.record.is_default === true &&
          <span>&#10004;</span>
        }
      </span>
    )
  }

  const isEndtNode = (callbackParams: DataTableFuncTbodyRowProps): React.ReactNode => {
    return (
      <span>
        {
          callbackParams.record.is_end === true &&
          <span>&#10004;</span>
        }
      </span>
    )
  }

  const rows: DataTableRowsProps<CustomerStatusesDataType>[] = [
    {
      label: 'ステータス名',
      value: 'name',
      align: 'left',
    },
    {
      label: 'ステータスレベル',
      value: 'level',
      align: 'left'
    },
    {
      label: '通知レベル',
      value: 'alert',
      align: 'left',
    },
    {
      label: 'デフォルト値',
      align: 'center',
      node: isDefaultNode
    },
    {
      label: '終了ステータス',
      align: 'center',
      node: isEndtNode
    },
  ];

  const actions: DataTableActionsProps<CustomerStatusesDataType>[] = [
    {
      title: '追加',
      func: handleClickAdd,
      color: 'primary',
    },
    {
      title: 'ステータスレベル変更',
      func: handleClickEditLevel,
      color: 'default',
    }
  ];

  return (
    <Paper className="content-2 customer-statuses">
      <h2 className="h-2">顧客ステータス</h2>
      <DataTable<CustomerStatusesDataType>
        rows={rows}
        checkbox={false}
        jsonPath={'customer-statuses.json'}
        urlQuery={false}
        actions={actions}
        filterGroupsInline={true}
        handleClickCell={clickCell}
        limit={10}
        size="small"
      />
      <Dialog
        fullWidth={true}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {
            formType === 'add' &&
            <span>
              顧客ステータス登録
            </span>
          }
          {
            formType === 'edit' &&
            <span>
              顧客ステータス編集
            </span>
          }
        </DialogTitle>
        <DialogContent>
          <form className="form-content dialog-form">
            <div className="view-content-in form-content">
              <div className="form-input-group">
                <ZshTextField
                  value={formData.name}
                  name="name"
                  handleChange={handleChangeFormData}
                  label="名前"
                  fullWidth
                  required
                  validateErrors={validateErrors}
                />
              </div>
              <div className="form-input-group">
                <AjaxSelect
                name="alert"
                label="通知レベル"
                menuItems={alerts}
                formData={formData}
                handleChange={handleChangeFormData}
                validateErrors={validateErrors}
                />
              </div>
              <div className="form-input-group">
                <FormGroup>
                  <FormControlLabel
                    label="デフォルト値"
                    control={
                      <Checkbox
                        color="primary"
                        name="is_default"
                        checked={formData.is_default}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          setFormData({
                            ...formData,
                            is_default: checked
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
              </div>
              <div className="form-input-group">
                <FormGroup>
                  <FormControlLabel
                    label="終了ステータス"
                    control={
                      <Checkbox
                        color="primary"
                        name="is_end"
                        checked={formData.is_end}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          setFormData({
                            ...formData,
                            is_end: checked
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
              </div>
              <div className="form-input-group">
                <p style={{ fontWeight: 'bold' }}>色設定</p>
                {
                  typeof colors === 'object' && Object.keys(colors).length > 0 &&
                  <div>
                    {
                      Object.keys(colors).map((key: string, index: number) => (
                        <Button
                          key={`as-at-col-${index}`}
                          onClick={() => handleClickColor(colors[key])}
                        >
                          {colors[key].name}
                        </Button>
                      ))
                    }
                  </div>
                }
                <div>
                  文字色　
                  <input
                    type="color"
                    name="color"
                    value={(() => {
                      if (formData.color !== '') {
                        return formData.color;
                      }
                      return '#000000';
                    })()}
                    onChange={handleChangeFormData}
                  />
                </div>
                <div>
                  背景色　
                  <input
                    type="color"
                    name="background_color"
                    value={(() => {
                      if (formData.background_color !== '') {
                        return formData.background_color;
                      }
                      return '#000000';
                    })()}
                    onChange={handleChangeFormData}
                  />
                </div>
                <div>
                  <p style={{ fontSize: '0.9em' }}>表示例</p>
                  <div
                    style={colorDemoBackgroundColor()}
                  >
                    <div
                      style={colorDemoColor()}
                    >
                      テキスト
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
          onClick={() => {
            if (formType === 'add') {
              handleAddSubmit();
            }
            if (formType === 'edit') {
              handleEditSubmit();
            }
          }}
          color="primary"
          autoFocus>
            保存
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={showViewDialog}
        onClose={() => setShowViewDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>顧客ステータス詳細</DialogTitle>
        <DialogContent>
          <table className="table-1">
            <tbody>
              <tr>
                <th>名前</th>
                <td>{data.name}</td>
              </tr>
              <tr>
                <th>通知レベル</th>
                <td>{data.alert}</td>
              </tr>
              <tr>
                <th>デフォルト値</th>
                <td>
                  {
                    data.is_default === true &&
                    <span>&#10004;</span>
                  }
                </td>
              </tr>
              <tr>
                <th>終了ステータス</th>
                <td>
                  {
                    data.is_end === true &&
                    <span>&#10004;</span>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowViewDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
            onClick={handleEdit}
            color="primary"
          >
            編集
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={showEditLevelDialog}
        onClose={() => setShowEditLevelDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle> 商談ステータス ステータスレベル設定</DialogTitle>
        <DialogContent>
          <List>
            {
              typeof editLevelData !== 'undefined' && Object.keys(editLevelData).length > 0 &&
              Object.values(editLevelData).map((d: CustomerStatusesDataType, index: number) => (
                <ListItem
                key={`as-edit-level-${index}`}
                >
                  <ListItemText
                  primary={d.name}
                  />
                  <ListItemSecondaryAction>
                      <IconButton
                      edge="end"
                      aria-label="up"
                      onClick={() => {
                        if (index !== 0) {
                          handleChangeLevel(d, 'up');
                        }
                      }}
                      disabled={index === 0}
                      >
                        <IconArrowDropUp />
                      </IconButton>
                      <IconButton
                      edge="end"
                      aria-label="down"
                      onClick={() => {
                        if ((index + 1) !== Object.keys(editLevelData).length) {
                          handleChangeLevel(d, 'down');
                        }
                      }}
                      disabled={(index + 1) === Object.keys(editLevelData).length}
                      >
                        <IconArrowDropDown />
                      </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowEditLevelDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
            onClick={handleEditLevelSubmit}
            color="primary"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}