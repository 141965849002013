import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import FormControl from '@material-ui/core/FormControl';
import '../../scss/ReactTags.scss';

type ZshTagInputTagProps = {
  id: string;
  text: string;
}

type ZshTagInputProps = {
  name: string;
  title?: string;
  placeholder?: string;
  note?: string;
  formData: any;
  handleChange: (name: string, vaue: string) => void
}

export default function ZshTagInput({
  name,
  title,
  placeholder = '追加',
  note = '複数設定する場合は、カンマ区切りで入力してください。',
  formData,
  handleChange
}: ZshTagInputProps) {

  const KeyCodes: { [key: string]: number } = {
    comma: 188,
    enter: 13,
  };

  const delimiters: number[] = [KeyCodes.comma, KeyCodes.enter];

  const setTags = (): ZshTagInputTagProps[] => {
    if (formData[name] !== undefined) {
      if (formData[name] === '') {
        return [];
      } else {
        const formTags = formData[name].split(',');
        const tags: ZshTagInputTagProps[] = [];
        formTags.forEach((val: string) => {
          tags.push({ id: val, text: val });
        });
        return tags;
      }
    } else {
      return [];
    }
  }

  const setValues = (tags: ZshTagInputTagProps[]): string => {
    const results: any = [];
    tags.forEach((val: any) => {
      results.push(val.text);
    });
    return results.join(',');
  }

  const handleDelete = (i: number): void => {
    const tags = setTags();
    handleChange(
      name,
      setValues(tags.filter((tag: any, index: number) => index !== i))
    );
  }

  const handleAddition = (tag: ZshTagInputTagProps): void => {
    const tags = setTags();
    const newTags = [...tags, tag];
    handleChange(name, setValues(newTags));
  }

  const handleDrag = (tag: ZshTagInputTagProps, currPos: number, newPos: number): void => {
    const tags = setTags();
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);
    handleChange(name, setValues(tags));
  }

  return (
    <div className="zsh-tag-input">
      <FormControl fullWidth className="zsh-select-root">
        {
          title !== undefined &&
          <span className="ReactTagsTitle MuiFormLabel-root">{title}</span>
        }
        <ReactTags
          tags={setTags()}
          placeholder={placeholder}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          delimiters={delimiters}
        />
        {
          note !== undefined &&
          <div className="ReactTagsNote">
            {note}
          </div>
        }
      </FormControl>
    </div>
  )
}