import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import * as _filter from '../../helper/filter';
import { Log } from '../../types/model';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    details: {
      padding: '0',
      overflow: 'auto',
      maxHeight: '300px',
    },
    span: {
      display: 'inline-block',
      paddingRight: '10px',
    },
    spanFirst: {
      display: 'inline-block',
    },
    logTitle: {
      padding: '0px 10px',
    },
    logUl: {
      width: '100%',
      margin: '0',
      padding: '0',
    },
    logLi: {
      listStyle: 'none',
      padding: '10px',
      borderBottom: '1px solid #EEE',
      '&:last-child': {
        borderBottom: 'none',
      }
    }
  }));

type Props = {
  logs: Log[];
}

type ZshLogsProps = Props & RouteComponentProps;

function ZshLogs({ logs, history }: ZshLogsProps) {

  const [lastUpdateDatetime, setLastUpdateDatetime] = useState<string | null>(null);
  const [lastUpdateUserName, setLastUpdateUserName] = useState<string | null>(null);

  const classes = useStyles();

  const logsStyle = {
    margin: '30px 0',
  };

  const handleClickView = (log: Log): void => {
    history.push('/logs/' + log.id);
  }

  const renderLogRow = (log: Log): JSX.Element => {
    const editType: string = log.is_new ? '登録' : '更新';
    let userFullName: string = '';
    if (
      log.user !== undefined &&
      log.user !== null &&
      typeof log.user.full_name === 'string'
    ) {
      userFullName = log.user.full_name;
    }
    return (
      <>
        <span className={classes.spanFirst}>
          {editType}：
        </span>
        <span className={classes.span}>
          {_filter.ShFilter(log.created, 'YMDHms')}
        </span>
        <span className={classes.span}>
          {userFullName}
        </span>
      </>
    );
  }

  useEffect(() => {
    if (lastUpdateDatetime === null) {
      if (typeof logs === 'object' && logs[0] !== undefined) {
        if (logs[0].created !== undefined) {
          setLastUpdateDatetime(logs[0].created); 
        } else {
          setLastUpdateDatetime('');
        }
        if (logs[0].user !== undefined && logs[0].user !== null) {
          if (typeof logs[0].user.full_name !== 'undefined') {
            setLastUpdateUserName(logs[0].user.full_name); 
          }
        }
      } else {
        setLastUpdateDatetime('');
        setLastUpdateUserName('');
      }
    }
  }, [lastUpdateDatetime, logs]);

  return (
    <div className="zsh-logs" style={logsStyle}>
      <Accordion className={classes.root}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.logTitle}>
          <span className={classes.spanFirst}>最終更新：</span>
          <span className={classes.span}>{_filter.ShFilter(lastUpdateDatetime, 'YMDHms')}</span>
          <span className={classes.span}>{lastUpdateUserName}</span>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <ul className={classes.logUl}>
            {
              typeof logs === 'object' &&
              logs.map((log: Log, index: number) => (
                <li
                  key={`zsh-log-${index}`}
                  className={classes.logLi}
                  onClick={() => handleClickView(log)}
                >
                  {renderLogRow(log)}
                </li>
              ))
            }
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default withRouter(ZshLogs);