import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import * as _usr_const from '../../config/usr-constant';
import { getFormEventNameValue } from '../../helper/form';
import * as _debug from '../../helper/debug';
import * as _data_actions from '../../helper/dataActions';
import { checkUploadCsvFile } from '../../helper/form';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';

const useStyles = makeStyles({
  erCell: {
    backgroundColor: 'rgb(253, 236, 234)',
    color: 'rgb(97, 26, 21)',
  },
});

type CustomersCsvCheckedProps = {
  csv_count_rows: number;
  csv_import_count: number;
  csv_error_row_count: number;
  csv_errors: string[];
  csv_file_name: string;
}

type CustomersCsvFormProps = {
  not_first_row_import: boolean;
}

export default function CustomersImportCsv({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const sourceDownloadCsv = useRef(axios.CancelToken.source());
  const sourceCheckImportCsv = useRef(axios.CancelToken.source());
  const sourceImportCsv = useRef(axios.CancelToken.source());

  const actionName: string = 'customers/import-csv/';

  const defaultCheckedCsvData: CustomersCsvCheckedProps = {
    csv_count_rows: 0,
    csv_import_count: 0,
    csv_error_row_count: 0,
    csv_errors: [],
    csv_file_name: ''
  };

  const [file, setFile] = useState<any>({});
  const [formData, setFormData] = useState<CustomersCsvFormProps>({
    not_first_row_import: false
  });
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileErrorMessages, setFileErrorMessages] = useState<string[]>([]);
  const [confirmSubmitDisable, setConfirmSubmitDisable] = useState<boolean>(true);
  const [step, setStep] = useState<'init' | 'check'>('init');
  const [checkedCsvData, setCheckedCsvData] = useState<CustomersCsvCheckedProps>(defaultCheckedCsvData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const nameValue = getFormEventNameValue(event);
    setFormData({ ...formData, ...nameValue });
  };

  const handleConfirmSubmit = (event: any): void => {
    event.preventDefault();
    setOverlayProppress(true);
    const params: any = new FormData();
    params.append('file', file);
    params.append('action', 'check');
    const not_first_row_import: number = formData.not_first_row_import ? 1 : 0;
    params.append('not_first_row_import', not_first_row_import);
    axios
      .post(
        _usr_const.ApiUrl + actionName,
        params,
        {
          cancelToken: sourceCheckImportCsv.current.token,
          headers: {
            'content-type': 'multipart/form-data',
          }
        }
      )
      .then((response: any) => {
        setCheckedCsvData(response.data);
        setStep('check');
      })
      .catch((error: any) => {
        _debug.debugAxiosError(error);
        setCheckedCsvData(defaultCheckedCsvData);
        if (error.response) {
          setFlashMessageError(error.response.data.message);
        } else {
          setFlashMessageError('エラーが発生しました');
        }
        setStep('init');
      })
      .finally(() => {
        setOverlayProppress(false);
      });
  }

  const handleImportSubmit = (): void => {
    setOverlayProppress(true);
    const params: any = new FormData();
    params.append('file', file);
    params.append('action', 'import');
    const not_first_row_import: number = formData.not_first_row_import ? 1 : 0;
    params.append('not_first_row_import', not_first_row_import);
    axios
      .post(
        _usr_const.ApiUrl + actionName,
        params,
        {
          cancelToken: sourceImportCsv.current.token,
          headers: {
            'content-type': 'multipart/form-data',
          }
        }
      )
      .then((response: any) => {
        setFlashMessageSuccess('CSVインポートをおこないました');
        history.push('/customers');
      })
      .catch((error: any) => {
        _debug.debugAxiosError(error);
        setFlashMessageError(error.response.data[0]);
      })
      .finally(() => {
        setOverlayProppress(false);
      });
  }

  const handleSelectFile = (event: any): void => {
    const check_file = checkUploadCsvFile(event);
    setFileErrorMessages(check_file.errorMessage);
    setFileError(check_file.er);
    setFile(check_file.file);
    setConfirmSubmitDisable(check_file.er)
  }

  const backInit = (): void => {
    setFile({});
    setFormData({ not_first_row_import: false });
    setFileError(false);
    setFileErrorMessages([]);
    setConfirmSubmitDisable(true);
    setStep('init');
  }

  const handleDownloadCsv = (params: any): void => {
    _data_actions.downloadCsv({
      downloadUrl: 'customers/sample-csv/',
      source: sourceDownloadCsv,
      fileName: 'sample_customers',
      setOverlayProppress,
      setFlashMessageError
    });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, sourceDownloadCsv.current);
    const clIcSource = Object.assign({}, sourceImportCsv.current);
    const clIchSource = Object.assign({}, sourceCheckImportCsv.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      clIcSource.cancel();
      clIchSource.cancel();
    }
  }, []);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      text: 'サンプルCSVをダウンロード',
      func: handleDownloadCsv,
    }
  ];

  const classes = useStyles();

  return (
    <div id="customers" className="content-1">
      <div>
        {
          step === 'init' &&
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
        }
        {
          step === 'check' &&
          <div className="btn-group">
            <span>
              <Button variant="contained" className="btn" onClick={backInit}>戻る</Button>
            </span>
          </div>
        }
        <Paper>
          {
            step === 'init' &&
            <form onSubmit={handleConfirmSubmit}>
              <div className="view-content-in form-content form-file-content">
                <div className="form-input-group">
                  <InputLabel
                    error={fileError}
                  >ファイル</InputLabel>
                  <Input
                    name="file"
                    type="file"
                    onChange={handleSelectFile}
                    error={fileError}
                  />
                  {
                    fileErrorMessages.length > 0 &&
                    <div className="input-er-wr">
                      {
                        fileErrorMessages.map((msg: string, index: number) => (
                          <span key={'erMsg-' + index} className="input-er-msg">
                            <span>{msg}</span><br />
                          </span>
                        ))
                      }
                    </div>
                  }
                </div>
                <div className="form-input-group">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.not_first_row_import}
                        onChange={handleChange}
                        name="not_first_row_import"
                        color="primary"
                        value={true}
                      />
                    }
                    label="1行目は取り込まない"
                  />
                  <div className="note-1">1行目に項目名等を記載している場合はチェックを付けてください。</div>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn"
                  disabled={confirmSubmitDisable}
                >
                  インポート
              </Button>
              </div>
            </form>
          }
          {
            step === 'check' &&
            <div className="view-content-in form-content form-file-content">
              {
                checkedCsvData.csv_import_count === 0 &&
                <p>インポート出来るデータがありません</p>
              }
              {
                checkedCsvData.csv_import_count > 0 &&
                <p>インポートを実行します。よろしいですか？</p>
              }
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">ファイル名</TableCell>
                      <TableCell>{checkedCsvData.csv_file_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">CSV内レコード件数</TableCell>
                      <TableCell>{checkedCsvData.csv_count_rows.toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">正常件数</TableCell>
                      <TableCell>
                        {
                          checkedCsvData.csv_import_count.toLocaleString()
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">エラー件数</TableCell>
                      <TableCell>
                        {(() => {
                          if (checkedCsvData.csv_error_row_count > 0) {
                            return (
                              <span className="red">
                                {checkedCsvData.csv_error_row_count.toLocaleString()}
                              </span>
                            );
                          }
                          return 0;
                        })()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {
                checkedCsvData.csv_error_row_count > 0 &&
                <div>
                  <h2 className="h-3">エラー内容</h2>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>行</TableCell>
                          <TableCell>項目</TableCell>
                          <TableCell>エラー内容</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          checkedCsvData.csv_errors.map((el: any, index: number) => (
                            <TableRow key={'csvEr' + index}>
                              <TableCell className={classes.erCell}>{el.line}</TableCell>
                              <TableCell className={classes.erCell}>{el.display}</TableCell>
                              <TableCell className={classes.erCell}>{el.message}</TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              }
              <div className="btn-group">
                <span>
                  <Button variant="contained" className="btn" onClick={backInit}>戻る</Button>
                </span>
                {
                  checkedCsvData.csv_import_count > 0 && checkedCsvData.csv_error_row_count === 0 &&
                  <span>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="btn"
                      onClick={handleImportSubmit}
                    >
                      インポート実行
                    </Button>
                  </span>
                }
              </div>
            </div>
          }
        </Paper>
      </div>
    </div>
  )
}