import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import { ValidateErrorsProps } from '../../types/state';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';

export default function MailFromsEdit({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName: string = 'mail-froms/edit/';
  const backActionName: string = '/mail-froms';

  const unmounted = useRef<boolean>(false);
  const dataSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState({
    email: '',
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'mail-froms',
      id: match.params.id,
      method: 'put',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      // cancel axios get
      clDSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      const initFunc = async () => {
        if (dataMounted === false) {
          const tepFormData = Object.assign({}, formData);
          await axios
            .get(_usr_const.ApiUrl + actionName + match.params.id + '/index.json', {
              cancelToken: dataSource.current.token
            })
            .then((results: any) => {
              if (!unmounted.current) {
                const formData = _form.setFormData(tepFormData, results.data.mailFrom);
                setDataMounted(true);
                setFormData(formData);
              }
            })
            .finally(() => {
              return Promise.resolve(1);
            });
        }
        await ((): any => {
          if (!unmounted.current) {
            setLoading(false);
          }
          return Promise.resolve(1);
        })();
      }
      initFunc();
      setInit(false);
    }
  }, [isInit, dataMounted, formData, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="mail-froms" className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">From</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.email}
                            name="email"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}