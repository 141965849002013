import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { withStyles } from '@material-ui/core/styles';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import * as _usr_const from '../../config/usr-constant';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import ZshTagInput from '../../components/Form/ZshTagInput';
import ZshEditor from '../../components/Form/ZshEditor';
import ZshRadio from '../../components/Form/ZshRadio';
import { AppSetting } from '../../types/model';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function AppSettingsEdit({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName: string = 'app-settings/edit';
  const backActionName: string = '/app-settings';

  const unmounted = useRef<boolean>(false);
  const dataSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<AppSetting>({
    account_lock: 1,
    page_limit: 1,
    password_expiration_date: false,
    password_expiration_date_num: 1,
    password_expiration_date_type: 'months',
    password_min_length: 6,
    add_customer_notice_emails: '',
    tax_rate: 0.08,
    email_notification: false,
    mail_from: '',
    mail_from_name: '',
    mail_footer: undefined,
    mail_stop_number: 0,
  });
  const [showPasswordExpirationDateInputs, setShowPasswordExpirationDateInputs] = useState<boolean>(false);

  const handleChange = (event: any): void => {
    let keyValue = _form.getFormEventNameValue(event);
    // toggle password_expiration_date inputs
    if (Object.keys(keyValue)[0] === 'password_expiration_date') {
      setShowPasswordExpirationDateInputs(Object.values(keyValue)[0]);
    }
    setFormData({ ...formData, ...keyValue });
  };

  const handleChangeContent = (editorContent: string): void => {
    setFormData({ ...formData, mail_footer: editorContent });
  }

  const handleChangeTags = (name: string, value: string): void => {
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    const tmpFormData: any = Object.assign({}, formData);
    //format form data
    if (tmpFormData.password_expiration_date === false) {
      delete tmpFormData.password_expiration_date_num;
      delete tmpFormData.password_expiration_date_type;
    }
    _form.handleSubmit({
      action: 'app-settings/edit',
      formData: tmpFormData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  // clean up
  useEffect(() => {
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      // cancel axios get
      clDSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      if (dataMounted === false) {
        const tepFormData = Object.assign({}, formData);
        axios
          .get(_usr_const.ApiUrl + actionName + '/index.json', {
            cancelToken: dataSource.current.token
          })
          .then((results: any) => {
            if (!unmounted.current) {
              const formData = _form.setFormData(tepFormData, results.data.appSetting);
              if (formData.password_expiration_date_num === null || formData.password_expiration_date_num === '') {
                formData.password_expiration_date_num = 1;
              }
              if (formData.password_expiration_date_type === null || formData.password_expiration_date_type === '') {
                formData.password_expiration_date_type = 'months';
              }
              setDataMounted(true);
              setFormData(formData);
            }
          })
          .finally(() => {
            if (!unmounted.current) {
              setLoading(false);
            }
          });
      }
      setInit(false);
    }
  }, [isInit, dataMounted, formData]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="app-setting-edit" className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <div>
                  <table className="form-table">
                    <tbody>
                      <tr>
                        <th>ロックアウト回数</th>
                        <td>
                          <div className="form-input-group">
                            <FormControl fullWidth className="zsh-select-root">
                              <Select
                                name="account_lock"
                                value={formData.account_lock}
                                onChange={handleChange}
                              >
                                {(() => {
                                  const accountLockitems: any = [];
                                  for (let i = 1; i <= 10; i++) {
                                    accountLockitems.push(<MenuItem value={i} key={'account_lock' + i}>{i}</MenuItem>);
                                  }
                                  return accountLockitems;
                                })()}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>ページ毎の表示件数</th>
                        <td>
                          <div className="form-input-group">
                            <FormControl fullWidth className="zsh-select-root">
                              <Select
                                name="page_limit"
                                value={formData.page_limit}
                                onChange={handleChange}
                              >
                                {(() => {
                                  const pageLimitItems: any[] = [];
                                  [1, 10, 100, 200, 500].map((val) => pageLimitItems.push(<MenuItem value={val} key={'page_limit' + val}>{val}</MenuItem>))
                                  return pageLimitItems;
                                })()}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>パスワード有効期限</th>
                        <td>
                          <div className="form-input-group">
                            <ZshRadio
                              name="password_expiration_date"
                              value={formData.password_expiration_date}
                              handleChange={handleChange}
                              options={
                                {
                                  '無期限': false,
                                  '期限を設定': true
                                }
                              }
                            />
                            {
                              showPasswordExpirationDateInputs &&
                              <div className="form-input-group">
                                <FormControl fullWidth className="zsh-select-root">
                                  <Select
                                    name="password_expiration_date_num"
                                    value={formData.password_expiration_date_num}
                                    onChange={handleChange}
                                  >
                                    {(() => {
                                      const passwordExpirationDateNumItems: any = [];
                                      for (let i = 1; i <= 12; i++) {
                                        passwordExpirationDateNumItems.push(<MenuItem value={i} key={'password_expiration_date_num' + i}>{i}ヶ月</MenuItem>);
                                      }
                                      return passwordExpirationDateNumItems;
                                    })()}
                                  </Select>
                                </FormControl>
                                <input type="hidden" name="password_expiration_date_type" value="formData.password_expiration_date_type" />
                              </div>
                            }
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>パスワード文字数</th>
                        <td>
                          <div className="form-input-group">
                            <FormControl fullWidth className="zsh-select-root">
                              <Select
                                name="password_min_length"
                                value={formData.password_min_length}
                                onChange={handleChange}
                              >
                                {(() => {
                                  const passwordMinLengthitems: any = [];
                                  for (let i = 6; i <= 12; i++) {
                                    passwordMinLengthitems.push(<MenuItem value={i} key={'password_min_length' + i}>英数字{i}文字以上</MenuItem>);
                                  }
                                  return passwordMinLengthitems;
                                })()}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>顧客登録通知先</th>
                        <td>
                          <div className="form-input-group">
                            <ZshTagInput
                              name="add_customer_notice_emails"
                              placeholder="メールアドレス追加"
                              formData={formData}
                              handleChange={handleChangeTags}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>税率</th>
                        <td>
                          <div className="form-input-group">
                            <FormControl fullWidth className="zsh-select-root">
                              <Select
                                name="tax_rate"
                                value={formData.tax_rate}
                                onChange={handleChange}
                              >
                                {(() => {
                                  const taxRateItems: any[] = [];
                                  [0.08, 0.1].map((val) => taxRateItems.push(<MenuItem value={val} key={'tax_rate' + val}>{val * 100}%</MenuItem>))
                                  return taxRateItems;
                                })()}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>メール通知機能</th>
                        <td>
                          <div className="form-input-group">
                            <FormControl fullWidth className="zsh-select-root">
                              <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                  <Grid item>Off</Grid>
                                  <Grid item>
                                    <AntSwitch checked={formData.email_notification} onChange={handleChange} name="email_notification" />
                                  </Grid>
                                  <Grid item>On</Grid>
                                </Grid>
                              </Typography>
                            </FormControl>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h2 className="h-3">メール設定</h2>
                <div>
                  <table className="form-table">
                    <tbody>
                      <tr>
                        <th>From</th>
                        <td>
                          <div className="form-input-group">
                            <TextField
                              value={formData.mail_from}
                              name="mail_from"
                              onChange={handleChange}
                              margin="normal"
                              className="form-input"
                              fullWidth
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>送信元名</th>
                        <td>
                          <div className="form-input-group">
                            <TextField
                              value={formData.mail_from_name}
                              name="mail_from_name"
                              onChange={handleChange}
                              margin="normal"
                              className="form-input"
                              fullWidth
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>署名</th>
                        <td>
                          <div className="form-input-group">
                            <ZshEditor
                              name="mail_footer"
                              value={formData.mail_footer}
                              onChange={handleChangeContent}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>停止回数</th>
                        <td>
                          <div className="form-input-group">
                            <TextField
                              value={formData.mail_stop_number}
                              name="mail_stop_number"
                              onChange={handleChange}
                              margin="normal"
                              className="form-input"
                              fullWidth
                              type="number"
                              inputProps={{
                                min: 1
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}