import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import axios from 'axios';
import Loading from '../../components/View/Loading';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZshTextField from '../../components/Form/ZshTextField';
import ActivityTypes from '../../components/View/ActivityTypes';
import ActivityStatuses from '../../components/View/ActivityStatuses';
import CustomerStatuses from '../../components/View/CustomerStatuses';
import InflowSourceTypes from '../../components/View/InflowSourceTypes';

export type SystemParamProps = {
  app_version: string;
  app_env: string;
  app_branch: string;
  api_key: string;
  api_secret: string;
  deploy_secret: string;
  cake_version: string;
}

export type SystemSettingProps = {
  ga_tracking_id: string;
  page_limits: string;
}

export default function SystemSettingsIndex() {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const unmounted = useRef<boolean>(false);
  const paramsDataSource = useRef(axios.CancelToken.source());
  const settingsDataSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSystemSettings, setLoadingSystemSettings] = useState<boolean>(false);
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [showSystemSettingsDialog, setShowSystemSettingsDialog] = useState<boolean>(false);
  const [systemParams, setSystemParams] = useState<SystemParamProps>({
    app_version: '',
    app_env: '',
    app_branch: '',
    api_key: '',
    api_secret: '',
    deploy_secret: '',
    cake_version: '',
  });
  const [systemSettings, setSystemSettings] = useState<SystemSettingProps>({
    ga_tracking_id: '',
    page_limits: '',
  });
  const [systemSettingsFormData, setSystemSettingsFormData] = useState<SystemSettingProps>({
    ga_tracking_id: '',
    page_limits: '',
  });

  const getSystemParams = useCallback(async () => {
    await axios.get(
      _usr_const.ApiUrl + 'system-settings/system-params.json',
      {
        cancelToken: paramsDataSource.current.token
      }
    )
      .then((results: any) => {
        if (!unmounted.current) {
          setSystemParams({
            ...results.data.params
          });
        }
      })
      .finally(() => {
        if (!unmounted.current) {
          setDataMounted(true);
          setLoading(false);
        }
        return Promise.resolve(1);
      });
  }, [paramsDataSource, unmounted]);

  const getSystemSettings = useCallback(async () => {
    setLoadingSystemSettings(true);
    await axios.get(
      _usr_const.ApiUrl + 'system-settings/system-settings.json',
      {
        cancelToken: settingsDataSource.current.token
      }
    )
      .then((results: any) => {
        if (!unmounted.current) {
          setSystemSettings({
            ...results.data.system_settings
          });
        }
      })
      .finally(() => {
        if (!unmounted.current) {
          setLoadingSystemSettings(false);
        }
        return Promise.resolve(1);
      });
  }, [settingsDataSource, unmounted]);

  const handleClickSystemSettingsEdit = (): void => {
    setSystemSettingsFormData({
      ...systemSettings
    });
    setShowSystemSettingsDialog(true);
  }

  const handleChangeSystemSettingsFormData = (event: any): void => {
    setSystemSettingsFormData({ ...systemSettingsFormData, ..._form.getFormEventNameValue(event) });
  }

  const systemSettingsFormFinished = (): void => {
    getSystemSettings();
    setOverlayProppress(false);
    setShowSystemSettingsDialog(false);
  }

  const handleSubmitSystemSettingsEdit = (): void => {
    _form.handleSubmit(
      {
        action: 'system-settings/system-settings',
        formData: systemSettingsFormData,
        callbackSuccess: systemSettingsFormFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      },
    );
  }

  // clean up
  useEffect(() => {
    const clDSource = Object.assign({}, paramsDataSource.current);
    return () => {
      // cancel axios get
      clDSource.cancel();
      unmounted.current = true;
    }
  }, []);

  // init action
  useEffect(() => {
    if (isInit) {
      getSystemParams();
      getSystemSettings();
      setInit(false);
    }
  }, [isInit, getSystemParams, getSystemSettings]);

  return (
    <div id="system-settings" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>Shift Version</th>
                    <td>{systemParams.app_version}</td>
                  </tr>
                  <tr>
                    <th>Cake Version</th>
                    <td>{systemParams.cake_version}</td>
                  </tr>
                  <tr>
                    <th>App Env</th>
                    <td>{systemParams.app_env}</td>
                  </tr>
                  <tr>
                    <th>Api Key</th>
                    <td>{systemParams.api_key}</td>
                  </tr>
                  <tr>
                    <th>Api Secret</th>
                    <td>{systemParams.api_secret}</td>
                  </tr>
                  <tr>
                    <th>App Branch</th>
                    <td>{systemParams.app_branch}</td>
                  </tr>
                  <tr>
                    <th>Deploy Secret</th>
                    <td>{systemParams.deploy_secret}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <Paper className="content-2">
            <h2 className="h-2">システム設定</h2>
            <div className="btn-group">
              <Button
                variant="contained"
                className="btn"
                color="primary"
                onClick={handleClickSystemSettingsEdit}
              >
                変更
              </Button>
            </div>
            <Loading loading={loadingSystemSettings} />
            <table className="table-1">
              <tbody>
                <tr>
                  <th>Analytics<br />Tracking ID</th>
                  <td>{systemSettings.ga_tracking_id}</td>
                </tr>
                <tr>
                  <th>表示件数</th>
                  <td>{systemSettings.page_limits}</td>
                </tr>
              </tbody>
            </table>
            <Dialog
              fullWidth={true}
              open={showSystemSettingsDialog}
              onClose={() => setShowSystemSettingsDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">システム設定変更</DialogTitle>
              <DialogContent>
                <form className="form-content dialog-form">
                  <div className="view-content-in form-content">
                    <div className="form-input-group">
                      <ZshTextField
                        value={systemSettingsFormData.ga_tracking_id}
                        name="ga_tracking_id"
                        handleChange={handleChangeSystemSettingsFormData}
                        label="Analytics Tracking ID"
                      />
                    </div>
                    <div className="form-input-group">
                      <ZshTextField
                        value={systemSettingsFormData.page_limits}
                        name="page_limits"
                        handleChange={handleChangeSystemSettingsFormData}
                        label="表示件数"
                      />
                    </div>
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowSystemSettingsDialog(false)}
                  color="default"
                >
                  閉じる
                </Button>
                <Button onClick={handleSubmitSystemSettingsEdit} color="primary" autoFocus>
                  保存
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
          <ActivityTypes />
          <ActivityStatuses />
          <CustomerStatuses />
          <InflowSourceTypes />
        </div>
      }
    </div>
  )
}