import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import { ValidateErrorsProps } from '../../types/state';
import { CustomerFormData, getStatuses } from './func/CustomersForm'
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import AddessInputs from '../../components/Form/AddressInputs';
import AjaxSelect from '../../components/Form/AjaxSelect';
import ZshTextField from '../../components/Form/ZshTextField';
import ErrorView from '../../components/View/ErrorView';
import { CustomerStatus } from '../../types/model';

export default function CustomersEdit({ history, match }: RouteComponentProps<{ id: string }>) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const actionName: string = 'customers/edit/';
  const backActionName: string = '/customers';

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());
  const dataSource = useRef(axios.CancelToken.source());

  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<CustomerFormData>({
    customer_status_id: '',
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    tel: '',
    email: '',
    zipcode_1: '',
    zipcode_2: '',
    prefecture: '',
    address_1: '',
    address_2: '',
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});
  const [statuses, setStatuses] = useState<CustomerStatus[] | undefined>(undefined);
  const [statusesError, setStatusesError] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const setValues = (values: { [key: string]: string }): void => {
    setFormData({
      ...formData,
      ...values
    });
  };

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'customers',
      id: match.params.id,
      method: 'put',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    const clDSource = Object.assign({}, dataSource.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      clDSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (dataMounted === false) {
      const initFunc = async () => {
        await getStatuses({
          unmounted: unmounted.current,
          setStatuses,
          setStatusesError,
          source
        });
        await ((): any => {
          const tepFormData = Object.assign({}, formData);
          axios
            .get(_usr_const.ApiUrl + actionName + match.params.id + '.json', {
              cancelToken: dataSource.current.token
            })
            .then((results: any) => {
              if (!unmounted.current) {
                let customer = results.data.customer;
                if (customer.customer_status_id === null) {
                  customer.customer_status_id = '';
                }
                const formData = _form.setFormData(tepFormData, customer);
                setFormData(formData);
              }
            })
            .catch((error: any) => {
              if (error.response) {
                setErrorStatus(error.response.status);
                setErrorMsg(error.response.data.message);
              }
            })
            .finally(() => {
              if (!unmounted.current) {
                setDataMounted(true);
                setLoading(false);
              }
              return Promise.resolve();
            });
        })();
      }
      initFunc();
    }
  }, [dataMounted, formData, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="customers" className="content-1">
      <form onSubmit={handleSubmit}>
        <Loading loading={loading} />
        {
          dataMounted && errorMsg === '' &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">ステータス</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="customer_status_id"
                            menuItems={statuses}
                            formData={formData}
                            handleChange={handleChange}
                            getError={statusesError}
                            validateErrors={validateErrors}
                            required
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">姓</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.last_name}
                            name="last_name"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.first_name}
                            name="first_name"
                            handleChange={handleChange}
                            required
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>姓 （カナ）</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.last_name_kana}
                            name="last_name_kana"
                            handleChange={handleChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>名 （カナ）</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.first_name_kana}
                            name="first_name_kana"
                            handleChange={handleChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>電話番号</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.tel}
                            name="tel"
                            handleChange={handleChange}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>メールアドレス</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.email}
                            name="email"
                            handleChange={handleChange}
                            fullWidth
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>住所</th>
                      <td>
                        <AddessInputs
                          formData={formData}
                          handleChange={handleChange}
                          setValues={setValues}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
        {
          errorMsg !== '' &&
          <div>
            <ErrorView
              history={history}
              errorStatus={errorStatus}
              errorMsg={errorMsg}
            />
          </div>
        }
      </form>
    </div>
  )
}