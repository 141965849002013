import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import * as _usersFormFunc from './func/UsersForm';
import { ValidateErrorsProps } from '../../types/state';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AjaxSelect from '../../components/Form/AjaxSelect';
import ZshTextField from '../../components/Form/ZshTextField';
import axios from 'axios';

export type UserFormDataProps = {
  username: string;
  password: string;
  authority: string;
  status: string;
  roles: any;
  personal_role: { [key: string]: any; };
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
}

export default function UsersAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const backActionName: string = '/users';

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<UserFormDataProps>({
    username: '',
    password: '',
    authority: '',
    status: '',
    roles: {
      _ids: []
    },
    personal_role: {},
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});
  const [roleValues, setRoleValues] = useState<{ [key: string]: boolean; }>({});
  const [roles, setRoles] = useState<{ [key: string]: string; }>({});
  const [roleNames, setRoleNames] = useState<{ [key: string]: string; }>({});
  const [roleActions, setRoleActions] = useState<{ [key: string]: string; }>({});
  const [personalRole, setPersonalRole] = useState<{ [key: string]: string; }>({});
  const [authorities, setAuthorities] = useState<{ [key: string]: string }>({});
  const [statuses, setStatuses] = useState<{ [key:string]: string }>({});
  const [statusesError, setStatusesError] = useState<boolean>(false);
  const [loadings, setLoadings] = useState({
    roles: true,
    roleNames: true,
    authorities: true,
    statuses: true
  });

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleRolesChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    let tmpRoleValues: any = Object.assign({}, roleValues);
    tmpRoleValues[event.target.value] = checked;
    setRoleValues(tmpRoleValues);
    _usersFormFunc.setRoleValuesToFormData({
      roleValues: tmpRoleValues,
      formData,
      setFormData
    });
  }

  const handlePersonalRoleChange = (event: any): void => {
    _usersFormFunc.handlePersonalRoleChange({
      formData,
      setFormData,
      event
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'users',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  const getPersonalRoleValue = (name: string, action: string): string => {
    let val: string = '';
    if (typeof formData.personal_role[name] !== 'undefined' && typeof formData.personal_role[name][action] !== 'undefined') {
      val = formData.personal_role[name][action];
    }
    return val;
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      // set params
      _usersFormFunc.getAddParams({
        unmounted,
        formData,
        setFormData,
        setLoadings,
        setRoles,
        setRoleNames,
        setRoleActions,
        setPersonalRole,
        setAuthorities,
        setStatuses,
        setRoleValues,
        setStatusesError,
        setLoading
      });
      setInit(false);
    }
  }, [isInit, formData]);

  useEffect(() => {
    if (statusesError) {
      alert('ユーザー区分が上位のため、編集できません');
    }
  }, [statusesError]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="users" className="content-1">
      <form onSubmit={handleSubmit} autoComplete="off">
        <Loading loading={loading} />
        {
          loading === false &&
          <div>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
            <Paper>
              <div className="view-content-in form-content">
                <table className="form-table">
                  <tbody>
                    <tr>
                      <th className="required-th">ユーザー名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.username}
                            name="username"
                            handleChange={handleChange}
                            fullWidth
                            required
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required-th">パスワード</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.password}
                            name="password"
                            handleChange={handleChange}
                            fullWidth
                            type="password"
                            required
                            validateErrors={validateErrors}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>ユーザー区分</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="authority"
                            menuItems={authorities}
                            loading={loadings.authorities}
                            formData={formData}
                            handleChange={handleChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>ステータス</th>
                      <td>
                        <div className="form-input-group">
                          <AjaxSelect
                            name="status"
                            menuItems={statuses}
                            loading={loadings.statuses}
                            formData={formData}
                            handleChange={handleChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>権限グループ</th>
                      <td>
                        <div className="form-input-group">
                          <FormControl>
                            <ul className="ul-1 roles-ul">
                              {
                                Object.keys(roles).map((val: string) => (
                                  <li key={'role-' + val}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                            handleRolesChange(event, checked)
                                          }}
                                          value={val}
                                          checked={roleValues[val] === undefined ? false : roleValues[val]}
                                          color="primary" />
                                      }
                                      label={roles[val]}
                                    />
                                  </li>
                                ))
                              }
                            </ul>
                          </FormControl>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>権限設定</th>
                      <td>
                        <div className="form-input-group">
                          <FormControl>
                            {
                              Object.keys(roleNames).length > 0 &&
                              Object.keys(roleNames).map((name: string) => (
                                <div key={name} className="role-actions">
                                  <p>{roleNames[name]}</p>
                                  {
                                    Object.keys(roleActions).length > 0 &&
                                    Object.keys(roleActions).map((action: string) => (
                                      <FormControl key={name + action} className="zsh-select-root" style={{ marginBottom: '10px' }}>
                                        <InputLabel htmlFor={action}>{roleActions[action]}</InputLabel>
                                        <Select
                                          className="form-input zsh-select personal-role-select"
                                          value={getPersonalRoleValue(name, action)}
                                          onChange={(event: any) => { handlePersonalRoleChange(event) }}
                                          inputProps={{
                                            name: name + '-' + action,
                                          }}
                                        >
                                          {
                                            Object.keys(personalRole).length > 0 &&
                                            Object.keys(personalRole).map((pr: string) => (
                                              <MenuItem value={pr} key={name + action + pr}>{personalRole[pr]}</MenuItem>
                                            ))
                                          }
                                        </Select>
                                      </FormControl>
                                    ))
                                  }
                                </div>
                              ))
                            }
                          </FormControl>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>姓</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.last_name}
                            name="last_name"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>名</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.first_name}
                            name="first_name"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>姓（カナ）</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.last_name_kana}
                            name="last_name_kana"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>名（カナ）</th>
                      <td>
                        <div className="form-input-group">
                          <ZshTextField
                            value={formData.first_name_kana}
                            name="first_name_kana"
                            handleChange={handleChange}
                            validateErrors={validateErrors}
                            fullWidth
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <HeadButtonGroups
              history={history}
              btnActions={btnActions}
            />
          </div>
        }
      </form>
    </div>
  )
}