import React, { useState, useEffect } from 'react';
import * as _const from '../../config/constant';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

type AddressInputProps = {
  zipcode_1?: string;
  zipcode_2?: string;
  prefecture?: string;
  address_1?: string;
  address_2?: string;
  zipcodeLabel_1?: string;
  zipcodeLabel_2?: string;
  prefectureLabel?: string;
  addressLabel_1?: string;
  addressLabel_2?: string;
  formData: any;
  handleChange: any;
  setValues: (values: { [key: string]: any }) => void;
}

type AddressInputPropsState = {
  zipcode_1: string;
  zipcode_2: string;
  prefecture: string;
  address_1: string;
  address_2: string;
  zipcodeLabel_1: string;
  zipcodeLabel_2: string;
  prefectureLabel: string;
  addressLabel_1: string;
  addressLabel_2: string;
  prefectureOptions: any;
}

export default function AddressInputs({
  zipcode_1,
  zipcode_2,
  prefecture,
  address_1,
  address_2,
  zipcodeLabel_1,
  zipcodeLabel_2,
  prefectureLabel,
  addressLabel_1,
  addressLabel_2,
  formData,
  handleChange,
  setValues,
}: AddressInputProps) {

  const [isInit, setInit] = useState(true);
  const [state, setState] = useState<AddressInputPropsState>({
    zipcode_1: 'zipcode_1',
    zipcode_2: 'zipcode_2',
    prefecture: 'prefecture',
    address_1: 'address_1',
    address_2: 'address_2',
    zipcodeLabel_1: '郵便番号',
    zipcodeLabel_2: '郵便番号',
    prefectureLabel: '都道府県',
    addressLabel_1: '市区町村',
    addressLabel_2: '以降の住所',
    prefectureOptions: []
  });
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleSearchAddress = (): void => {
    setErrorMsg('');
    const apiUrl = 'https://madefor.github.io/postal-code-api/api/v1/';
    fetch(apiUrl + formData[state.zipcode_1] + '/' + formData[state.zipcode_2] + '.json')
      .then((response: any) => response.json())
      .then((responseJson: any) => {
        const values: { [key: string]: any } = {
          [state.prefecture]: responseJson.data[0].ja.prefecture,
          [state.address_1]: responseJson.data[0].ja.address1,
          [state.address_2]: responseJson.data[0].ja.address2,
        };
        setValues(values);
      })
      .catch((error: any) => {
        setErrorMsg('住所を取得できませんでした');
      });
  }

  useEffect(() => {
    if (isInit) {
      let tmpState: AddressInputPropsState = Object.assign({}, state);
      tmpState.prefectureOptions = _const.Prefectures;
      if (zipcode_1 !== undefined) {
        tmpState.zipcode_1 = zipcode_1;
      }
      if (zipcode_2 !== undefined) {
        tmpState.zipcode_2 = zipcode_2;
      }
      if (prefecture !== undefined) {
        tmpState.prefecture = prefecture;
      }
      if (address_1 !== undefined) {
        tmpState.address_1 = address_1;
      }
      if (address_2 !== undefined) {
        tmpState.address_2 = address_2;
      }
      if (zipcodeLabel_1 !== undefined) {
        tmpState.zipcodeLabel_1 = zipcodeLabel_1;
      }
      if (zipcodeLabel_2 !== undefined) {
        tmpState.zipcodeLabel_2 = zipcodeLabel_2;
      }
      if (prefectureLabel !== undefined) {
        tmpState.prefectureLabel = prefectureLabel;
      }
      if (addressLabel_1 !== undefined) {
        tmpState.addressLabel_1 = addressLabel_1;
      }
      if (addressLabel_2 !== undefined) {
        tmpState.addressLabel_2 = addressLabel_2;
      }
      setState(tmpState);
      setInit(false);
    }
  }, [
    isInit,
    addressLabel_1,
    addressLabel_2,
    address_1,
    address_2,
    prefecture,
    prefectureLabel,
    state,
    zipcodeLabel_1,
    zipcodeLabel_2,
    zipcode_1,
    zipcode_2
  ]);

  return (
    <div>
      {
        isInit === false &&
        <>
          <div className="form-input-group zipcode-input-group">
            <TextField
              label={state.zipcodeLabel_1}
              value={formData[state.zipcode_1]}
              name={state.zipcode_1}
              onChange={handleChange}
              margin="normal"
              className="form-input zipcode-input"
              inputProps={{
                maxLength: 3,
              }}
            />
            <span className="zipcode-hyphen">-</span>
            <TextField
              label={state.zipcodeLabel_2}
              value={formData[state.zipcode_2]}
              name={state.zipcode_2}
              onChange={handleChange}
              margin="normal"
              className="form-input zipcode-input"
              inputProps={{
                maxLength: 4,
              }}
            />
            <Button
              size="small"
              variant="outlined"
              className="zipcode-btn"
              onClick={handleSearchAddress}
            >住所検索</Button>
            {
              errorMsg !== '' &&
              <div className="error-message">
                {errorMsg}
              </div>
            }
          </div>
          <div className="form-input-group">
            <FormControl>
              <InputLabel htmlFor="prefecture">{state.prefectureLabel}</InputLabel>
              <Select
                value={formData[state.prefecture]}
                name={state.prefecture}
                onChange={handleChange}
                className="form-input prefecture"
                inputProps={{
                  name: state.prefecture,
                }}
              >
                <MenuItem value=""></MenuItem>
                {
                  state.prefectureOptions.map((pre: string, index: number) => (
                    <MenuItem value={pre} key={'prefecture-' + index}>{pre}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          <div className="form-input-group">
            <TextField
              label={state.addressLabel_1}
              value={formData[state.address_1]}
              name={state.address_1}
              onChange={handleChange}
              margin="normal"
              className="form-input"
              fullWidth
            />
          </div>
          <div className="form-input-group">
            <TextField
              label={state.addressLabel_2}
              value={formData[state.address_2]}
              name={state.address_2}
              onChange={handleChange}
              margin="normal"
              className="form-input"
              fullWidth
            />
          </div>
        </>
      }
    </div>
  )
}