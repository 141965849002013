import React, { useState, useEffect, useCallback, useRef } from 'react';
import '../../scss/Calendar.scss';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SyncIcon from '@material-ui/icons/Sync';

export type ZshCalendarEventProps = {
  subject: string;
  start_date: string;
  end_date: string;
  link?: string;
  background_color?: string;
  color?: string;
}

export type ZshCalendarEventDataProps = {
  guid: string;
  subject: string;
  link: string;
  between: boolean;
  colors: {
    backgroundColor: string;
    color: string;
  };
  start_date: string;
  end_date: string;
  btwClass: string;
  eventClass: string;
  zindex: number;
  sZindex: number;
}

export type ZshCalendarCallbackEventProps = {
  subject: string;
  start_date: string;
  end_date: string;
  link?: string;
  colors: {
    backgroundColor: string;
    color: string;
  };
  between: boolean;
}

type ZshCalendarHeaderProps = {
  display: string;
  holiday: boolean;
}

type ZshCalendarDataProps = {
  date: string;
  day: number | string;
  dw: number;
  holiday: boolean;
}

type ZshCalendarRowEventParamsProps = {
  display: number;
  limit: number;
}

type ZshCalendarProps = {
  events?: ZshCalendarEventProps[];
  firstDay?: 0 | 1;
  defaultDay?: Date;
  onClickDay?: (date: Date) => void;
  onChangeMonth?: (date: Date) => void;
  onClickEvent?: (event: ZshCalendarCallbackEventProps) => void;
  onReload?: (date: Date) => void;
}

export default function ZshCalendar({
  events,
  firstDay = 1,
  defaultDay = new Date(),
  onClickDay,
  onChangeMonth,
  onClickEvent,
  onReload,
}: ZshCalendarProps) {

  const today: Date = new Date();
  const defaultBackGroundColor: string = '#c6dafc';
  const defaultColor: string = 'rgba(32,33,36,0.38)';

  const refEvents = useRef(events);
  const refOnChangeMonth = useRef(onChangeMonth);

  const [todayString, setTodayString] = useState<string | undefined>(undefined);
  const [activeDate, setActiveDate] = useState<Date>(defaultDay);
  const [data, setData] = useState<ZshCalendarDataProps[][]>([]);
  const [eventData, setEventData] = useState<{ [key: string]: string[] }>({});
  const [eventDataList, setEventDataList] = useState<{ [key: string]: ZshCalendarEventDataProps }>({});
  const [monthDisplay, setMonthDisplay] = useState<string>('');
  const [cells, setCells] = useState<(number | "")[][]>([]);
  const [cellHeaders, setCellHeaders] = useState<ZshCalendarHeaderProps[]>([]);
  const [rowEnetns, setRowEvents] = useState<ZshCalendarEventDataProps[][][]>([]);
  const [rowEventParams, setRowEventParams] = useState<ZshCalendarRowEventParamsProps[][]>([]);
  const [moreEvents, setMoreEvents] = useState<ZshCalendarEventDataProps[]>([]);
  const [openMoreEvents, setOpenMoreEvents] = useState<boolean>(false);

  const guid = (): string => {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  const formatCells = useCallback(() => {
    const range = (min: number, max: number): number[] => {
      return Array(max - min + 1).join().split(',').map(function (e, i) {
        return min + i;
      });
    }
    const calChank = (array: any): any[] => {
      return [].concat.apply([], array.map((e: any, i: number) => {
        return i % 7 ? [] : [array.slice(i, i + 7)];
      }));
    }
    const cellDate: Date = new Date(activeDate.getFullYear(), activeDate.getMonth(), 1);
    const cellEndDate: number = new Date(cellDate.getFullYear(), cellDate.getMonth() + 1, 0).getDate();
    const before: number = cellDate.getDay() - firstDay;
    const after: number = (7 - (before + cellEndDate) % 7) % 7;
    const calCel: (number | "")[] = range(1 - before, cellEndDate + after)
      .map(function (e) { return e < 1 || e > cellEndDate ? '' : e; });
    setCells(calChank(calCel));
  }, [activeDate, firstDay]);

  const formatCellHeaders = useCallback(() => {
    let headers: ZshCalendarHeaderProps[] = [];
    let days: string[] = ['日', '月', '火', '水', '木', '金', '土'];
    if (firstDay === 1) {
      days = ['月', '火', '水', '木', '金', '土', '日'];
    }
    for (let index = 0; index < days.length; index++) {
      let isHoliday: boolean = false;
      if (days[index] === '土' || days[index] === '日') {
        isHoliday = true;
      }
      headers.push({
        display: days[index],
        holiday: isHoliday
      });
    }
    setCellHeaders(headers);
  }, [firstDay]);

  const formatCalendarEvent = useCallback(() => {
    let tmpEventData: { [key: string]: string[] } = {};
    let tmpEventDataList: { [key: string]: ZshCalendarEventDataProps } = {};
    if (refEvents.current !== undefined && refEvents.current.length > 0) {
      refEvents.current.forEach((event: ZshCalendarEventProps) => {
        if (typeof event.start_date !== 'undefined') {
          let thisEvData: ZshCalendarEventDataProps = {
            guid: guid(),
            subject: event.subject,
            link: '',
            between: false,
            colors: {
              backgroundColor: defaultBackGroundColor,
              color: defaultColor
            },
            start_date: event.start_date,
            end_date: event.end_date,
            btwClass: '',
            eventClass: '',
            zindex: 0,
            sZindex: 0,
          };

          if (event.link !== undefined) {
            thisEvData.link = event.link;
          }
          if (event.background_color !== undefined && event.background_color !== '') {
            thisEvData.colors.backgroundColor = event.background_color;
          }
          if (event.color !== undefined && event.color !== '') {
            thisEvData.colors.color = event.color;
          }

          if (typeof tmpEventData[thisEvData.start_date] === 'undefined') {
            tmpEventData[thisEvData.start_date] = [];
          }

          const startDate: Date = new Date(event.start_date);

          if (typeof event.end_date !== 'undefined') {
            if (event.start_date !== event.end_date) {
              const endDate: Date = new Date(event.end_date);
              if (endDate.getTime() > startDate.getTime()) {
                thisEvData.between = true;
              }
            }
          }

          tmpEventDataList[thisEvData.guid] = thisEvData;
          tmpEventData[thisEvData.start_date].push(thisEvData.guid);

          // set between date
          if (thisEvData.between === true) {
            const btEndDate: Date = new Date(event.end_date);
            startDate.setDate(startDate.getDate() + 1);
            let btw_fl: boolean = true;
            while (btw_fl) {
              var btwDateM = ('0' + (startDate.getMonth() + 1)).slice(-2);
              var btwDateD = ('0' + startDate.getDate()).slice(-2);
              var btwDate = startDate.getFullYear() + '-' + btwDateM + '-' + btwDateD;
              if (typeof tmpEventData[btwDate] === 'undefined') {
                tmpEventData[btwDate] = [];
              }
              tmpEventData[btwDate].push(thisEvData.guid);
              startDate.setDate(startDate.getDate() + 1);
              if (btEndDate.getTime() < startDate.getTime()) {
                btw_fl = false;
              }
            }
          }
        }
      });
    }
    setEventData(tmpEventData);
    setEventDataList(tmpEventDataList);
  }, [refEvents]);

  const formatCalendarData = useCallback(() => {

    let tmpData: ZshCalendarDataProps[][] = [];
    let tmpRowEnetns: ZshCalendarEventDataProps[][][] = [];
    let tmpRowEventParams: ZshCalendarRowEventParamsProps[][] = [];

    cells.forEach((cell: (number | "")[], index: number) => {

      tmpData[index] = [];
      tmpRowEnetns[index] = [];
      tmpRowEventParams[index] = [];

      cell.forEach((cellData: number | "", e: number) => {

        tmpRowEnetns[index][e] = [];

        let thisData: ZshCalendarDataProps = {
          date: '',
          day: cellData,
          dw: 0,
          holiday: false
        };

        if (cellData !== '') {

          const thisCellDate = new Date(
            activeDate.getFullYear(),
            activeDate.getMonth(),
            cellData
          );

          const thisMonth: string = ('0' + (thisCellDate.getMonth() + 1)).slice(-2);
          const thisDay: string = ('0' + thisCellDate.getDate()).slice(-2);
          thisData.date = `${thisCellDate.getFullYear()}-${thisMonth}-${thisDay}`
          thisData.dw = thisCellDate.getDay();

          const thisDateObj: Date = new Date(thisData.date);

          // set last week holiday
          if (thisData.dw === 6 || thisData.dw === 0) {
            thisData.holiday = true;
          }

          // set events
          if (typeof eventData[thisData.date] !== 'undefined') {
            let zindex: number = 0;
            eventData[thisData.date].forEach((ev: string) => {
              if (typeof eventDataList[ev] !== 'undefined') {
                let eventObj: ZshCalendarEventDataProps = Object.assign({}, eventDataList[ev]);
                let eventObjStartDate: Date = new Date(eventObj.start_date);
                const eventObjEndDate: Date = new Date(eventObj.end_date);
                // format start date
                if (eventObjStartDate.getTime() < thisDateObj.getTime()) {
                  eventObj.start_date = thisData.date;
                }
                // set day between
                eventObjStartDate = new Date(eventObj.start_date);
                let btwClass: string = 'zsh-cal-btw-0';
                if (eventObj.start_date !== eventObj.end_date) {
                  let termDay: number = Math.floor((eventObjEndDate.getTime() - eventObjStartDate.getTime()) / 86400000);
                  if (thisData.dw === 0) {
                    if (firstDay === 1 && termDay >= 1) {
                      btwClass = 'zsh-cal-btw-1';
                    } else {
                      if (termDay >= 7) {
                        btwClass = 'zsh-cal-btw-7';
                      } else {
                        btwClass = `zsh-cal-btw-${termDay + 1}`;
                      }
                    }
                  } else {
                    if ((termDay + thisData.dw) >= 7) {
                      termDay = (7 - thisData.dw) + firstDay;
                      btwClass = `zsh-cal-btw-${termDay}`;
                    } else {
                      btwClass = `zsh-cal-btw-${termDay + 1}`;
                    }
                  }
                }
                eventObj.btwClass = btwClass;
                eventObj.eventClass = 'zsh-cal-ev-' + e;
                eventObj.zindex = zindex;
                tmpRowEnetns[index][e].push(eventObj);
                zindex++;
              }
            });
          }
        }
        tmpData[index].push(thisData);
      });
    });

    // sort event
    if (tmpRowEnetns.length > 0) {
      tmpRowEnetns.forEach((rowEvents: ZshCalendarEventDataProps[][]) => {
        rowEvents.forEach((rowEvent: ZshCalendarEventDataProps[]) => {
          if (rowEvent.length > 0) {
            rowEvent.sort((a: ZshCalendarEventDataProps, b: ZshCalendarEventDataProps) => {
              if (a.between > b.between) return -1;
              if (a.between < b.between) return 1;
              if (a.zindex < b.zindex) return -1;
              if (a.zindex > b.zindex) return 1;
              return 0;
            });
          }
        });
      });
    }

    // set event style zindex
    if (tmpRowEnetns.length > 0) {
      tmpRowEnetns.forEach((rowEvents: ZshCalendarEventDataProps[][]) => {
        let tmpZindexAr: { [key: string]: number } = {};
        rowEvents.forEach((rowEvent: ZshCalendarEventDataProps[]) => {
          if (rowEvent.length > 0) {
            let cnt: number = 0;
            rowEvent.forEach((ev: ZshCalendarEventDataProps) => {
              let cntE: number = 0;
              if (tmpZindexAr[ev.guid] === undefined) {
                tmpZindexAr[ev.guid] = cnt;
                ev.sZindex = cnt;
              } else {
                if (rowEvent.length < tmpZindexAr[ev.guid]) {
                  cntE = cnt;
                } else {
                  cntE = tmpZindexAr[ev.guid];
                }
                ev.sZindex = cntE;
                cnt = cntE;
              }
              cnt++;
            });
          }
        });
      });
    }

    // set row events params
    if (tmpRowEnetns.length > 0) {
      tmpRowEnetns.forEach((rowEvents: ZshCalendarEventDataProps[][], i: number) => {
        rowEvents.forEach((rowEvent: ZshCalendarEventDataProps[]) => {
          let thisRowParams: ZshCalendarRowEventParamsProps = {
            limit: 3,
            display: 0
          }
          if (rowEvent.length > 0) {
            // set limit
            const thisSZindex: number = rowEvent[0].sZindex;
            if (thisSZindex <= 3) {
              thisRowParams.limit = thisRowParams.limit - thisSZindex;
            } else {
              thisRowParams.limit = 0;
            }
            // set display count
            thisRowParams.display = rowEvent.slice(0, thisRowParams.limit).length;
          }
          tmpRowEventParams[i].push(thisRowParams);
        });
      });
    }

    setRowEvents(tmpRowEnetns);
    setRowEventParams(tmpRowEventParams);
    setData(tmpData);
  }, [eventData, cells, activeDate, eventDataList, firstDay]);

  const handleChangeMonth = useCallback(() => {
    const month: string = ('0' + (activeDate.getMonth() + 1)).slice(-2);
    setMonthDisplay(`${activeDate.getFullYear()}年${month}月`);
    formatCells();
  }, [activeDate, setMonthDisplay, formatCells]);

  const handlePrevDate = (): void => {
    const prevDate: Date = new Date(activeDate);
    prevDate.setMonth(activeDate.getMonth() - 1);
    setActiveDate(prevDate);
  }

  const handleNextDate = (): void => {
    const nextDate: Date = new Date(activeDate);
    nextDate.setMonth(activeDate.getMonth() + 1);
    setActiveDate(nextDate);
  }

  const dataDayClasses = (dayData: ZshCalendarDataProps): string => {
    let classAr: string[] = [
      'zsh-cal-conCe',
      'zsh-cal-cliCel',
    ]
    if (dayData.holiday) {
      classAr.push('zsh-cal-holiday');
    }
    if (dayData.date === todayString) {
      classAr.push('zsh-cal-today');
    }
    classAr.push(`zsh-cal-dw-${dayData.dw}`);
    return classAr.join(' ');
  }

  const handleClickDay = (cleckDate: string): void => {
    if (onClickDay !== undefined) {
      try {
        const date: Date = new Date(cleckDate);
        onClickDay(date);
      } catch (error) {
      }
    }
  }

  const handleToday = (): void => {
    setActiveDate(today);
  }

  const handleClickEvent = (event: ZshCalendarEventDataProps): void => {
    if (eventDataList[event.guid] !== undefined) {
      const thisEv: ZshCalendarEventDataProps = eventDataList[event.guid];
      const callbackData: ZshCalendarCallbackEventProps = {
        subject: thisEv.subject,
        start_date: thisEv.start_date,
        end_date: thisEv.end_date,
        link: thisEv.link,
        colors: thisEv.colors,
        between: thisEv.between,
      }
      if (onClickEvent !== undefined) {
        onClickEvent(callbackData);
      }
    }
  }

  const handleClickMoreEvent = (calEv: ZshCalendarEventDataProps[]): void => {
    setMoreEvents(calEv);
    setOpenMoreEvents(true);
  }

  const handleClickReload = (): void => {
    if (onReload !== undefined) {
      onReload(activeDate);
    }
  }

  const renderEvents = (calEv: ZshCalendarEventDataProps[], rowIndex: number, calEvIndex: number): JSX.Element => {
    let thisEvents: ZshCalendarEventDataProps[] = calEv.slice();
    let moreEvent: boolean = false;
    if (thisEvents.length > rowEventParams[rowIndex][calEvIndex]['limit']) {
      thisEvents = thisEvents.slice(0, rowEventParams[rowIndex][calEvIndex]['limit']);
      moreEvent = true;
    }
    return (
      <div>
        {
          thisEvents.map((calEvd: ZshCalendarEventDataProps, calEvdIndex: number) => (
            <div
              key={`zsh-cal-data-event-${rowIndex}-${calEvIndex}-${calEvdIndex}`}
              className={`zsh-cal-ev ${calEvd.eventClass} ${calEvd.btwClass}`}
              style={{ top: `${calEvd.sZindex}em` }}
            >
              <span
                className="zsh-cal-evCon CCd"
                style={calEvd.colors}
                onClick={() => handleClickEvent(calEvd)}
              >
                {calEvd.subject}
              </span>
            </div>
          ))
        }
        {
          moreEvent &&
          <div className={`zsh-cal-ev-more  zsh-cal-ev-more-${calEvIndex}`}>
            <div
              className="zsh-cal-more-tg CCd"
              onClick={() => handleClickMoreEvent(calEv)}
            >
              他 {calEv.length - rowEventParams[rowIndex][calEvIndex]['display']} 件
            </div>
          </div>
        }
      </div>
    );
  }

  // init
  useEffect(() => {
    if (todayString === undefined) {
      const activeMonth: string = ('0' + (activeDate.getMonth() + 1)).slice(-2);
      const activeDay: string = ('0' + activeDate.getDate()).slice(-2);
      setTodayString(`${activeDate.getFullYear()}-${activeMonth}-${activeDay}`);
      formatCellHeaders();
    }
  }, [todayString, activeDate, formatCellHeaders, setTodayString]);

  // set event data
  useEffect(() => {
    if (refEvents.current !== undefined) {
      formatCalendarEvent();
    }
  }, [refEvents, formatCalendarEvent]);

  useEffect(() => {
    formatCalendarData();
  }, [eventData, formatCalendarData]);

  useEffect(() => {
    handleChangeMonth();
    if (refOnChangeMonth.current !== undefined) {
      refOnChangeMonth.current(activeDate);
    }
  }, [activeDate, handleChangeMonth]);

  return (
    <Paper className="zsh-cal">
      <div className="zsh-cal-date">{monthDisplay}</div>
      <div className="zsh-cal-nav oh">
        <div className="left">
          <Button
            variant="outlined"
            size="small"
            onClick={handleToday}
            className="zsh-cal-btn-today"
          >
            今日
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handlePrevDate}
            className="zsh-cal-btn-prev"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleNextDate}
            className="zsh-cal-btn-next"
          >
            &gt;
          </Button>
        </div>
        <div className="right">
          <Button
            variant="outlined"
            size="small"
            onClick={handleClickReload}
            className="zsh-cal-btn-reload"
          >
            <SyncIcon />
          </Button>
        </div>
      </div>
      <div className="zsh-cal-content">
        <div className="zsh-cal-conHead">
          {
            cellHeaders.map((header: ZshCalendarHeaderProps, index: number) => (
              <div
                key={`zsh-cal-header-${index}`}
                className={header.holiday ? 'zsh-cal-holiday' : ''}
              >
                {header.display}
              </div>
            ))
          }
        </div>
        <div className="zsh-cal-conBody">
          {
            data.map((rowData: ZshCalendarDataProps[], rowIndex: number) => (
              <div
                key={`zsh-cal-data-${rowIndex}`}
                className="zsh-cal-conRw"
              >
                <div className="zsh-cal-conCeWr">
                  {
                    rowData.map((dayData: ZshCalendarDataProps, dayIndex: number) => (
                      <div
                        key={`zsh-cal-data-day-${rowIndex}-${dayIndex}`}
                        className={dataDayClasses(dayData)}
                        onClick={() => handleClickDay(dayData.date)}
                        data-date={dayData.date}
                      >
                        <div className="calD-day">
                          <span>
                            {dayData.day}
                          </span>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className="zsh-cal-evWr zsh-cal-cliCel">
                  <div className="zsh-calEv-inner">
                    {
                      rowEnetns[rowIndex].map((calEv: ZshCalendarEventDataProps[], calEvIndex: number) => (
                        <div
                          key={`zsh-cal-data-event-${rowIndex}-${calEvIndex}`}
                          className="zsh-cal-evD"
                        >
                          {renderEvents(calEv, rowIndex, calEvIndex)}
                        </div>
                      ))
                    }
                    <div className="zsh-cal-day-inner">
                      {
                        rowData.map((dayData: ZshCalendarDataProps, dayIndex: number) => (
                          <div
                            key={`zsh-cal-day-inner-${rowIndex}-${dayIndex}`}
                            className="zsh-cal-day-hidden zsh-cal-cliCel"
                            onClick={() => handleClickDay(dayData.date)}
                          >
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Dialog
        open={openMoreEvents}
        onClose={() => {
          setOpenMoreEvents(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className="cal-click-moreEv-box cal-click-con CCd">
          <div className="cal-cb-wr CCd">
            {
              moreEvents.map((calMdEvd: ZshCalendarEventDataProps, calMdIndex: number) => (
                <div
                  key={`zsh-cal-more-events-${calMdIndex}`}
                  className="zsh-cal-evCon CCd"
                  style={calMdEvd.colors}
                  onClick={() => handleClickEvent(calMdEvd)}
                >
                  {calMdEvd.subject}
                </div>
              ))
            }
          </div>
        </DialogContent>
      </Dialog>
    </Paper>
  );
}