import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageError } from '../../atoms/FlashMessage';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as _const from '../../config/constant';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import OverlayProgress from '../../components/OverlayProgress';
import UsersPasswordReset from './UsersPasswordReset';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: '#eaeff1',
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    forgotPassword: {
      textAlign: 'center',
      paddingTop: '15px',
    },
  })
);

function UsersLogin({ history, location }: RouteComponentProps) {

  const classes = useStyles();

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const [passwordReset, setPasswordReset] = useState<boolean>(false);

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleEditPassword = (): void => {
    history.push('/users/password-reset');
    setPasswordReset(true)
  }

  const postUrl: string = _usr_const.ApiUrl + 'users/login';

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setOverlayProppress(true);
    window.scrollTo(0, 0);
    axios
      .post(
        postUrl,
        formData
      )
      .then((response: any) => {
        localStorage.setItem('shift_token', response.data.token);
        window.location.href = _const.loginRedirect;
      })
      .catch((error) => {
        console.log(error);
        let errorMessage: string[] = [];
        if (error.response) {
          // set error message
          if (error.response.data.message !== undefined) {
            errorMessage.push(error.response.data.message);
          }
        }
        // set flash message
        if (errorMessage.length === 0) {
          errorMessage.push('エラーが発生しました');
          if (error.message !== undefined) {
            errorMessage.push(error.message);
          }
          if (error.response) {
            errorMessage.push('response data: ' + error.response.data);
            errorMessage.push('response status: ' + error.response.status);
            errorMessage.push('response headers: ' + error.response.headers);
          }
        }
        setFlashMessageError(errorMessage.join('¥n'));
      })
      .finally(() => {
        setOverlayProppress(false);
      });
  }

  useEffect(() => {
    if (location.pathname.indexOf('/users/password-reset') !== -1 && passwordReset === false) {
      setPasswordReset(true);
    }
  }, [location, history, passwordReset, setPasswordReset]);

  return (
    <div id="users-login">
      <OverlayProgress />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {
            passwordReset &&
            <div>
              <UsersPasswordReset
                setPasswordReset={setPasswordReset}
              />
            </div>
          }
          {
            passwordReset === false &&
            <div>
              <Typography component="h1" variant="h5" className="login-h">
                Shift
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="username"
                  autoComplete="email"
                  autoFocus
                  value={formData.username}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  サインイン
              </Button>
                <Grid container>
                  <Grid item xs className={classes.forgotPassword}>
                    <Link variant="body2" onClick={handleEditPassword} className="password-reset-a">パスワードを忘れた方はこちら</Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          }
        </div>
      </Container>
    </div>
  )
}

export default withRouter(UsersLogin);