import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageError, flashMessageSuccess } from '../../atoms/FlashMessage';
import * as _usr_const from '../../config/usr-constant';
import * as _form from '../../helper/form';
import * as _debug from '../../helper/debug';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import axios from 'axios'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZshTextField from '../../components/Form/ZshTextField';

type AuthInformationsProps = {
  id: number | undefined;
  authority_display: string;
  full_name: string;
};

type AuthInformationEditPasswordProps = {
  new_password: string;
  new_password_confirm: string;
};

export default function HeaderAuthInformations() {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const authInformationsSelector: any = useSelector(state => state.AuthInformations);

  const [isInit, setInit] = useState(true);
  const [authInformations, setAuthInformations] = useState<AuthInformationsProps | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [openEditPassword, setOpenEditPassword] = useState<boolean>(false);
  const [editPasswordData, setEditPasswordData] = useState<AuthInformationEditPasswordProps>({
    new_password: '',
    new_password_confirm: ''
  });
  const [editPasswordErrors, setEditPasswordErrors] = useState<{[key: string]: string[]}>({
    new_password: [],
    new_password_confirm: []
  });
  const [moreClassName, setMoreClassName] = useState<'showMore' | 'hideMore'>('hideMore');
  const [fullName, setFullName] = useState<string>('');
  const [authorityDisplay, setAuthorityDisplay] = useState<string>('');

  const handleClickToggle = (): void => {
    setShowMore(showMore ? false : true);
  }

  const handleHeaderAuthMoreClose = useCallback((element: any) => {
    const headerElements = [
      'header-auth-informations-display',
      'header-auth-informations-more-ul',
      'Hai-showMore',
      'Hai-showMore-icon-more',
    ];
    let checkId: string = '';
    if (element.srcElement.parentElement !== null) {
      checkId = element.srcElement.parentElement.id;
    } else {
      checkId = element.srcElement.id;
    }
    if (headerElements.indexOf(checkId)=== -1) {
      setShowMore(false); 
    }
  }, []);

  const handleLogout = (): void => {
    setOverlayProppress(true);
    axios
      .get(
        _usr_const.ApiUrl + 'users/logout'
      )
      .then((response: any) => {
        localStorage.removeItem('shift_token');
        window.location.href = '/users/login';
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        setOverlayProppress(false);
        setFlashMessageError('エラーが発生しました');
      });
  }

  const handleCloseEditPassword = (): void => {
    setOpenEditPassword(false);
    setEditPasswordData({
      new_password: '',
      new_password_confirm: ''
    });
    setEditPasswordErrors({
      new_password: [],
      new_password_confirm: []
    });
  }

  const handleChangeEditPassword = (event: any): void => {
    const inputName = event.target.name;
    setEditPasswordData({ ...editPasswordData, [inputName]: event.target.value });
  }

  const handleSubmitEditPassword = (): void => {
    let errors: {[key: string]: string[]} = {
      new_password: [],
      new_password_confirm: []
    };
    // check local
    if (editPasswordData.new_password === '') {
      errors.new_password.push('パスワードを入力して下さい');
    }
    if (editPasswordData.new_password_confirm === '') {
      errors.new_password_confirm.push('パスワード（確認）を入力して下さい');
    } else {
      if (editPasswordData.new_password !== editPasswordData.new_password_confirm) {
        errors.new_password_confirm.push('パスワードを確認して下さい');
      }
    }
    // post
    if (errors.new_password.length === 0 && errors.new_password_confirm.length === 0 && authInformations !== undefined) {
      setOverlayProppress(true);    
      axios
      .post(
        _usr_const.ApiUrl + 'users/edit-password/' + authInformations.id,
        editPasswordData,
      )
      .then((response: any) => {
        setFlashMessageSuccess('パスワードを変更しました');
        handleCloseEditPassword();
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (error.response) {
          // validate error
          if (error.response.status === 400) {
            setEditPasswordErrors(_form.formatValidateError(error));
          }
        }
        setFlashMessageError('パスワードを変更出来ませんでした');
      })
      .finally(() => {
        setOverlayProppress(false);
      });
    } else {
      setEditPasswordErrors(errors);
    }
  }

  useEffect(() => {
    return () => {
      document.body.removeEventListener('click', handleHeaderAuthMoreClose);
    }
  }, [handleHeaderAuthMoreClose]);

  useEffect(() => {
    if (isInit) {
      document.body.addEventListener('click', handleHeaderAuthMoreClose);
      setInit(false);
    }
  }, [isInit, handleHeaderAuthMoreClose]);

  // set auth informaitons
  useEffect(() => {
    if (authInformations === undefined) {
      if (authInformationsSelector !== undefined) {
        authInformationsSelector.forEach((element: any) => {
          if (element.isLoded) {
            if (element.error === undefined && element.data.auth_informations !== undefined) {
              if (authInformations !== element.data.auth_informations) {
                setAuthInformations(element.data.auth_informations);
                setError(false);
              }
            } else {
              if (typeof element.error !== 'undefined') {
                console.log(element.error); 
              }
              if (error === false) {
                if (typeof element.error !== 'undefined' && typeof element.error.message !== 'undefined') {
                  setFlashMessageError(element.error.message);
                }
                setError(true);
              }
            }
          }
        });
      } 
    }
  },
    [
      authInformationsSelector,
      authInformations,
      error,
      setFlashMessageError
    ]
  );

  // show more class
  useEffect(() => {
    setMoreClassName(showMore ? 'showMore' : 'hideMore');
  }, [showMore]);

  useEffect(() => {
    if (authInformations !== undefined) {
      if (authInformations.full_name !== undefined) {
        setFullName(authInformations.full_name);
      }
      if (authInformations.authority_display !== undefined) {
        setAuthorityDisplay(authInformations.authority_display);
      }
    }
  }, [authInformations]);

  return (
    <div id="header-auth-informations">
      {
        error &&
        <div>
          Connect Error
        </div>
      }
      {
        error === false &&
        <div id="header-auth-informations-in">
          <div id="header-auth-informations-display" onClick={handleClickToggle}>
            <span id="Hai-authority-display">{authorityDisplay}</span>
            <span id="Hai-fullname">{fullName}</span>
            <span id="Hai-showMore">
              {
                showMore &&
                <ExpandLess id="Hai-showMore-icon-less" />
              }
              {
                showMore === false &&
                <ExpandMore id="Hai-showMore-icon-more" />
              }
            </span>
          </div>
          <div id="header-auth-informations-more" className={moreClassName}>
            <ul id="header-auth-informations-more-ul">
              <li><span onClick={() => { setOpenEditPassword(true) }}>パスワード変更</span></li>
              <li>
                <span onClick={handleLogout}>ログアウト</span>
              </li>
            </ul>
          </div>
        </div>
      }
      <Dialog
        open={openEditPassword}
        onClose={handleCloseEditPassword}
      >
        <DialogTitle>パスワード変更</DialogTitle>
        <DialogContent className="header-auth-edit-password">
          <div className="form-content">
            <div className="form-input-group">
              <ZshTextField
                margin="dense"
                name="new_password"
                value={editPasswordData.new_password}
                label="パスワード"
                type="password"
                fullWidth
                handleChange={handleChangeEditPassword}
                validateErrors={editPasswordErrors}
              />
            </div>
            <div className="form-input-group">
              <ZshTextField
                margin="dense"
                name="new_password_confirm"
                value={editPasswordData.new_password_confirm}
                label="パスワード（確認）"
                type="password"
                fullWidth
                handleChange={handleChangeEditPassword}
                validateErrors={editPasswordErrors}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditPassword} color="default">閉じる</Button>
          <Button onClick={handleSubmitEditPassword} color="primary" autoFocus>変更</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}