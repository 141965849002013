import * as _usr_const from '../../../config/usr-constant';
import * as _debug from '../../../helper/debug';
import { CustomerStatus } from '../../../types/model';
import axios from 'axios';

export type CustomerFormData = {
  customer_status_id: string;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  tel: string;
  email: string;
  zipcode_1: string;
  zipcode_2: string;
  prefecture: string;
  address_1: string;
  address_2: string;
}

type getStatusesProps = {
  unmounted: boolean,
  setStatuses: React.Dispatch<React.SetStateAction<CustomerStatus[] | undefined>>,
  setStatusesError: React.Dispatch<React.SetStateAction<boolean>>,
  source: any
}
export async function getStatuses({
  unmounted,
  setStatuses,
  setStatusesError,
  source
}: getStatusesProps) {
  // get data
  await axios
  .get(
    _usr_const.ApiUrl + 'customer-statuses/get-customer-statuses.json', {
      cancelToken: source.current.token
    }
  )
  .then((response) => {    
    if (typeof response.data !== 'undefined') {
        let statuses: any = {};
        for (const customerStatus of response.data.customer_statuses) {
          statuses[customerStatus['id']] = customerStatus['name'];
        }
        if (!unmounted) {
          setStatuses(statuses);
        }
    }
  })
  .catch((error) => {
    _debug.debugAxiosError(error);
    if (!unmounted) { 
      setStatuses([]);
      setStatusesError(true);
    }
  })
  .finally(() => {
    return Promise.resolve(1);
  });
}