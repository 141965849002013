import React, { useState, useRef, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _form from '../../helper/form';
import * as _data_actions from '../../helper/dataActions';
import * as _debug from '../../helper/debug';
import { ValidateErrorsProps } from '../../types/state';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ZshTooltip from '../../components/View/ZshTooltip';
import DataTable, {
  DataTableRowsProps,
  DataTableActionsProps,
  DataTableFuncTbodyRowProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps } from '../../components/View/DataTableFilters';
import AjaxSelect from '../../components/Form/AjaxSelect';
import ZshTextField from '../../components/Form/ZshTextField';
import ZshDateTimePicker from '../../components/Form/ZshDateTimePicker';
import { Inquiry } from '../../types/model';

type InquiriesProps = {
  objectCommonParentId: number;
  customerId: number;
  activityTypeId?: number;
  findType: 'customer';
  title?: string;
}

type InquirieFormDataProps = {
  id: string;
  object_common_parent_id: string;
  customer_id: string,
  activity_type_id: string,
  activity_status_id: string,
  content: string,
  inquiry_datetime: string | null,
  pickers: {
    inquiry_datetime: Date | null,
  },
}

export default function Inquiries({
  objectCommonParentId,
  customerId,
  activityTypeId,
  findType = 'customer',
  title = 'すべての問合せ'
}: InquiriesProps) {

  const defaultData: Inquiry = {
    id: 0,
    object_common_parent_id: 0,
    customer_id: 0,
    activity_type_id: 0,
    activity_status_id: 0,
    inquiry_datetime: '',
    content: '',
    model_display: '',
    object_view_url: '',
    activity_status: {
      color: '',
      name: '',
    },
    registration_information_type: '',
    registration_information_source: '',
  };

  const defaultFormData: InquirieFormDataProps = {
    id: '',
    object_common_parent_id: '',
    customer_id: '',
    activity_type_id: '',
    activity_status_id: '',
    content: '',
    inquiry_datetime: null,
    pickers: {
      inquiry_datetime: null,
    },
  };

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const source = useRef(axios.CancelToken.source());
  const unmounted = useRef(false);

  const [selectedData, setSelectedData] = useState<Inquiry>(defaultData);
  const [modal, setModal] = useState<'view' | 'add' | 'edit' | 'hide'>('hide');
  const [formData, setFormData] = useState<InquirieFormDataProps>(defaultFormData);
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});
  const [activityStatuses, setActivityStatuses] = useState<any[]>([]);
  const [allActivityStatuses, setAllActivityStatuses] = useState<any[] | undefined>(undefined);
  const [activityStatusesMounted, setActivityStatusesMounted] = useState<boolean>(false);
  const [activityStatusesError, setActivityStatusesError] = useState<boolean>(false);
  const [dataTableCallbackParams, setDataTableCallbackParams] = useState({
    getData: () => { },
    query: {},
  });

  const handleChange = (event: any): void => {
    const inputName: string = event.target.name;
    const value: any = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [inputName]: value });
  };

  const handleClose = (): void => {
    setSelectedData(defaultData);
    setFormData(defaultFormData);
    setValidateErrors({});
    setModal('hide');
  }

  const handleView = (params: DataTableHandleClickCellProps<Inquiry>): void => {
    setDataTableCallbackParams(params);
    const selecttedData: any = Object.assign({}, params.record);
    setSelectedData({ ...selecttedData });
    setFormData({
      id: selecttedData.id,
      object_common_parent_id: selecttedData.object_common_parent_id,
      customer_id: selecttedData.customer_id,
      activity_type_id: selecttedData.activity_type_id,
      activity_status_id: selecttedData.activity_status_id,
      content: selecttedData.content,
      inquiry_datetime: selecttedData.inquiry_datetime,
      pickers: {
        inquiry_datetime: selecttedData.inquiry_datetime,
      },
    });
    setModal('view');
    // get activity statuses
    let activityStatusesPath: string = 'activity-statuses/get-activity-statuses.json'
    if (selecttedData.activity_type_id !== null) {
      activityStatusesPath = `activity-types/get-activity-status/${selecttedData.activity_type_id}.json`
    }
    axios.get(
      _usr_const.ApiUrl + activityStatusesPath,
      {
        cancelToken: source.current.token
      }
    )
      .then((results: any) => {
        if (results.data.activity_statuses !== undefined) {
          if (!unmounted.current) {
            let tmpActivityStatuses: any = {};
            results.data.activity_statuses.forEach((obj: any) => {
              tmpActivityStatuses[obj.id] = obj.name;
            });
            setActivityStatuses(tmpActivityStatuses);
            setActivityStatusesError(false);
          }
        }
      })
      .catch((error: any) => {
        _debug.debugAxiosError(error);
        if (!unmounted.current) {
          setActivityStatusesError(true);
        }
      })
      .finally(() => {
        if (!unmounted.current) {
          setActivityStatusesMounted(true);
        }
      });
  }

  const handleAdd = (params: any): void => {
    setDataTableCallbackParams(params);
    setFormData({
      ...formData,
      object_common_parent_id: objectCommonParentId.toString(),
      customer_id: customerId.toString(),
      pickers: {
        inquiry_datetime: null,
      }
    });
    setModal('add');
  }

  const handleEdit = (): void => {
    setModal('edit');
  }

  const handleDatePickerChange = (date: Date | null, name: string): void => {
    const tmpFormData: any = _form.handleDatePickerChange<InquirieFormDataProps>(formData, date, name);
    setFormData(tmpFormData);
  }

  const formFinished = (): void => {
    setOverlayProppress(false);
    dataTableCallbackParams.getData();
    handleClose();
  }

  const handleSubmit = (): void => {
    let postUrl: string = 'inquiries';
    if (modal === 'edit') {
      _form.handleSubmit(
        {
          action: postUrl,
          id: formData.id,
          method: 'put',
          formData,
          callbackSuccess: formFinished,
          setOverlayProppress,
          setFlashMessageSuccess,
          setFlashMessageError,
          setValidateErrors
        },
      );
    }
    if (modal === 'add') {
      _form.handleSubmit(
        {
          action: postUrl,
          formData,
          callbackSuccess: formFinished,
          setOverlayProppress,
          setFlashMessageSuccess,
          setFlashMessageError,
          setValidateErrors
        },
      );
    }
  }

  const setActivityStatusMenuItems = (): any[] => {
    if (modal === 'add') {
      if (typeof allActivityStatuses === 'undefined') {
        return [];
      } else {
        return allActivityStatuses;
      }
    }
    if (modal === 'edit') {
      if (activityStatusesMounted) {
        return activityStatuses;
      }
    }
    return [];
  }

  const handleDeletePost = (params: DataTableFuncTbodyRowProps<Inquiry>): void => {
    if (params.record !== undefined) {
      const selected: number[] = [params.record.id];
      _data_actions.deleteData({
        deleteUrl: 'inquiries/isdelete/',
        selected: selected,
        getData: params.getData,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      });
    } else {
      setFlashMessageError('削除対象を取得できませんでした');
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: DataTableFuncTbodyRowProps): void => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '削除してよろしいですか？¥nこの操作は元に戻せません。',
      closeText: 'キャンセル',
      agreeText: '削除する',
      funcAgree: () => {
        handleDeletePost(params);
      },
      setZshDialog
    });
  }

  const deleteNode = (params: DataTableFuncTbodyRowProps): JSX.Element => {
    return (
      <span>
        <IconButton aria-label="Delete" onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(event, params)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </span>
    )
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  let getAllActiviryStatusesPath: string = `activity-statuses/get-activity-statuses.json`;
  if (activityTypeId !== undefined) {
    getAllActiviryStatusesPath = `activity-types/get-activity-status/${activityTypeId}.json`;
  }

  // get allActivityStatuses 
  useEffect(() => {
    if (allActivityStatuses === undefined) {
      // get activity statuses
      axios.get(
        _usr_const.ApiUrl + getAllActiviryStatusesPath,
        {
          cancelToken: source.current.token
        }
      )
        .then((results: any) => {
          if (results.data.activity_statuses !== undefined) {
            if (!unmounted.current) {
              let tmpActivityStatuses: any = {};
              results.data.activity_statuses.forEach((obj: any) => {
                tmpActivityStatuses[obj.id] = obj.name;
              });
              setAllActivityStatuses(tmpActivityStatuses);
              setActivityStatusesError(false);
            }
          }
        })
        .catch((error: any) => {
          _debug.debugAxiosError(error);
          if (!unmounted.current) {
            setAllActivityStatuses([]);
            setActivityStatusesError(true);
          }
        });
    }
  }, [allActivityStatuses, activityTypeId, getAllActiviryStatusesPath]);

  const rows: DataTableRowsProps[] = [
    {
      label: '登録日時',
      value: 'created',
      filter: 'YMDHm',
      sortField: 'Inquiries.created',
      align: 'left',
    },
    {
      label: '種別',
      value: 'model_display',
      align: 'left'
    },
    {
      label: 'ステータス',
      value: 'activity_status_name',
      align: 'left',
    },
    {
      label: '問合せ',
      value: 'content',
      align: 'left',
      maxLength: 20
    },
    {
      label: '',
      align: 'right',
      node: deleteNode
    }
  ];

  let dataTableParams: DataTableFiltersProps[] = [];

  if (findType === 'customer') {
    dataTableParams.push({
      key: 'model',
      dataKey: 'activity_types',
      title: '種別',
      type: 'inlineSelect',
      getOptionUrl: 'activity-types/get_activity_types/add/index.json',
      optionValue: 'model',
      optionText: 'name',
    });
  }

  dataTableParams.push({
    key: 'status',
    dataKey: 'activity_statuses',
    title: 'ステータス',
    type: 'inlineSelect',
    getOptionUrl: getAllActiviryStatusesPath,
    optionValue: 'id',
    optionText: 'name',
  });

  const actions: DataTableActionsProps[] = [
    {
      title: '登録',
      func: handleAdd,
      color: 'primary',
    },
  ];

  return (
    <Paper className="content-2 inquiries">
      <h2 className="h-2">{title}</h2>
      {
        objectCommonParentId !== 0 &&
        <DataTable<Inquiry>
          rows={rows}
          checkbox={false}
          jsonPath={'inquiries/index/' + objectCommonParentId + '/index.json'}
          urlQuery={false}
          handleClickCell={handleView}
          limit={5}
          size="small"
          dataTableParams={dataTableParams}
          actions={actions}
          filterGroupsInline={true}
          defaultQueryValues={
            {
              find_type: findType,
              customer_id: customerId
            }
          }
        />
      }
      <Dialog
        fullWidth={true}
        open={modal === 'view' ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">問合せ詳細</DialogTitle>
        <DialogContent>
          <table className="table-1">
            <tbody>
              <tr>
                <th>種別</th>
                <td>
                  {selectedData.model_display}
                </td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td>
                  {
                    selectedData.activity_status.color !== null &&
                    <span style={{ color: selectedData.activity_status.color }}>
                      {selectedData.activity_status.name}
                    </span>
                  }
                  {
                    selectedData.activity_status.color === null &&
                    <span>
                      {selectedData.activity_status.name}
                    </span>
                  }
                </td>
              </tr>
              <tr>
                <th>日時</th>
                <td className="pre-line">
                  {_filter.ShFilter(selectedData.inquiry_datetime, 'YMDHm')}
                </td>
              </tr>
              <tr>
                <th>内容</th>
                <td className="pre-line">
                  {selectedData.content}
                </td>
              </tr>
              <tr>
                <th>
                  登録方法
                  <ZshTooltip text="このデータがどのようにして登録されたかの区分です。" />
                </th>
                <td>
                  {selectedData.registration_information_type}
                </td>
              </tr>
              <tr>
                <th>
                  データソース
                  <ZshTooltip text="このデータがどこから登録されたかの区分です。" />
                </th>
                <td>
                  {selectedData.registration_information_source}
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            閉じる
            </Button>
          <Button onClick={handleEdit} color="primary" autoFocus>
            編集
            </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={(() => {
          if (modal === 'edit' || modal === 'add') {
            return true;
          }
          return false;
        })()}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            modal === 'add' &&
            <span>問合せ登録</span>
          }
          {
            modal === 'edit' &&
            <span>問合せ編集</span>
          }
        </DialogTitle>
        <DialogContent>
          <form className="form-content dialog-form">
            <div className="view-content-in form-content">
              <div className="form-input-group">
                <AjaxSelect
                  name="activity_status_id"
                  label="ステータス"
                  menuItems={setActivityStatusMenuItems()}
                  formData={formData}
                  handleChange={handleChange}
                  validateErrors={validateErrors}
                  getError={activityStatusesError}
                  required
                />
              </div>
              <div className="form-input-group">
                <ZshDateTimePicker
                  label="日時"
                  name="inquiry_datetime"
                  value={formData.pickers.inquiry_datetime}
                  handleChange={(date: Date | null) => handleDatePickerChange(date, 'inquiry_datetime')}
                  format="YYYY/MM/DD HH:mm"
                  views={['date', 'hours']}
                  validateErrors={validateErrors}
                  required
                />
              </div>
              <div className="form-input-group">
                <ZshTextField
                  name="content"
                  label="内容"
                  value={formData.content}
                  handleChange={handleChange}
                  margin="normal"
                  className="form-input form-textarea"
                  multiline={true}
                  fullWidth
                  required
                  validateErrors={validateErrors}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            閉じる
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}