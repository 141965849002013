import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

type ZshRadioProps = {
  title?: string;
  name: string;
  value: any;
  handleChange: (event: any) => void;
  options: { [key: string]: string | number | boolean };
  required?: boolean;
  validateErrors?: { [key: string]: string[] };
}

export default function ZshRadio({
  title,
  name,
  value,
  handleChange,
  options,
  required = false,
  validateErrors
}: ZshRadioProps) {

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (validateErrors !== undefined && validateErrors[name] !== undefined) {
      setErrorMessages(validateErrors[name]);
    } else {
      setErrorMessages([]);
    }
  }, [validateErrors, name]);

  return (
    <FormControl
      component="fieldset"
      className="radio-form-control-1"
      required={required}
    >
      {
        title !== undefined &&
        <FormLabel component="legend" className="form-label-1">{title}</FormLabel>
      }
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {
          Object.keys(options).map((key: string, index: number) => (
            <FormControlLabel
              key={`radio-${name}-${index}`}
              id={`radio-${name}-${index}`}
              value={options[key]}
              control={<Radio />}
              label={key}
            />
          ))
        }
      </RadioGroup>
      {
        errorMessages.length > 0 &&
        <span className="zsh-radio-field-er-wr">
          {
            errorMessages.map((msg: string, index: number) => (
              <span key={name + '-erSeMsg-' + index} className="zsh-radio-field-er-msg">
                <FormHelperText>{msg}</FormHelperText><br />
              </span>
            ))
          }
        </span>
      }
    </FormControl>
  )
}