import React, { useState } from 'react';
import ZshCalendar, {
  ZshCalendarEventProps,
  ZshCalendarCallbackEventProps
} from '../../components/View/ZshCalendar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export default function CalendarsIndex() {

  let events: ZshCalendarEventProps[] = [];

  const evColors: { [key: string]: string }[] = [
    {
      background_color: 'rgb(234, 128, 128)',
      color: '#000'
    },
    {
      background_color: 'rgb(243, 190, 185)',
      color: ''
    },
    {
      background_color: 'rgb(250, 168, 143)',
      color: ''
    },
    {
      background_color: 'rgb(251, 223, 147)',
      color: ''
    },
    {
      background_color: 'rgb(153, 219, 188)',
      color: ''
    },
    {
      background_color: 'rgb(133, 192, 161)',
      color: ''
    },
    {
      background_color: 'rgb(129, 205, 242)',
      color: ''
    },
    {
      background_color: 'rgb(159, 168, 218)',
      color: ''
    },
    {
      background_color: 'rgb(188, 195, 229)',
      color: ''
    },
    {
      background_color: 'rgb(199, 146, 213)',
      color: ''
    },
    {
      background_color: 'rgb(176, 176, 176)',
      color: ''
    },
    {
      background_color: 'rgb(161, 194, 250)',
      color: ''
    },
  ];

  const now: Date = new Date();
  const ev_date: Date = new Date(now.getFullYear(), now.getMonth(), 1);

  for (let i = 0; i < 20; i++) {
    //set start date
    const start_date_m: string = ('0' + (ev_date.getMonth() + 1)).slice(-2);
    const start_date_d: string = ('0' + ev_date.getDate()).slice(-2);
    const start_date: string = ev_date.getFullYear() + '-' + start_date_m + '-' + start_date_d;

    //set end date
    let end_date: string = start_date;
    let end_date_m, end_date_d;
    if (i === 10) {
      end_date_m = ('0' + (ev_date.getMonth() + 1)).slice(-2);
      end_date_d = ('0' + (ev_date.getDate() + 5)).slice(-2);
      end_date = ev_date.getFullYear() + '-' + end_date_m + '-' + end_date_d;
    }
    if (i === 15) {
      end_date_m = ('0' + (ev_date.getMonth() + 1)).slice(-2);
      end_date_d = ('0' + (ev_date.getDate() + 8)).slice(-2);
      end_date = ev_date.getFullYear() + '-' + end_date_m + '-' + end_date_d;
    }
    if (i === 16 || i === 18) {
      end_date_m = ('0' + (ev_date.getMonth() + 1)).slice(-2);
      end_date_d = ('0' + (ev_date.getDate() + 3)).slice(-2);
      end_date = ev_date.getFullYear() + '-' + end_date_m + '-' + end_date_d;
    }
    //set data
    let this_ev: ZshCalendarEventProps = {
      subject: 'テスト' + (i + 1),
      start_date: start_date,
      end_date: end_date,
      link: './#' + i,
      background_color: '',
      color: '',
    };
    //set colors
    if (evColors[i] !== undefined) {
      if (evColors[i].background_color !== undefined) {
        this_ev.background_color = evColors[i].background_color;
      }
      if (evColors[i].color !== undefined) {
        this_ev.color = evColors[i].color;
      }
    }
    events.push(this_ev);
    if (i >= 5) {
      ev_date.setDate(ev_date.getDate() + 1);
    }
  }

  const [event, setEvent] = useState<ZshCalendarCallbackEventProps>({
    subject: '',
    link: '',
    colors: {
      backgroundColor: '',
      color: ''
    },
    start_date: '',
    end_date: '',
    between: false,
  });
  const [openEventDetail, setOpenEventDetail] = useState<boolean>(false);

  const handleClickDay = (date: Date): void => {
    console.log({
      func: 'handleClickDay',
      value: date
    });
  }

  const handleChangeMonth = (date: Date): void => {
    console.log({
      func: 'handleChangeMonth',
      value: date
    });
  }

  const handeleClickEvent = (event: ZshCalendarCallbackEventProps): void => {
    console.log({
      func: 'handeleClickEvent',
      value: event
    });
    setEvent(event);
    setOpenEventDetail(true);
  }

  const handleReload = (date: Date): void => {
    console.log({
      func: 'handleReload',
      value: date
    });
  }

  return (
    <div className="zsh-calendar">
      <ZshCalendar
        events={events}
        onClickDay={handleClickDay}
        onChangeMonth={handleChangeMonth}
        onClickEvent={handeleClickEvent}
        onReload={handleReload}
      />
      <Dialog
        open={openEventDetail}
        onClose={() => setOpenEventDetail(false)}
      >
        <DialogContent>
          <p>{event.subject}</p>
          {
            event.between &&
            <p>
              {event.start_date} ~ {event.end_date}
            </p>
          }
          {
            event.between === false &&
            <p>
              {event.start_date}
            </p>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
}