import React from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, {
  DataTableRowsProps,
  DataTableActionsProps,
  DataTableActionsFuncProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps } from '../../components/View/DataTableFilters';
import * as _data_actions from '../../helper/dataActions';
import { InflowSourceMaster } from '../../types/model';

export default function InflowSourceMastersIndex({ history, location }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const clickCell = (callbackParams: DataTableHandleClickCellProps<InflowSourceMaster>): void => {
    history.push(location.pathname + '/' + callbackParams.record.id);
  }

  const handleToAdd = (): void => {
    history.push('/inflow-source-masters/add');
  }

  const confirmDelete = (params: DataTableActionsFuncProps<InflowSourceMaster>): void => {
    if (params.selected.length > 0) {
      _data_actions.showConfirmDialog({
        dialogType: 'delete',
        funcAgree: () => {
          handleDelete(params);
        },
        setZshDialog
      });
    } else {
      setFlashMessageError('項目を選択してください');
    }
  }

  const handleDelete = (params: DataTableActionsFuncProps<InflowSourceMaster>): void => {
    _data_actions.deleteData({
      deleteUrl: 'inflow-source-masters/delete/',
      selected: params.selected,
      getData: params.getData,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError
    });
  }

  const rows: DataTableRowsProps<InflowSourceMaster>[] = [
    {
      label: 'ID',
      value: 'id',
      sortField: 'InflowSourceMasters.id',
      align: 'left',
    },
    {
      label: '流入元区分',
      value: 'inflow_source_type_name',
      align: 'left'
    },
    {
      label: '流入元名',
      value: 'name',
      sortField: 'InflowSourceMasters.name',
      align: 'left',
    },
    {
      label: '登録日時',
      value: 'created',
      filter: 'YMDHm',
      sortField: 'InflowSourceMasters.created',
      align: 'left',
    },
    {
      label: '更新日時',
      value: 'modified',
      filter: 'YMDHm',
      sortField: 'InflowSourceMasters.modified',
      align: 'left',
    },
  ];

  const dataTableParams: DataTableFiltersProps[] = [
    {
      key: 'inflow_source_type_id',
      dataKey: 'inflow_source_types',
      title: '流入元区分',
      type: 'inlineSelect',
      getOptionUrl: 'inflow-source-masters/get-inflow-source-types.json',
      optionValue: 'id',
      optionText: 'name',
    },
  ];

  const actions: DataTableActionsProps<InflowSourceMaster>[] = [
    {
      title: '新規追加',
      func: handleToAdd,
      color: 'primary',
      role: {
        name: 'InflowSourceMasters',
        action: 'add',
      },
    },
    {
      title: '削除',
      func: confirmDelete,
      color: 'default',
      role: {
        name: 'InflowSourceMasters',
        action: 'delete',
      },
    },
  ];

  return (
    <div id="inflow-source-masters" className="content-1">
      <DataTable<InflowSourceMaster>
        rows={rows}
        checkbox={true}
        jsonPath='inflow-source-masters.json'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        dataTableParams={dataTableParams}
        actions={actions}
      />
    </div>
  )
}