import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import * as _form from '../../helper/form';
import * as _usr_const from '../../config/usr-constant';
import * as _data_actions from '../../helper/dataActions';
import { ValidateErrorsProps } from '../../types/state';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import IconArrowDropUp from '@material-ui/icons/ArrowDropUp';
import IconArrowDropDown from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import ZshTextField from '../../components/Form/ZshTextField';
import AjaxSelect from '../../components/Form/AjaxSelect';
import DataTable, {
  DataTableActionsProps,
  DataTableRowsProps,
  DataTableFuncTbodyRowProps,
  DataTableActionsFuncProps
} from '../../components/View/DataTable';
import Axios from 'axios';

type ActivityStatusType = {
  id?: string;
  name: string;
  alert: string;
  is_default: boolean;
  is_end: boolean;
  color: string;
  background_color: string;
  activity_types: {
    _ids: number[]
  }
}

type ActivityStatusDataType = {
  id?: string;
  name: string;
  alert: string;
  is_default: boolean;
  is_end: boolean;
  color: string;
  background_color: string;
  level: number;
  activity_types: []
}

type ColorType = {
  name: string;
  color: string;
  background_color: string;
  border_color: string;
}

type SupportedTypesResultType = {
  name: string;
  types: {
    [key: number]: boolean;
  }
}

type SupportedTypesType = {
  types?: {
    [key: number]: string
  };
  results?: SupportedTypesResultType[];
}

const initialFormDara: ActivityStatusType = {
  name: '',
  alert: '',
  is_default: false,
  is_end: false,
  color: '',
  background_color: '',
  activity_types: {
    _ids: []
  }
}

const initialDara: ActivityStatusDataType = {
  name: '',
  alert: '',
  is_default: false,
  is_end: false,
  color: '',
  background_color: '',
  level: 0,
  activity_types: []
}

export default function ActivityStatuses() {

  const unmounted = useRef(false);
  const alertsSource = useRef(Axios.CancelToken.source());
  const activityTypesSource = useRef(Axios.CancelToken.source());
  const colorsSource = useRef(Axios.CancelToken.source());
  const supportedTypesSource = useRef(Axios.CancelToken.source());

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const [formData, setFormData] = useState<ActivityStatusType>(initialFormDara);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showViewDialog, setShowViewDialog] = useState<boolean>(false);
  const [showEditLevelDialog, setShowEditLevelDialog] = useState<boolean>(false);
  const [dataTableCallbackParams, setDataTableCallbackParams] = useState({
    getData: () => {},
    query: {},
  });
  const [data, setData] = useState<ActivityStatusDataType>(initialDara);
  const [editLevelData, setEditLevelData] = useState<{[key: number]: ActivityStatusDataType} | undefined>(undefined);
  const [formType, setFormType] = useState<'add' | 'edit'>('add');
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});
  const [alerts, setAlerts] = useState<{[key: string]: string} | undefined>(undefined);
  const [activityTypes, setActivityTypes] = useState<{[key: string]: string} | undefined >(undefined);
  const [selectedActivityType, setSelectedActivityType] = useState<number>(0);
  const [colors, setColors] = useState<{[key: string]: ColorType} | undefined>(undefined);
  const [supportedTypes, setSupportedTypes] = useState<SupportedTypesType | undefined>(undefined);

  const getActivityTypes = (): void => {
    Axios.get(
      _usr_const.ApiUrl + 'activity-types/get-activity-types-list.json',
      {
        cancelToken: activityTypesSource.current.token
      }
    )
    .then((results: any) => {
      if (!unmounted.current) {
        setActivityTypes(results.data.activity_types);
      }
    })
    .catch(() => {
      setActivityTypes({});
    });
  }

  const clickCell = (callbackParams: DataTableFuncTbodyRowProps) => {
    getActivityTypes();
    setDataTableCallbackParams(callbackParams);
    setData(callbackParams.record);
    setShowViewDialog(true);
  }

  const handleChangeFormData = (event: any): void  => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  }

  const handleChangeActivityTypeSelect = (event: any, child: React.ReactNode): void => {
    setSelectedActivityType(Number(event.target.value));
  }

  const handleAddSelectActivityType = (): void => {
    let tmpIds: number[] = formData.activity_types._ids;
    if (selectedActivityType !== 0 && tmpIds.indexOf(selectedActivityType) === -1) {
      tmpIds.push(selectedActivityType);
    }
    setFormData({
      ...formData,
      activity_types: {
        _ids: tmpIds
      }
    });
  }

  const handleDeleteSelectActivityType = (id: number): void => {
    let tmpIds: number[] = formData.activity_types._ids;
    tmpIds　= tmpIds.filter((tmpId: number) => {
      return id !== tmpId;
    });
    setFormData({
      ...formData,
      activity_types: {
        _ids: tmpIds
      }
    });
  }

  const handleClickColor = (color: ColorType): void => {
    setFormData({
      ...formData,
      color: color.color,
      background_color: color.background_color
    });
  }

  const handleClickAdd = (callbackParams: DataTableActionsFuncProps): void => {
    setDataTableCallbackParams(callbackParams);
    getActivityTypes();
    setFormType('add');
    setFormData({
      ...initialFormDara,
      activity_types: {
        _ids: []
      }
    });
    setValidateErrors({});
    setSelectedActivityType(0);
    setShowDialog(true);
  }

  const formFinished = (): void => {
    dataTableCallbackParams.getData();
    getSupportedTypes();
    setShowDialog(false);
    setShowViewDialog(false);
    setShowEditLevelDialog(false);
  }

  const handleAddSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'activity-statuses',
        formData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError,
        setValidateErrors
      },
    );
  }

  const handleEditSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'activity-statuses',
        formData,
        id: data.id,
        method: 'put',
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError,
        setValidateErrors
      },
    );
  }

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    let ids: number[] = [];
    if (data.activity_types.length > 0) {
      data.activity_types.forEach((activity_type: any) => {
        ids.push(Number(activity_type.id));
      })
    }
    event.stopPropagation();
    setShowViewDialog(false);
    setFormType('edit');
    setFormData({
      name: data.name,
      alert: data.alert,
      is_default: data.is_default,
      is_end: data.is_end,
      color: data.color,
      background_color: data.background_color,
      activity_types: {
        _ids: ids
      }
    });
    setValidateErrors({});
    setShowDialog(true);
  }

  const handleDeletePost = () => {
    if (data.id !== undefined) {
      const selected: number[] = [Number(data.id)];
      _data_actions.deleteData({
        deleteUrl: 'activity-statuses/isdelete/',
        selected: selected,
        getData: dataTableCallbackParams.getData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      });
    } else {
      setFlashMessageError('削除対象を取得できませんでした');
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '削除してよろしいですか？¥nこの操作は元に戻せません。',
      closeText: 'キャンセル',
      agreeText: '削除する',
      funcAgree: () => {
        handleDeletePost();
      },
      setZshDialog
    });
  }

  const handleClickEditLevel = (callbackParams: DataTableActionsFuncProps<ActivityStatusDataType>): void => {
    setDataTableCallbackParams(callbackParams);
    setShowDialog(false);
    setShowViewDialog(false);
    let tmp: {[key: number]: ActivityStatusDataType} = {};
    if (callbackParams.records.length > 0) {
      callbackParams.records.forEach((d: ActivityStatusDataType) => {
        tmp[d.level] = d;
      });
    }
    setEditLevelData(tmp);
    setShowEditLevelDialog(true);
  }

  const handleChangeLevel = (d: ActivityStatusDataType, type: 'up' | 'down'): void => {
    if (editLevelData !== undefined) {
      let tmpSetData: {[key: number]: ActivityStatusDataType} = {};
      let tmpData: {[key: number]: ActivityStatusDataType} = {};
      if (Object.keys(editLevelData).length > 0) {
        if (type === 'up') {
          if (d.level > 0) {
            const beforeLevel: number = d.level - 1;
            Object.values(editLevelData).forEach((ed: ActivityStatusDataType) => {
              let thisLevel: number = ed.level;
              if (ed.level === d.level) {
                thisLevel = d.level - 1;
              } else if (beforeLevel === ed.level) {
                thisLevel = d.level;
              }
              tmpData[thisLevel] = ed;
            }); 
          }
        }
        if (type === 'down') {
          const afterLevel: number = d.level + 1;
          Object.values(editLevelData).forEach((ed: ActivityStatusDataType) => {
            let thisLevel: number = ed.level;
            if (ed.level === d.level) {
              thisLevel = d.level + 1;
            } else if (afterLevel === ed.level) {
              thisLevel = d.level;
            }
            tmpData[thisLevel] = ed;
          });
        }
      }
      // reset level
      if (Object.keys(tmpData).length > 0) {
        Object.values(tmpData).forEach((tmd: ActivityStatusDataType, index: number) => {
          tmd.level = index;
          tmpSetData[index] = tmd;
        })
      }
      setEditLevelData(tmpSetData);
    }
  }

  const handleEditLevelSubmit = (): void => {
    if (typeof editLevelData === 'object' && Object.keys(editLevelData).length > 0) {
      let postData: {
        id: string;
        level: number
      }[] = [];
      Object.values(editLevelData).forEach((d: ActivityStatusDataType) => {
        if (d.id !== undefined) {
          postData.push({
            id: d.id,
            level: d.level
          });
        }
      });
      _form.handleSubmit(
        {
          action: 'settings/activity-statuses-edit-level',
          formData: {
            data: postData
          },
          callbackSuccess: formFinished,
          setOverlayProppress,
          setFlashMessageSuccess,
          setFlashMessageError,
          setValidateErrors
        },
      );
    } else {
      alert('対象データがありません');
    }
  }

  const colorDemoBackgroundColor = (): React.CSSProperties => {
    let style: React.CSSProperties = {
      padding: '10px'
    };
    if (formData.background_color !== '') {
      style.backgroundColor = formData.background_color;
    }
    return style;
  }

  const colorDemoColor = (): React.CSSProperties => {
    let style: React.CSSProperties = {};
    if (formData.color !== '') {
      style.color = formData.color;
    }
    return style;
  }

  const getSupportedTypes = useCallback(() => {
    Axios.get(
      _usr_const.ApiUrl + 'activity-statuses/supported-types.json',
      {
        cancelToken: supportedTypesSource.current.token
      }
    )
    .then((results: any) => {
      if (!unmounted.current) {
        setSupportedTypes(results.data);
      }
    })
    .catch(() => {
      if (!unmounted.current) {
        setSupportedTypes({});
      }
    });
  }, [unmounted]);

  // clean up
  useEffect(() => {
    const alSource = Object.assign({}, alertsSource.current);
    const atSource = Object.assign({}, activityTypesSource.current);
    const clSource = Object.assign({}, colorsSource.current);
    const stySource = Object.assign({}, supportedTypesSource.current);
    return () => {
      // cancel axios get
      alSource.cancel();
      atSource.cancel();
      clSource.cancel();
      stySource.cancel();
      unmounted.current = true;
    }
  }, []);

  // get alerts
  useEffect(() => {
    if (alerts === undefined) {
      Axios.get(
        _usr_const.ApiUrl + 'activity-statuses/alerts.json',
        {
          cancelToken: alertsSource.current.token
        }
      )
      .then((results: any) => {
        if (!unmounted.current) {
          setAlerts(results.data.alerts);
        }
      })
      .catch(() => {
        if (!unmounted.current) {
          setAlerts({});
        }
      });
    }
  }, [alerts, unmounted]);

  // get colors
  useEffect(() => {
    if (colors === undefined) {
      Axios.get(
        _usr_const.ApiUrl + 'activity-statuses/colors.json',
        {
          cancelToken: colorsSource.current.token
        }
      )
      .then((results: any) => {
        if (!unmounted.current) {
          setColors(results.data.colors);
        }
      })
      .catch(() => {
        if (!unmounted.current) {
          setColors({});
        }
      });
    }
  }, [colors, unmounted]);

  // get supportedTypes
  useEffect(() => {
    if (supportedTypes === undefined) {
      getSupportedTypes();
    }
  }, [supportedTypes, unmounted, getSupportedTypes]);

  const isDefaultNode = (callbackParams: DataTableFuncTbodyRowProps): React.ReactNode => {
    return (
      <span>
        {
          callbackParams.record.is_default === true &&
          <span>&#10004;</span>
        }
      </span>
    )
  }

  const isEndtNode = (callbackParams: DataTableFuncTbodyRowProps): React.ReactNode => {
    return (
      <span>
        {
          callbackParams.record.is_end === true &&
          <span>&#10004;</span>
        }
      </span>
    )
  }

  const rows: DataTableRowsProps<ActivityStatusDataType>[] = [
    {
      label: 'ステータス名',
      value: 'name',
      align: 'left',
    },
    {
      label: 'ステータスレベル',
      value: 'level',
      align: 'left'
    },
    {
      label: '通知レベル',
      value: 'alert',
      align: 'left',
    },
    {
      label: 'デフォルト値',
      align: 'center',
      node: isDefaultNode
    },
    {
      label: '終了ステータス',
      align: 'center',
      node: isEndtNode
    },
  ];

  const actions: DataTableActionsProps<ActivityStatusDataType>[] = [
    {
      title: '追加',
      func: handleClickAdd,
      color: 'primary',
    },
    {
      title: 'ステータスレベル変更',
      func: handleClickEditLevel,
      color: 'default',
    }
  ];

  return (
    <Paper className="content-2 activity-statuses">
      <h2 className="h-2">商談ステータス</h2>
      <DataTable<ActivityStatusDataType>
        rows={rows}
        checkbox={false}
        jsonPath={'activity-statuses.json'}
        urlQuery={false}
        actions={actions}
        filterGroupsInline={true}
        handleClickCell={clickCell}
        limit={10}
        size="small"
      />
      <div style={{ marginTop: '20px' }}>
        <h2 className="h-2">対応種別一覧</h2>
        {
          typeof supportedTypes === 'object' && Object.keys(supportedTypes).length > 0 &&
          <table className="table-1">
            <thead>
              <tr>
                <th></th>
                {
                  supportedTypes.types !== undefined &&
                  Object.values(supportedTypes.types).map((type: string, index: number) => (
                    <th
                    key={`at-stypes-thead-${index}`}
                    className="txt-center"
                    >
                      {type}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                supportedTypes.results !== undefined && 
                supportedTypes.results.map((result: SupportedTypesResultType, index: number) => (
                  <tr
                  key={`at-stypes-tbody-${index}`}
                  >
                    <th>{result.name}</th>
                    {
                      Object.values(result.types).map((type: boolean, index: number) => (
                        <td
                        key={`at-stypes-tbody-td-${index}`}
                        className="txt-center"
                        >
                          {
                            type === true &&
                            <span>
                              &#10004;
                            </span>
                          }
                        </td>
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        }
      </div>
      <Dialog
        fullWidth={true}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {
            formType === 'add' &&
            <span>
              商談ステータス登録
            </span>
          }
          {
            formType === 'edit' &&
            <span>
              商談ステータス編集
            </span>
          }
        </DialogTitle>
        <DialogContent>
          <form className="form-content dialog-form">
            <div className="view-content-in form-content">
              <div className="form-input-group">
                <ZshTextField
                  value={formData.name}
                  name="name"
                  handleChange={handleChangeFormData}
                  label="名前"
                  fullWidth
                  required
                  validateErrors={validateErrors}
                />
              </div>
              <div className="form-input-group">
                <AjaxSelect
                name="alert"
                label="通知レベル"
                menuItems={alerts}
                formData={formData}
                handleChange={handleChangeFormData}
                validateErrors={validateErrors}
                />
              </div>
              <div className="form-input-group">
                <FormGroup>
                  <FormControlLabel
                    label="デフォルト値"
                    control={
                      <Checkbox
                        color="primary"
                        name="is_default"
                        checked={formData.is_default}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          setFormData({
                            ...formData,
                            is_default: checked
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
              </div>
              <div className="form-input-group">
                <FormGroup>
                  <FormControlLabel
                    label="終了ステータス"
                    control={
                      <Checkbox
                        color="primary"
                        name="is_end"
                        checked={formData.is_end}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          setFormData({
                            ...formData,
                            is_end: checked
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
              </div>
              <div className="form-input-group">
                <p style={{ fontWeight: 'bold' }}>対象種別</p>
                <FormControl>
                  <Select
                  value={selectedActivityType}
                  onChange={handleChangeActivityTypeSelect}
                  >
                    <MenuItem value="0"></MenuItem>
                    {
                      typeof activityTypes === 'object' &&
                      Object.keys(activityTypes).map((key: string, index: number) => (
                        <MenuItem
                        key={`as-at-${index}`}
                        value={key}
                        disabled={(() => {
                          if (formData.activity_types._ids.indexOf(Number(key)) !== -1) {
                            return true
                          }
                          return false;
                        })()}
                        >
                          {activityTypes[key]}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <Button
                  size="small"
                  variant="contained"
                  style={
                    {
                      marginTop: '15px'
                    }
                  }
                  onClick={handleAddSelectActivityType}
                  >
                    追加
                  </Button>
                </FormControl>
                <div>
                  {
                    formData.activity_types._ids.length > 0 && activityTypes !== undefined &&
                    <List>
                      {
                        formData.activity_types._ids.map((id: number, index: number) => (
                          <ListItem
                          key={`as-at-sel-${index}`}
                          >
                            {
                              activityTypes[id.toString()] !== undefined &&
                              <ListItemText
                                primary={activityTypes[id.toString()]}
                              />
                            }
                            <ListItemSecondaryAction>
                              <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteSelectActivityType(id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))
                      }
                    </List>
                  }
                </div>
              </div>
              <div className="form-input-group">
                <p style={{ fontWeight: 'bold' }}>色設定</p>
                {
                  typeof colors === 'object' && Object.keys(colors).length > 0 &&
                  <div>
                    {
                      Object.keys(colors).map((key: string, index: number) => (
                        <Button
                          key={`as-at-col-${index}`}
                          onClick={() => handleClickColor(colors[key])}
                        >
                          {colors[key].name}
                        </Button>
                      ))
                    }
                  </div>
                }
                <div>
                  文字色　
                  <input
                    type="color"
                    name="color"
                    value={(() => {
                      if (formData.color !== '') {
                        return formData.color;
                      }
                      return '#000000';
                    })()}
                    onChange={handleChangeFormData}
                  />
                </div>
                <div>
                  背景色　
                  <input
                    type="color"
                    name="background_color"
                    value={(() => {
                      if (formData.background_color !== '') {
                        return formData.background_color;
                      }
                      return '#000000';
                    })()}
                    onChange={handleChangeFormData}
                  />
                </div>
                <div>
                  <p style={{ fontSize: '0.9em' }}>表示例</p>
                  <div
                    style={colorDemoBackgroundColor()}
                  >
                    <div
                      style={colorDemoColor()}
                    >
                      テキスト
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
          onClick={() => {
            if (formType === 'add') {
              handleAddSubmit();
            }
            if (formType === 'edit') {
              handleEditSubmit();
            }
          }}
          color="primary"
          autoFocus>
            保存
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={showViewDialog}
        onClose={() => setShowViewDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>商談ステータス詳細</DialogTitle>
        <DialogContent>
          <table className="table-1">
            <tbody>
              <tr>
                <th>名前</th>
                <td>{data.name}</td>
              </tr>
              <tr>
                <th>通知レベル</th>
                <td>{data.alert}</td>
              </tr>
              <tr>
                <th>デフォルト値</th>
                <td>
                  {
                    data.is_default === true &&
                    <span>&#10004;</span>
                  }
                </td>
              </tr>
              <tr>
                <th>終了ステータス</th>
                <td>
                  {
                    data.is_end === true &&
                    <span>&#10004;</span>
                  }
                </td>
              </tr>
              <tr>
                <th>対象種別</th>
                <td>
                  {
                    data.activity_types.length > 0 &&
                    <div>
                      {
                        data.activity_types.map((activity_type: any, index: number) => (
                          <div key={`as-view-at-${index}`}>
                            {activity_type.name}
                          </div>
                        ))
                      }
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowViewDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
            onClick={handleEdit}
            color="primary"
          >
            編集
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={showEditLevelDialog}
        onClose={() => setShowEditLevelDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle> 商談ステータス ステータスレベル設定</DialogTitle>
        <DialogContent>
          <List>
            {
              typeof editLevelData !== 'undefined' && Object.keys(editLevelData).length > 0 &&
              Object.values(editLevelData).map((d: ActivityStatusDataType, index: number) => (
                <ListItem
                key={`as-edit-level-${index}`}
                >
                  <ListItemText
                  primary={d.name}
                  />
                  <ListItemSecondaryAction>
                      <IconButton
                      edge="end"
                      aria-label="up"
                      onClick={() => {
                        if (index !== 0) {
                          handleChangeLevel(d, 'up');
                        }
                      }}
                      disabled={index === 0}
                      >
                        <IconArrowDropUp />
                      </IconButton>
                      <IconButton
                      edge="end"
                      aria-label="down"
                      onClick={() => {
                        if ((index + 1) !== Object.keys(editLevelData).length) {
                          handleChangeLevel(d, 'down');
                        }
                      }}
                      disabled={(index + 1) === Object.keys(editLevelData).length}
                      >
                        <IconArrowDropDown />
                      </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowEditLevelDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
            onClick={handleEditLevelSubmit}
            color="primary"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}