import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { RouteComponentProps } from 'react-router-dom';
import * as _form from '../../helper/form';
import { ValidateErrorsProps } from '../../types/state';
import Paper from '@material-ui/core/Paper';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshTextField from '../../components/Form/ZshTextField';
import ZshEditor from '../../components/Form/ZshEditor';

export type SignaturesFormDataProps = {
  name: string;
  content: string | undefined;
};

export default function SignaturesAdd({ history }: RouteComponentProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const backActionName: string = '/signatures';

  const unmounted = useRef<boolean>(false);

  const [formData, setFormData] = useState<SignaturesFormDataProps>({
    name: '',
    content: ''
  });
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});

  const handleChange = (event: any): void => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  };

  const handleChangeContent = (editorContent: string): void => {
    setFormData({ ...formData, content: editorContent });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    _form.handleSubmit({
      action: 'signatures',
      formData,
      history,
      backActionName,
      setOverlayProppress,
      setFlashMessageSuccess,
      setFlashMessageError,
      setValidateErrors
    });
  }

  // clean up
  useEffect(() => {
    return () => {
      unmounted.current = true;
    }
  }, []);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'save',
    }
  ];

  return (
    <div id="signatures" className="content-1">
      <form onSubmit={handleSubmit}>
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in form-content">
              <table className="form-table">
                <tbody>
                  <tr>
                    <th className="required-th">署名タイトル</th>
                    <td>
                      <div className="form-input-group">
                        <ZshTextField
                          value={formData.name}
                          name="name"
                          handleChange={handleChange}
                          required
                          validateErrors={validateErrors}
                          fullWidth
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="required-th">内容</th>
                    <td>
                      <div className="form-input-group">
                        <ZshEditor
                          name="content"
                          onChange={handleChangeContent}
                          validateErrors={validateErrors}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
        </div>
      </form>
    </div>
  )
}