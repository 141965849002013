import * as _usr_const from './usr-constant';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import HowToReqIcon from '@material-ui/icons/HowToReg';
import BuildIcon from '@material-ui/icons/Build';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import MailIcon from '@material-ui/icons/Mail';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

/**
 * Role Allow
 */
let ConstRoleAllow: any = {
  'Customers': false,
  'Memos': true,
  'InflowSourceMasters': false,
  'MailMagazineGroups': false,
  'MailMagazines': false,
  'AttributeGroups': false,
  'MailFroms': false,
  'Signatures': false,
  'ShMailLogs': true,
  'Users': false,
  'Roles': true,
  'Logs': true,
  'AppSettings': true,
  'Calendars': true,
  'SystemSettings': true,
};
if (Object.keys(_usr_const.UserRoleAllow).length > 0) {
  ConstRoleAllow = Object.assign({}, _usr_const.UserRoleAllow);
}
export const RoleAllow: any = ConstRoleAllow;

/**
 * set Menus
 */
export type ChildMenuType = {
  title: string;
  icon?: any;
  to: string;
  name: string;
  allow?: boolean | undefined;
  show?: boolean | undefined;
  active?: boolean | undefined;
  IconComponent?: any;
}
export type MenuType = {
  title: string;
  menuTitle?: any;
  authority?: number | undefined;
  children: ChildMenuType[];
  show?: boolean | undefined;
}
let ConstMenus: MenuType[] = [
  {
    title: '',
    children: [
      {
        title: '顧客情報',
        icon: PeopleIcon,
        to: '/customers',
        name: 'Customers',
      },
      {
        title: '流入元マスタ',
        icon: AssignmentIcon,
        to: '/inflow-source-masters',
        name: 'InflowSourceMasters',
      },
      {
        title: 'メルマガグループ',
        icon: ContactMailIcon,
        to: '/mail-magazine-groups',
        name: 'MailMagazineGroups',
      },
      {
        title: 'メルマガ',
        icon: MailIcon,
        to: '/mail-magazines',
        name: 'MailMagazines',
      },
      {
        title: '属性グループ',
        icon: GroupWorkIcon,
        to: '/attribute-groups',
        name: 'AttributeGroups',
      },
      {
        title: 'From管理',
        icon: AlternateEmailIcon,
        to: '/mail-froms',
        name: 'MailFroms',
      },
      {
        title: '署名',
        icon: EditIcon,
        to: '/signatures',
        name: 'Signatures',
      },
      {
        title: 'メール配信ログ',
        to: '/sh-mail-logs',
        name: 'ShMailLogs',
      },
      {
        title: 'スケジュール',
        icon: CalendarTodayIcon,
        to: '/calendars',
        name: 'Calendars',
      },
    ],
  },
  {
    title: '管理者メニュー',
    authority: 90,
    children: [
      {
        title: 'ユーザー',
        icon: PersonIcon,
        to: '/users',
        name: 'Users',
      },
      {
        title: '権限グループ',
        icon: HowToReqIcon,
        to: '/roles',
        name: 'Roles',
      },
      {
        title: 'ログ',
        icon: DescriptionIcon,
        to: '/logs',
        name: 'Logs',
      },
      {
        title: '設定',
        icon: BuildIcon,
        to: '/app-settings',
        name: 'AppSettings',
      },
    ],
  },
  {
    title: 'システム管理者メニュー',
    authority: 100,
    children: [
      {
        title: 'システム設定',
        to: '/system-settings',
        name: 'SystemSettings',
      }
    ]
  }
];
if (_usr_const.UserMenus.length > 0) {
  ConstMenus = _usr_const.UserMenus;
}
// set menu allow
ConstMenus.forEach((element: any) => {
  element['children'].forEach((child: any) => {
    if (RoleAllow[child.name] !== undefined) {
      if (RoleAllow[child.name] === true) {
        child.allow = true;
      } else {
        child.allow = false;
      }
    } else {
      child.allow = false;
    }
  });
});
export const Menus: MenuType[] = ConstMenus;

/**
 * set Settings
 */
let setloginRedirect = '/customers';
if (_usr_const.setloginRedirect !== '') {
  setloginRedirect = _usr_const.setloginRedirect;
}
export const loginRedirect = setloginRedirect;

export const Prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];