import { atom, selector } from 'recoil';

export type flashMessageAtomProps = {
    level: string;
    message: string;
}

export const flashMessage = atom<flashMessageAtomProps>({
    key: 'flashMessage',
    default: {
        level: 'success',
        message: ''
    },
});

export const flashMessageSuccess = selector({
    key: 'flashMessageSuccess',
    get: ({ get }) => {
        return get(flashMessage)
    },
    set: ({ set }, newValue) => {
        let message: string = '';
        if (typeof newValue === 'string') {
            message = newValue;
        }
        return set(flashMessage, {
            level: 'success',
            message
        })
    }
});

export const flashMessageError = selector({
    key: 'flashMessageError',
    get: ({ get }) => {
        return get(flashMessage)
    },
    set: ({ set }, newValue) => {
        let message: string = '';
        if (typeof newValue === 'string') {
            message = newValue;
        }
        return set(flashMessage, {
            level: 'error',
            message
        })
    }
});

export const flashMessageWarning = selector({
    key: 'flashMessageWarning',
    get: ({ get }) => {
        return get(flashMessage)
    },
    set: ({ set }, newValue) => {
        let message: string = '';
        if (typeof newValue === 'string') {
            message = newValue;
        }
        return set(flashMessage, {
            level: 'warning',
            message
        })
    }
});

export const flashMessageInfo = selector({
    key: 'flashMessageInfo',
    get: ({ get }) => {
        return get(flashMessage)
    },
    set: ({ set }, newValue) => {
        let message: string = '';
        if (typeof newValue === 'string') {
            message = newValue;
        }
        return set(flashMessage, {
            level: 'info',
            message
        })
    }
});