import * as _customers from './view/Customers';
import * as _inflowSourceMasters from './view/InflowSourceMasters';
import * as _mailMagazineGroups from './view/MailMagazineGroups';
import * as _mailMagazines from './view/MailMagazines';
import * as _attributeGroups from './view/AttributeGroups';
import * as _mailFroms from './view/MailFroms';
import * as _signatures from './view/Signatures';
import * as _shMailLogs from './view/ShMailLogs';
import * as _users from './view/Users';
import * as _roles from './view/Roles';
import * as _logs from './view/Logs';
import * as _appSettings from './view/AppSettings';
import CalendarsIndex from './view/Calendars/CalendarsIndex';
import SystemSettingsIndex from './view/SystemSettings/SystemSettingsIndex';

type RouterType = {
  path: string;
  component: any;
  name: string;
  roleAction: string;
}

let setRouter: RouterType[] = [];

/**
 * Customers
 */
setRouter.push(
  {
    path: '/',
    component: _customers.CustomersIndex,
    name: 'Customers',
    roleAction: 'view',
  },
  {
    path: '/customers',
    component: _customers.CustomersIndex,
    name: 'Customers',
    roleAction: 'view',
  },
  {
    path: '/customers/add',
    component: _customers.CustomersAdd,
    name: 'Customers',
    roleAction: 'add',
  },
  {
    path: '/customers/import-csv',
    component: _customers.CustomersImportCsv,
    name: 'Customers',
    roleAction: 'csv_import',
  },
  {
    path: '/customers/:id',
    component: _customers.CustomersView,
    name: 'Customers',
    roleAction: 'view',
  },
  {
    path: '/customers/:id/edit',
    component: _customers.CustomersEdit,
    name: 'Customers',
    roleAction: 'edit',
  }
);

/**
 * InflowSourceMasters
 */
setRouter.push(
  {
  path: '/inflow-source-masters',
  component: _inflowSourceMasters.InflowSourceMastersIndex,
  name: 'InflowSourceMasters',
  roleAction: 'view',
  },
  {
    path: '/inflow-source-masters/add',
    component: _inflowSourceMasters.InflowSourceMastersAdd,
    name: 'InflowSourceMasters',
    roleAction: 'add',
  },
  {
    path: '/inflow-source-masters/:id',
    component: _inflowSourceMasters.InflowSourceMastersView,
    name: 'InflowSourceMasters',
    roleAction: 'view',
  },
  {
    path: '/inflow-source-masters/:id/edit',
    component: _inflowSourceMasters.InflowSourceMastersEdit,
    name: 'InflowSourceMasters',
    roleAction: 'edit',
  },
);

/**
 * MailMagazineGroups
 */
setRouter.push(
  {
  path: '/mail-magazine-groups',
  component: _mailMagazineGroups.MailMagazineGroupsIndex,
  name: 'MailMagazineGroups',
  roleAction: 'view',
  },
  {
    path: '/mail-magazine-groups/add',
    component: _mailMagazineGroups.MailMagazineGroupsAdd,
    name: 'MailMagazineGroups',
    roleAction: 'add',
  },
  {
    path: '/mail-magazine-groups/:id',
    component: _mailMagazineGroups.MailMagazineGroupsView,
    name: 'MailMagazineGroups',
    roleAction: 'view',
  },
  {
    path: '/mail-magazine-groups/:id/edit',
    component: _mailMagazineGroups.MailMagazineGroupsEdit,
    name: 'MailMagazineGroups',
    roleAction: 'edit',
  },
);

/**
 * MailMagazines
 */
setRouter.push(
  {
  path: '/mail-magazines',
  component: _mailMagazines.MailMagazinesIndex,
  name: 'MailMagazines',
  roleAction: 'view',
  },
  {
    path: '/mail-magazines/add',
    component: _mailMagazines.MailMagazinesAdd,
    name: 'MailMagazines',
    roleAction: 'add',
  },
  {
    path: '/mail-magazines/:id',
    component: _mailMagazines.MailMagazinesView,
    name: 'MailMagazines',
    roleAction: 'view',
  },
  {
    path: '/mail-magazines/:id/edit',
    component: _mailMagazines.MailMagazinesEdit,
    name: 'MailMagazines',
    roleAction: 'edit',
  },
);

/**
 * AttributeGroups
 */
setRouter.push(
  {
  path: '/attribute-groups',
  component: _attributeGroups.AttributeGroupsIndex,
  name: 'AttributeGroups',
  roleAction: 'view',
  },
  {
    path: '/attribute-groups/add',
    component: _attributeGroups.AttributeGroupsAdd,
    name: 'AttributeGroups',
    roleAction: 'add',
  },
  {
    path: '/attribute-groups/:id',
    component: _attributeGroups.AttributeGroupsView,
    name: 'AttributeGroups',
    roleAction: 'view',
  },
  {
    path: '/attribute-groups/:id/edit',
    component: _attributeGroups.AttributeGroupsEdit,
    name: 'AttributeGroups',
    roleAction: 'edit',
  },
);

/**
 * MailFroms
 */
setRouter.push(
  {
  path: '/mail-froms',
  component: _mailFroms.MailFromsIndex,
  name: 'MailFroms',
  roleAction: 'view',
  },
  {
    path: '/mail-froms/add',
    component: _mailFroms.MailFromsAdd,
    name: 'MailFroms',
    roleAction: 'add',
  },
  {
    path: '/mail-froms/:id',
    component: _mailFroms.MailFromsView,
    name: 'MailFroms',
    roleAction: 'view',
  },
  {
    path: '/mail-froms/:id/edit',
    component: _mailFroms.MailFromsEdit,
    name: 'MailFroms',
    roleAction: 'edit',
  },
);

/**
 * Signatures
 */
setRouter.push(
  {
  path: '/signatures',
  component: _signatures.SignaturesIndex,
  name: 'Signatures',
  roleAction: 'view',
  },
  {
    path: '/signatures/add',
    component: _signatures.SignaturesAdd,
    name: 'Signatures',
    roleAction: 'add',
  },
  {
    path: '/signatures/:id',
    component: _signatures.SignaturesView,
    name: 'Signatures',
    roleAction: 'view',
  },
  {
    path: '/signatures/:id/edit',
    component: _signatures.SignaturesEdit,
    name: 'Signatures',
    roleAction: 'edit',
  },
);

/**
 * ShMailLogs
 */
setRouter.push(
  {
  path: '/sh-mail-logs',
  component: _shMailLogs.ShMailLogsIndex,
  name: 'ShMailLogs',
  roleAction: 'view',
  },
  {
    path: '/sh-mail-logs/:id',
    component: _shMailLogs.ShMailLogsView,
    name: 'ShMailLogs',
    roleAction: 'view',
  },
);

/**
 * Users
 */
setRouter.push(
  {
    path: '/users',
    component: _users.UsersIndex,
    name: 'Users',
    roleAction: 'view',
  },
  {
    path: '/users/add',
    component: _users.UsersAdd,
    name: 'Users',
    roleAction: 'add',
  },
  {
    path: '/users/:id',
    component: _users.UsersView,
    name: 'Users',
    roleAction: 'view',
  },
  {
    path: '/users/:id/edit',
    component: _users.UsersEdit,
    name: 'Users',
    roleAction: 'edit',
  }
);

/**
 * Roles
 */
setRouter.push(
  {
    path: '/roles',
    component: _roles.RolesIndex,
    name: 'Roles',
    roleAction: 'view',
  },
  {
    path: '/roles/add',
    component: _roles.RolesAdd,
    name: 'Roles',
    roleAction: 'add',
  },
  {
    path: '/roles/:id',
    component: _roles.RolesView,
    name: 'Roles',
    roleAction: 'view',
  },
  {
    path: '/roles/:id/edit',
    component: _roles.RolesEdit,
    name: 'Roles',
    roleAction: 'edit',
  }
);

/**
 * Logs
 */
setRouter.push(
  {
    path: '/logs',
    component: _logs.LogsIndex,
    name: 'Logs',
    roleAction: 'view',
  },
  {
    path: '/logs/:id',
    component: _logs.LogsView,
    name: 'Logs',
    roleAction: 'view',
  },
);

/**
 * AppSettings
 */
setRouter.push(
  {
    path: '/app-settings',
    component: _appSettings.AppSettingsView,
    name: 'AppSettings',
    roleAction: 'view',
  },
  {
    path: '/app-settings/edit',
    component: _appSettings.AppSettingsEdit,
    name: 'AppSettings',
    roleAction: 'edit',
  },
);

/**
 * Calendars
 */
setRouter.push(
  {
    path: '/calendars',
    component: CalendarsIndex,
    name: 'Calendars',
    roleAction: 'view',
  },
);

/**
 * SystemSettings
 */
setRouter.push(
  {
    path: '/system-settings',
    component: SystemSettingsIndex,
    name: 'SystemSettings',
    roleAction: 'view',
  },
);

export const router: RouterType[] = setRouter;