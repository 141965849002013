import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import { Role } from '../../types/model';

export default function RolesView({ history, match }: RouteComponentProps<{ id: string }>) {

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Role>({
    id: 0,
    name: '',
    role: {},
    common_parent: undefined,
  });
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);
  const [roleActions, setRoleActions] = useState<{ [key: string]: string; }>({});
  const [roleNames, setRoleNames] = useState<{ [key: string]: string }>({});

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      _view_action.getViewData<Role>({
        unmounted,
        source,
        dataKey: 'role',
        path: _usr_const.ApiUrl + 'roles/' + match.params.id + '.json',
        setData,
        setDataMounted,
        setErrorStatus,
        setErrorMsg,
        setLoading,
        successCallbackBeforeSetData: (results) => {
          setRoleActions(results.data.role_actions);
          setRoleNames(results.data.role_names);
        }
      })
        .catch((error: any) => {
          _debug.debugAxiosError(error);
        });
      setInit(false);
    }
  }, [isInit, match.params.id]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'edit',
      func: (): void => {
        history.push('/roles/' + match.params.id + '/edit');
      }
    },
  ];

  const roleView: JSX.Element[] = Object.keys(data.role).map((role: string) => {
    let showRole: boolean = true;
    if (typeof roleNames[role] === 'undefined') {
      showRole = false;
    }
    const RoleName: string = typeof roleNames[role] !== 'undefined' ? roleNames[role] : '';
    const RoleActions: JSX.Element[] = data.role[role].map((action: string) => {
      const ActionName: string = roleActions[action] ? roleActions[action] : '';
      return (
        <li key={role + action} className="mb5">{ActionName}</li>
      );
    });
    return (
      <div key={role}>
        {
          showRole &&
          <div className="role-actions-wr">
            <div className="role-actions-title">{RoleName}</div>
            <ul className="role-actions">
              {RoleActions}
            </ul>
          </div>
        }
      </div>
    );
  });

  return (
    <div id="roles" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <th>権限グループ名</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>権限設定</th>
                    <td>{roleView}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
          {
            data.common_parent !== undefined && data.common_parent.logs !== undefined &&
            <ZshLogs logs={data.common_parent.logs} />
          }
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}