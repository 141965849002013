import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { RouteComponentProps } from 'react-router-dom'
import { zshDialog } from '../../atoms/ZshDialog';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import * as _usr_const from '../../config/usr-constant';
import * as _view_action from '../../helper/viewAction';
import * as _debug from '../../helper/debug';
import * as _filter from '../../helper/filter';
import * as _data_actions from '../../helper/dataActions';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Loading from '../../components/View/Loading';
import HeadButtonGroups, { HeadButtonGroupBtnActionsProps } from '../../components/View/HeadButtonGroups';
import ZshLogs from '../../components/View/ZshLogs';
import ErrorView from '../../components/View/ErrorView';
import ZshTooltip from '../../components/View/ZshTooltip';
import DataTable, {
  DataTableFuncTbodyRowProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import {
  MailMagazineGroup,
  MailMagazine,
  MailMagazineDistributionStops,
  Customer
} from '../../types/model';

type TabPanelProps = {
  children?: JSX.Element;
  index: any;
  value: any;
}

export default function MailMagazineGroupsView({ history, match }: RouteComponentProps<{ id: string }>) {

  const setZshDialog = useSetRecoilState(zshDialog);
  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const unmounted = useRef(false);
  const source = useRef(axios.CancelToken.source());
  const postSource = useRef(axios.CancelToken.source());

  const [isInit, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MailMagazineGroup>({
    id: 0,
    common_parent_id: 0,
    attribute_group_id: 0,
    name: '',
    attribute_group: {
      id: 0,
      name: '',
      conditions: []
    },
    mail_from: {
      id: 0,
      email: ''
    },
    from_name: '',
    signature: {
      id: 0,
      name: '',
      content: ''
    },
    reply_to: '',
    customers_count: 0,
    stops_count: 0,
    not_send_customers_count: 0,
    common_parent: undefined
  });
  const [dataMounted, setDataMounted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<number>(0);
  const [tabValue, setTabValue] = useState<number>(0);

  const getData = useCallback(() => {
    _view_action.getViewData<MailMagazineGroup>({
      unmounted,
      source,
      dataKey: 'mailMagazineGroup',
      path: _usr_const.ApiUrl + 'mail-magazine-groups/' + match.params.id + '.json',
      setData,
      setDataMounted,
      setErrorStatus,
      setErrorMsg,
      setLoading
    })
      .catch((error) => {
        _debug.debugAxiosError(error);
      });
  }, [match.params.id]);

  const TabPanel = (props: TabPanelProps): JSX.Element => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`mmg-tabpanel-${index}`}
        aria-labelledby={`mmg-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const a11yProps = (index: any): { [key: string]: string } => {
    return {
      id: `mmg-tab-${index}`,
      'aria-controls': `mmg-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTabValue(newValue);
  };

  const handleClickMailMagazineCell = (callbackParams: DataTableHandleClickCellProps<MailMagazine>): void => {
    if (callbackParams.record.id !== undefined) {
      history.push('/mail-magazines/' + callbackParams.record.id);
    }
  }

  const handleClickCustomersCell = (callbackParams: DataTableHandleClickCellProps<any>): void => {
    if (callbackParams.record.id !== undefined) {
      history.push('/customers/' + callbackParams.record.id);
    }
  }

  const handleClickStopSubmit = (params: DataTableFuncTbodyRowProps<Customer>): void => {
    if (params.record.id === undefined) {
      setFlashMessageError('変更に必要な値を取得できませんでした');
      return;
    }
    setOverlayProppress(true);
    axios
      .post(
        _usr_const.ApiUrl + 'mail-magazine-groups/change-stop-customer',
        {
          mail_magazine_group_id: Number(match.params.id),
          customer_id: params.record.id
        },
      )
      .then((response: any) => {
        setFlashMessageSuccess('配信停止にしました');
        if (params.getData !== undefined && typeof params.getData === 'function') {
          params.getData();
        }
        getData();
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (error.response.data[0] !== undefined) {
          setFlashMessageError(error.response.data[0]);
        } else {
          setFlashMessageError('エラーが発生し変更できませんでした');
        }
      })
      .finally(() => {
        setOverlayProppress(false);
      });
  }

  const handleClickActiveSubmit = (params: DataTableFuncTbodyRowProps<MailMagazineDistributionStops>): void => {
    if (params.record.customer_id === undefined) {
      setFlashMessageError('変更に必要な値を取得できませんでした');
      return;
    }
    setOverlayProppress(true);
    axios
      .post(
        _usr_const.ApiUrl + 'mail-magazine-groups/change-active-customer',
        {
          mail_magazine_group_id: Number(match.params.id),
          customer_id: params.record.customer_id
        },
      )
      .then((response: any) => {
        setFlashMessageSuccess('配信再開しました');
        if (params.getData !== undefined && typeof params.getData === 'function') {
          params.getData();
        }
        getData();
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (error.response.data[0] !== undefined) {
          setFlashMessageError(error.response.data[0]);
        } else {
          setFlashMessageError('エラーが発生し変更できませんでした');
        }
      })
      .finally(() => {
        setOverlayProppress(false);
      });
  }

  const handleClickStop = (event: any, params: DataTableFuncTbodyRowProps<Customer>): void => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '配信停止にしてよろしいですか？',
      closeText: 'キャンセル',
      agreeText: 'OK',
      funcAgree: () => {
        handleClickStopSubmit(params);
      },
      setZshDialog
    });
  }

  const handleClickActive = (event: any, params: DataTableFuncTbodyRowProps<MailMagazineDistributionStops>): void => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '配信再開してよろしいですか？',
      closeText: 'キャンセル',
      agreeText: 'OK',
      funcAgree: () => {
        handleClickActiveSubmit(params);
      },
      setZshDialog
    });
  }

  const MailMagazineRow = (params: DataTableFuncTbodyRowProps<MailMagazine>): JSX.Element => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div>
            {
              params.record.subject !== undefined &&
              <span>{params.record.subject}</span>
            }
          </div>
          <div>
            {
              params.record.status_display !== undefined &&
              <span dangerouslySetInnerHTML={{ __html: params.record.status_display }}></span>
            }
          </div>
          <div>
            {
              params.record.send_date !== undefined && params.record.send_date !== null &&
              <span>配信日時：{_filter.ShFilter(params.record.send_date, 'YMDHm')}</span>
            }
          </div>
        </div>
      </div>
    );
  }

  const CustomersRow = (params: DataTableFuncTbodyRowProps<Customer>): JSX.Element => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div>
            {
              params.record.email !== undefined &&
              <span>{params.record.email}</span>
            }
          </div>
        </div>
        <div className="data-table-inline-btn-group">
          <Button
            size="small"
            variant="outlined"
            className="btn"
            onClick={(event: any) => handleClickStop(event, params)}
          >
            配信停止
          </Button>
        </div>
      </div>
    );
  }

  const StopCustomersRow = (params: DataTableFuncTbodyRowProps<MailMagazineDistributionStops>): JSX.Element => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div>
            {
              params.record.customer_email !== undefined &&
              <span>{params.record.customer_email}</span>
            }
          </div>
        </div>
        <div className="data-table-inline-btn-group">
          <Button
            size="small"
            variant="outlined"
            className="btn"
            onClick={(event: any) => handleClickActive(event, params)}
          >
            配信再開
          </Button>
        </div>
      </div>
    );
  }

  const NotSendCustomersRow = (params: DataTableFuncTbodyRowProps<Customer>): JSX.Element => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div>
            {
              params.record.email !== undefined &&
              <span>{params.record.email}</span>
            }
          </div>
        </div>
      </div>
    );
  }

  const handleMailMagazineAdd = (): void => {
    history.push('/mail-magazines/add/?mail_magazine_group_id=' + match.params.id);
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    const pSource = Object.assign({}, postSource.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      pSource.cancel();
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      setLoading(true);
      getData();
      setInit(false);
    }
  }, [isInit, getData]);

  const btnActions: HeadButtonGroupBtnActionsProps[] = [
    {
      type: 'edit',
      func: (): void => {
        history.push('/mail-magazine-groups/' + match.params.id + '/edit');
      },
      role: {
        name: 'MailMagazineGroups',
        action: 'edit',
      },
    },
  ];

  const mailMagazineBtnGroup: any = [
    {
      title: 'メルマガ登録',
      func: handleMailMagazineAdd,
      color: 'primary',
    },
  ];

  return (
    <div id="mail-magazine-groups" className="content-1">
      <Loading loading={loading} />
      {
        dataMounted &&
        <div>
          <HeadButtonGroups
            history={history}
            btnActions={btnActions}
          />
          <Paper>
            <div className="view-content-in mail-magazine-groups">
              <table className="table-1">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <th>メルマガグループ名</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>配信先の属性グループ</th>
                    <td>
                      {
                        data.attribute_group !== null && data.attribute_group !== undefined &&
                        <span>{data.attribute_group.name}</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>From</th>
                    <td>
                      {
                        data.mail_from !== null && data.mail_from !== undefined &&
                        <span>{data.mail_from.email}</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>送信者名<ZshTooltip text="配信されたメールの送信者名（From）に表示されます" /></th>
                    <td>{data.from_name}</td>
                  </tr>
                  <tr>
                    <th>署名</th>
                    <td>
                      {
                        data.signature !== null && data.signature !== undefined &&
                        <span>{data.signature.name}</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Reply To</th>
                    <td>{data.reply_to}</td>
                  </tr>
                  <tr>
                    <th>読者数</th>
                    <td>{data.customers_count.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>配信停止者数</th>
                    <td>{data.stops_count.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>配信対象外者数</th>
                    <td>{data.not_send_customers_count.toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
              <div className="mail-magazine-groups-tab-wr">
                <Paper square>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="mail magazine group detail"
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="メルマガ" {...a11yProps(0)} />
                    <Tab label="読者一覧" {...a11yProps(1)} />
                    <Tab label="配信停止一覧" {...a11yProps(2)} />
                    <Tab label="配信対象外者一覧" {...a11yProps(3)} />
                  </Tabs>
                </Paper>
                <TabPanel value={tabValue} index={0}>
                  <DataTable<MailMagazine>
                    checkbox={false}
                    jsonPath={'mail-magazines/index/index.json'}
                    urlQuery={false}
                    handleClickCell={handleClickMailMagazineCell}
                    funcTbodyRow={MailMagazineRow}
                    filterGroupsInline={true}
                    size="small"
                    actions={mailMagazineBtnGroup}
                    defaultQueryValues={
                      {
                        mail_magazine_group_id: match.params.id
                      }
                    }
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <DataTable<Customer>
                    checkbox={false}
                    jsonPath={'mail-magazine-groups/get-customers/' + data.id + '/index.json'}
                    urlQuery={false}
                    handleClickCell={handleClickCustomersCell}
                    funcTbodyRow={CustomersRow}
                    filterGroupsInline={true}
                    size="small"
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <DataTable<MailMagazineDistributionStops>
                    checkbox={false}
                    jsonPath={'mail-magazine-groups/get-stop-customers/' + data.id + '/index.json'}
                    urlQuery={false}
                    handleClickCell={handleClickCustomersCell}
                    funcTbodyRow={StopCustomersRow}
                    filterGroupsInline={true}
                    size="small"
                    defaultQueryValues={
                      {
                        mail_magazine_group_id: match.params.id
                      }
                    }
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <DataTable<Customer>
                    checkbox={false}
                    jsonPath={'mail-magazine-groups/get-not-send-customers/' + data.id + '/index.json'}
                    urlQuery={false}
                    handleClickCell={handleClickCustomersCell}
                    funcTbodyRow={NotSendCustomersRow}
                    filterGroupsInline={true}
                    size="small"
                  />
                </TabPanel>
              </div>
            </div>
          </Paper>
          {
            data.common_parent !== undefined && data.common_parent.logs !== undefined &&
            <ZshLogs logs={data.common_parent.logs} />
          }
        </div>
      }
      {
        errorMsg !== '' &&
        <div>
          <ErrorView
            history={history}
            errorStatus={errorStatus}
            errorMsg={errorMsg}
          />
        </div>
      }
    </div>
  )
}