import * as _usr_const from '../../../config/usr-constant';
import * as _debug from '../../../helper/debug';
import axios from 'axios';

export type MailMagazineGroupFormProps = {
  name: string;
  attribute_group_id: string | number;
  from_name: string;
  mail_from_id: string | number;
  signature_id: string | number;
  reply_to:  string;
}

export type MailMagazineGroupFormParamsProps = {
  mailFroms: { [key: number]: string };
  signatures: { [key: number]: string };
}

type getMailMagazineGroupsFormParamsProps = {
  unmounted: boolean,
  setFormParams: React.Dispatch<React.SetStateAction<MailMagazineGroupFormParamsProps>>,
  setFormParamsError: React.Dispatch<React.SetStateAction<boolean>>,
  source: any
}
export async function getMailMagazineGroupsFormParams({
  unmounted,
  setFormParams,
  setFormParamsError,
  source
}: getMailMagazineGroupsFormParamsProps) {
  // get data
  await axios
  .get(
    _usr_const.ApiUrl + 'mail-magazine-groups/get-form-params.json', {
      cancelToken: source.current.token
    }
  )
  .then((response) => {    
    if (typeof response.data !== 'undefined') {
        if (!unmounted) {
          setFormParams(response.data);
        }
    }
  })
  .catch((error) => {
    _debug.debugAxiosError(error);
    if (!unmounted) { 
      setFormParamsError(true);
    }
  })
  .finally(() => {
    return Promise.resolve(1);
  });
}