import * as _usr_const from '../../../config/usr-constant';
import * as _debug from '../../../helper/debug';
import axios from 'axios';

type getTypesProps = {
  unmounted: boolean,
  setTypes: React.Dispatch<React.SetStateAction<{ [key: number]: string } | undefined>>,
  setTypesError: React.Dispatch<React.SetStateAction<boolean>>,
  source: any
}
export async function getTypes({
  unmounted,
  setTypes,
  setTypesError,
  source
}: getTypesProps) {
  // get data
  await axios
  .get(
    _usr_const.ApiUrl + 'inflow-source-masters/get-inflow-source-types.json', {
      cancelToken: source.current.token
    }
  )
  .then((response) => {    
    if (typeof response.data !== 'undefined') {
        let types: any = {};
        for (const ismTypes of response.data.inflow_source_types) {
          types[ismTypes['id']] = ismTypes['name'];
        }
        if (!unmounted) {
          setTypes(types);
        }
    }
  })
  .catch((error) => {
    _debug.debugAxiosError(error);
    if (!unmounted) { 
      setTypes({});
      setTypesError(true);
    }
  })
  .finally(() => {
    return Promise.resolve(1);
  });
}