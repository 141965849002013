import React, { useState, useRef, useEffect, useCallback, createRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { flashMessageError } from '../../atoms/FlashMessage';
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _formatter from '../../helper/formatter';
import * as _debug from '../../helper/debug';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { AttributeGroupConditionProps } from './AttributeGroupConditionInputs';
import Loading from '../View/Loading';
import { DataTableRowsProps } from '../View/DataTable';
import { PaginateProps } from '../../types/state';
import { Customer } from '../../types/model';

type AttributeGroupConditionPreviewProps = {
  conditions: AttributeGroupConditionProps[];
}

type QueriesProps = {
  limit: number;
  page: number;
  sort: string;
  direction: string;
}

type PreviewResponseDataProps = {
  data: {
    records: Customer[];
    paginate: PaginateProps;
  }
}

export default function AttributeGroupConditionPreview({
  conditions
}: AttributeGroupConditionPreviewProps) {

  const setFlashMessageError = useSetRecoilState(flashMessageError);

  const unmounted = useRef<boolean>(false);
  const source = useRef(axios.CancelToken.source());

  const [preview, setPreview] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [previewConditions, setPreviewConditions] = useState<AttributeGroupConditionProps[]>([]);
  const [records, setRecords] = useState<Customer[]>([]);
  const [paginate, setPaginate] = useState<PaginateProps>({
    mounted: false,
    count: 0,
    prevPage: false,
    nextPage: true,
    start: 0,
    end: 0,
    direction: 'asc',
    directionDefault: 'asc',
    limit: null,
    sort: '',
    sortDefault: '',
    page: 0,
    pageCount: 0
  });
  const [queries, setQueries] = useState<QueriesProps>({
    limit: 10,
    page: 0,
    sort: '',
    direction: 'asc'
  });

  const dataTableRef: any = createRef();

  const perPageOptions: number[] = [5, 10, 25, 50, 100];

  const getData = useCallback(() => {
    if (!unmounted.current) {
      setLoading(true);
    }
    let getQurries: QueriesProps = Object.assign({}, queries);
    getQurries.page = queries.page + 1;
    const queries_string: string = '?' + Object.entries(getQurries).map((e) => `${e[0]}=${e[1]}`).join('&');
    axios
      .post(
        _usr_const.ApiUrl + `customers/set-preview-conditions${queries_string}`,
        {
          conditions: previewConditions
        },
        {
          cancelToken: source.current.token
        }
      )
      .then((results: PreviewResponseDataProps) => {
        if (!unmounted.current) {
          setRecords(results.data.records);
          setPaginate({
            ...results.data.paginate,
            mounted: true
          });
        }
      })
      .catch((error) => {
        _debug.debugAxiosError(error);
        if (!unmounted.current) {
          setFlashMessageError('データ取得に失敗しました');
        }
      })
      .finally(() => {
        if (!unmounted.current) {
          setLoading(false);
        }
      });
  }, [queries, previewConditions, setFlashMessageError]);

  const handleClickPreview = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (conditions.length === 0) {
      setFlashMessageError('条件が未設定です');
    } else {
      setPreview(true);
      setPreviewConditions(conditions);
      setQueries({
        ...queries,
        page: 0
      });
    }
  }

  const cellData = (row: DataTableRowsProps<Customer>, record: any, valueKey: string): JSX.Element => {
    let value: any = '';
    if (valueKey.indexOf('.') !== -1) {
      let valueKeys: string[] = valueKey.split('.');
      let tmpValue: any = record;
      while (valueKeys.length > 0) {
        const findKey: string | undefined = valueKeys.shift();
        if (findKey === undefined || typeof tmpValue[findKey] === 'undefined') {
          valueKeys = [];
        }
        if (typeof findKey === 'string') {
          tmpValue = tmpValue[findKey]; 
        }
      }
      if (typeof tmpValue !== 'object') {
        value = tmpValue;
      }
    } else {
      if (typeof record[valueKey] !== 'undefined') {
        value = record[valueKey];
      }
    }
    value = _filter.ShFilter(value, row.filter);
    if (row.maxLength !== undefined) {
      value = value.substr(0, row.maxLength) + '...';
    }
    if (row.html) {
      return (
        <span className="data-table-value" dangerouslySetInnerHTML={{__html: value}}></span>
      );
    }
    return (
      <span className="data-table-value">{value}</span>
    );
  }

  // set body data
  const bodyData = records.map((record: Customer, index: number): JSX.Element => {
    let trStyle: { [key: string]: React.Key } = {};
    if (record.color_style !== undefined) {
      trStyle = _formatter.formatCssStyleToReactStyle(record.color_style);
    }
    return (
      <TableRow
        key={`agcp-tr-${index}`}
        style={{ textAlign: 'left' }}
      >
        {
          _usr_const.customerRows.map((row: DataTableRowsProps, index: number) => (
            <TableCell
              align={row.align}
              key={'tbody-' + index}
              padding="default"
              style={trStyle}
            >
              <span className="data-table-sp-label">{row.label}</span>
              {
                row.value !== undefined &&
                <span>
                  {cellData(row, record, row.value)}
                </span>
              }
            </TableCell>
          ))
        }
      </TableRow>
    )
  });

  const moveDomTop = (): void => {
    const el: any = dataTableRef.current;
    const domRect: any = el.getBoundingClientRect();
    if (window.scrollY > domRect.top) {
      window.scrollTo(0, domRect.top);
    }
  }

  const getPaginationPageNum = (): number => {
    if (paginate.mounted) {
      return queries.page;
    }
    return 0;
  }

  const handleChangePage = (event: any, page: number): void => {
    if (!unmounted.current) {
      moveDomTop();
      if (event !== null) {
        setQueries({ ...queries, page });
      }
    }
  };

  const handleChangeRowsPerPage = (event: any): void => {
    if (!unmounted.current) {
      moveDomTop();
      setQueries({
        ...queries,
        page: 0,
        limit: event.target.value
      });
    }
  };

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel('cancel post attribute group preview');
      unmounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (preview) {
      getData();
    }
  }, [preview, getData]);

  return (
    <Paper className="content-2 attribute-group-condition-preview" ref={dataTableRef}>
      <div className="agcp-btn-wr">
        <Button
          color="default"
          variant="outlined"
          onClick={handleClickPreview}
        >
          この条件でプレビュー
        </Button>
      </div>
      {
        preview &&
        <div>
          <Loading loading={loading} />
          {
            loading === false &&
            <div className="data-table">
              <TableContainer>
                <Table
                  style={{ width: '100%' }}
                  aria-label="conditions table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      {
                        _usr_const.customerRows.map((row: DataTableRowsProps, index: number) => (
                          <TableCell
                            key={'preview-thead-' + index}
                            style={{ padding: '4px 15px', textAlign: 'left' }}
                          >
                            {row.label}
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bodyData}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={perPageOptions}
                component="div"
                count={paginate.count}
                rowsPerPage={queries.limit}
                page={getPaginationPageNum()}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="表示数"
              />
            </div>
          }
        </div>
      }
    </Paper>
  );
}