import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataTable, {
  DataTableRowsProps,
  DataTableSearchInputParamsProps,
  DataTableHandleClickCellProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps } from '../../components/View/DataTableFilters';
import { ShMailLog } from '../../types/model';

export default function ShMailLogsIndex({ history, location }: RouteComponentProps) {

  const clickCell = (params: DataTableHandleClickCellProps<ShMailLog>): void => {
    history.push(location.pathname + '/' + params.record.id);
  }

  const rows: DataTableRowsProps<ShMailLog>[] = [
    {
      label: 'ID',
      value: 'id',
      sortField: 'ShMailLogs.id',
      align: 'left',
    },
    {
      label: '日時',
      value: 'created',
      filter: 'YMDHm',
      sortField: 'ShMailLogs.created',
      align: 'left',
    },
    {
      label: '状況',
      value: 'sent_status',
      align: 'left',
    },
    {
      label: '対象',
      value: 'target_name',
      align: 'left',
    },
    {
      label: '顧客名',
      value: 'customer_name',
      align: 'left',
    },
  ];

  const searchInputParams: DataTableSearchInputParamsProps[] = [
    {
      title: 'To',
      text: '部分一致',
    },
  ];

  const dataTableParams: DataTableFiltersProps[] = [
    {
      key: 'status',
      title: '状況',
      type: 'inlineSelect',
      options: [
        {
          text: '配信済み',
          value: 'succeed',
        },
        {
          text: '配信エラー',
          value: 'error',
        },
        {
          text: '対象外',
          value: 'excluded',
        },
      ]
    },
  ];

  return (
    <div id="sh-mail-logs" className="content-1">
      <DataTable<ShMailLog>
        rows={rows}
        checkbox={false}
        jsonPath='sh-mail-logs.json'
        urlQuery={true}
        parentProps={{ history, location }}
        handleClickCell={clickCell}
        searchInputParams={searchInputParams}
        dataTableParams={dataTableParams}
      />
    </div>
  )
}