import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import * as _form from '../../helper/form';
import * as _data_actions from '../../helper/dataActions';
import { ValidateErrorsProps } from '../../types/state';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ZshTextField from '../../components/Form/ZshTextField';
import DataTable, {
  DataTableActionsProps,
  DataTableRowsProps,
  DataTableFuncTbodyRowProps,
  DataTableActionsFuncProps
} from '../../components/View/DataTable';

type InflowSourceTypeDataType = {
  id?: string;
  name: string;
  is_hp: boolean;
}

const initialFormDara: InflowSourceTypeDataType = {
  name: '',
  is_hp: false,
}

const initialDara: InflowSourceTypeDataType = {
  id: '',
  name: '',
  is_hp: false,
}

export default function InflowSourceTypes () {

  const unmounted = useRef(false);

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const [formData, setFormData] = useState<InflowSourceTypeDataType>(initialFormDara);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showViewDialog, setShowViewDialog] = useState<boolean>(false);
  const [dataTableCallbackParams, setDataTableCallbackParams] = useState({
    getData: () => {},
    query: {},
  });
  const [data, setData] = useState<InflowSourceTypeDataType>(initialDara);
  const [formType, setFormType] = useState<'add' | 'edit'>('add');
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});

  const clickCell = (callbackParams: DataTableFuncTbodyRowProps) => {
    setDataTableCallbackParams(callbackParams);
    setData(callbackParams.record);
    setShowViewDialog(true);
  }

  const handleChangeFormData = (event: any): void  => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  }

  const handleClickAdd = (callbackParams: DataTableActionsFuncProps): void => {
    setDataTableCallbackParams(callbackParams);
    setFormType('add');
    setFormData({
      ...initialFormDara
    });
    setValidateErrors({});
    setShowDialog(true);
  }

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    setShowViewDialog(false);
    setFormType('edit');
    setFormData({
      ...data
    });
    setValidateErrors({});
    setShowDialog(true);
  }

  const formFinished = (): void => {
    dataTableCallbackParams.getData();
    setShowDialog(false);
    setShowViewDialog(false);
  }

  const handleAddSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'inflow-source-types',
        formData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError,
        setValidateErrors
      },
    );
  }

  const handleEditSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'inflow-source-types',
        formData,
        id: data.id,
        method: 'put',
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError,
        setValidateErrors
      },
    );
  }

  const handleDeletePost = (): void => {
    if (data.id !== undefined) {
      const selected: number[] = [Number(data.id)];
      _data_actions.deleteData({
        deleteUrl: 'inflow-source-types/isdelete/',
        selected: selected,
        getData: dataTableCallbackParams.getData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      });
    } else {
      setFlashMessageError('削除対象を取得できませんでした');
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '削除してよろしいですか？¥nこの操作は元に戻せません。',
      closeText: 'キャンセル',
      agreeText: '削除する',
      funcAgree: () => {
        handleDeletePost();
      },
      setZshDialog
    });
  }

  // clean up
  useEffect(() => {
    return () => {
      unmounted.current = true;
    }
  }, []);

  const isHpNode = (callbackParams: DataTableFuncTbodyRowProps): React.ReactNode => {
    return (
      <span>
        {
          callbackParams.record.is_hp === true &&
          <span>&#10004;</span>
        }
      </span>
    )
  }

  const rows: DataTableRowsProps<InflowSourceTypeDataType>[] = [
    {
      label: '区分名',
      value: 'name',
      align: 'left',
    },
    {
      label: 'HP区分',
      align: 'center',
      node: isHpNode
    },
  ];

  const actions: DataTableActionsProps<InflowSourceTypeDataType>[] = [
    {
      title: '追加',
      func: handleClickAdd,
      color: 'primary',
    },
  ];

  return (
    <Paper className="content-2">
      <h2 className="h-2">流入元区分マスタ</h2>
      <DataTable<InflowSourceTypeDataType>
        rows={rows}
        checkbox={false}
        jsonPath={'inflow-source-types.json'}
        urlQuery={false}
        actions={actions}
        filterGroupsInline={true}
        handleClickCell={clickCell}
        limit={10}
        size="small"
      />
      <Dialog
        fullWidth={true}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {
            formType === 'add' &&
            <span>
              流入元区分マスタ登録
            </span>
          }
          {
            formType === 'edit' &&
            <span>
              流入元区分マスタ編集
            </span>
          }
        </DialogTitle>
        <DialogContent>
          <form className="form-content dialog-form">
            <div className="view-content-in form-content">
              <div className="form-input-group">
                <ZshTextField
                  value={formData.name}
                  name="name"
                  handleChange={handleChangeFormData}
                  label="区分名"
                  fullWidth
                  required
                  validateErrors={validateErrors}
                />
              </div>
              <div className="form-input-group">
                <FormGroup>
                  <FormControlLabel
                    label="HP区分"
                    control={
                      <Checkbox
                        color="primary"
                        name="is_hp"
                        checked={formData.is_hp}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          setFormData({
                            ...formData,
                            is_hp: checked
                          });
                        }}
                      />
                    }
                  />
                </FormGroup>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
          onClick={() => {
            if (formType === 'add') {
              handleAddSubmit();
            }
            if (formType === 'edit') {
              handleEditSubmit();
            }
          }}
          color="primary"
          autoFocus>
            保存
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={showViewDialog}
        onClose={() => setShowViewDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>流入元区分マスタ詳細</DialogTitle>
        <DialogContent>
          <table className="table-1">
            <tbody>
              <tr>
                <th>区分名</th>
                <td>{data.name}</td>
              </tr>
              <tr>
                <th>HP区分</th>
                <td>
                  {
                    data.is_hp === true &&
                    <span>&#10004;</span>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowViewDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
            onClick={handleEdit}
            color="primary"
          >
            編集
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}