import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import * as _form from '../../helper/form';
import * as _data_actions from '../../helper/dataActions';
import { ValidateErrorsProps } from '../../types/state';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ZshTextField from '../../components/Form/ZshTextField';
import DataTable, {
  DataTableActionsProps,
  DataTableFuncTbodyRowProps,
  DataTableActionsFuncProps
} from '../../components/View/DataTable';
import { ActivityType } from '../../types/model';

type ActivityTypeFormProps = {
  id?: string;
  name: string;
  model: string;
}

const initialFormDara: ActivityTypeFormProps = {
  name: '',
  model: '',
}

export default function ActivityTypes() {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const [formData, setFormData] = useState<ActivityTypeFormProps>(initialFormDara);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dataTableCallbackParams, setDataTableCallbackParams] = useState({
    getData: () => {},
    query: {},
  });
  const [formType, setFormType] = useState<'add' | 'edit'>('add');
  const [validateErrors, setValidateErrors] = useState<ValidateErrorsProps>({});

  const handleChangeFormData = (event: any): void  => {
    setFormData({ ...formData, ..._form.getFormEventNameValue(event) });
  }

  const handleClickAdd = (callbackParams: DataTableActionsFuncProps): void => {
    setDataTableCallbackParams(callbackParams);
    setFormType('add');
    setFormData(initialFormDara);
    setValidateErrors({});
    setShowDialog(true);
  }

  const formFinished = (): void => {
    dataTableCallbackParams.getData();
    setShowDialog(false);
  }

  const handleAddSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'activity-types',
        formData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError,
        setValidateErrors
      },
    );
  }

  const handleEditSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'activity-types',
        formData,
        id: formData.id,
        method: 'put',
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError,
        setValidateErrors
      },
    );
  }

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, callbackParams: DataTableFuncTbodyRowProps): void => {
    event.stopPropagation();
    setDataTableCallbackParams(callbackParams);
    setFormType('edit');
    setFormData({
      ...callbackParams.record
    });
    setValidateErrors({});
    setShowDialog(true);
  }

  const handleDeletePost = (callbackParams: DataTableFuncTbodyRowProps) => {
    if (callbackParams.record !== undefined && callbackParams.record.id) {
      const selected: number[] = [Number(callbackParams.record.id)];
      _data_actions.deleteData({
        deleteUrl: 'activity-types/isdelete/',
        selected: selected,
        getData: callbackParams.getData,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      });
    } else {
      setFlashMessageError('削除対象を取得できませんでした');
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, callbackParams: DataTableFuncTbodyRowProps): void => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '削除してよろしいですか？¥nこの操作は元に戻せません。',
      closeText: 'キャンセル',
      agreeText: '削除する',
      funcAgree: () => {
        handleDeletePost(callbackParams);
      },
      setZshDialog
    });
  }

  const showTbodyRow = (params: DataTableFuncTbodyRowProps<ActivityType>): any => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div className="at-name">名前：{params.record.name}</div>
          <div className="at-model">モデル：{params.record.model}</div>
        </div>
        <div className="data-table-inline-btn-group">
          <IconButton aria-label="Edit" onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleEdit(event, params)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton aria-label="Delete" onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(event, params)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    );
  }

  const actions: DataTableActionsProps[] = [
    {
      title: '追加',
      func: handleClickAdd,
      color: 'primary',
    },
  ];

  return (
    <Paper className="content-2 activity-types">
      <h2 className="h-2">商談種別</h2>
      <DataTable<ActivityType>
        checkbox={false}
        jsonPath={'activity-types.json'}
        urlQuery={false}
        actions={actions}
        filterGroupsInline={true}
        funcTbodyRow={showTbodyRow}
        limit={10}
        size="small"
      />
      <Dialog
        fullWidth={true}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            formType === 'add' &&
            <span>
              商談種別登録
            </span>
          }
          {
            formType === 'edit' &&
            <span>
              商談種別編集
            </span>
          }
        </DialogTitle>
        <DialogContent>
          <form className="form-content dialog-form">
            <div className="view-content-in form-content">
              <div className="form-input-group">
                <ZshTextField
                  value={formData.name}
                  name="name"
                  handleChange={handleChangeFormData}
                  label="名前"
                  fullWidth
                  required
                  validateErrors={validateErrors}
                />
              </div>
              <div className="form-input-group">
                <ZshTextField
                  value={formData.model}
                  name="model"
                  handleChange={handleChangeFormData}
                  label="モデル"
                  fullWidth
                  required
                  validateErrors={validateErrors}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDialog(false)}
            color="default"
          >
            閉じる
          </Button>
          <Button
          onClick={() => {
            if (formType === 'add') {
              handleAddSubmit();
            }
            if (formType === 'edit') {
              handleEditSubmit();
            }
          }}
          color="primary"
          autoFocus>
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}