import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import * as _const from '../config/constant';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: 16,
      paddingBottom: 16,
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 4,
      paddingBottom: 4,
      color: 'rgba(255, 255, 255, 0.7)',
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: 16,
      paddingBottom: 16,
    },
    firebase: {
      fontSize: 24,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.common.white,
    },
    itemActionable: {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    itemActiveItem: {
      color: '#4fc3f7',
    },
    itemPrimary: {
      color: 'inherit',
      fontSize: theme.typography.fontSize,
    },
    divider: {
      marginTop: theme.spacing(2),
    },
    menuTitleNone: {
      display: 'none',
    },
    listItemIcon: {
      minWidth: '36px',
    }
  })
);

type NavigatorProps = {
  menus: _const.MenuType[];
  variant?: "permanent" | "persistent" | "temporary" | undefined;
  open?: boolean;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void | undefined;
  PaperProps?: any;
  handleDrawerClose: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

type Props = RouteComponentProps & NavigatorProps;

function Navigator({
  menus,
  variant,
  open,
  onClose,
  PaperProps,
  handleDrawerClose,
  location
}: Props) {

  const classes = useStyles();

  // set this path name
  let locationPath: string = '';
  if (location.pathname !== undefined) {
    const splitLocationPath: string[] = location.pathname.split('/');
    locationPath = '/' + splitLocationPath[1];
  }

  // format menus
  menus.forEach((category: _const.MenuType) => {
    // set menu title
    category['menuTitle'] = 'menuTitleNone';
    if (category['title'] !== '') {
      category['menuTitle'] = '';
    }
    if (category['children'].length > 0) {
      category['children'].forEach((child: _const.ChildMenuType) => {
        // set icon
        child['IconComponent'] = null;
        if (child['icon'] === undefined) {
          child['IconComponent'] = ListIcon;
        } else {
          child['IconComponent'] = child['icon'];
        }
        // set active
        child['active'] = false;
        if (child['to'] === locationPath) {
          child['active'] = true;
        }
      })
    }
  });

  return (
    <Drawer
      variant={variant}
      PaperProps={PaperProps}
      onClose={onClose}
      open={open}
    >
      <List disablePadding>
        <ListItem className={classNames(classes.firebase, classes.item, classes.itemCategory)}>
          Shift
          </ListItem>
        {menus.map(({ title, children, menuTitle, show }) => (
          <div key={title}>
            {
              show &&
              <React.Fragment>
                <ListItem className={classNames(classes.categoryHeader, menuTitle)}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {title}
                  </ListItemText>
                </ListItem>
                {children.map(({ title: childTitle, IconComponent, active, to, show, name }) => (
                  <div key={name}>
                    {
                      show &&
                      <Link to={to} key={childTitle} className="sideLink" onClick={handleDrawerClose}>
                        <ListItem
                          button
                          dense
                          key={childTitle}
                          className={classNames(
                            classes.item,
                            classes.itemActionable,
                            active && classes.itemActiveItem,
                          )}
                        >
                          {
                            IconComponent !== null &&
                            <ListItemIcon className={classes.listItemIcon}>
                              <IconComponent />
                            </ListItemIcon>
                          }
                          <ListItemText
                            classes={{
                              primary: classes.itemPrimary,
                            }}
                          >
                            {childTitle}
                          </ListItemText>
                        </ListItem>
                      </Link>
                    }
                  </div>
                ))}
                <Divider className={classes.divider} />
              </React.Fragment>
            }
          </div>
        ))}
      </List>
    </Drawer>
  );
}
export default withRouter(Navigator);