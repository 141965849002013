import * as _usr_const from '../../../config/usr-constant';
import * as _debug from '../../../helper/debug';
import axios from 'axios';

export type RoleNamesProps = {
  title: string;
  actions: {
    [key: string]: string;
  };
}

export type RoleFormDataProps = {
  name: string;
  role: { [key: string]: string[] };
}

type getRoleNamesProps = {
  unmounted: boolean,
  setRoleNames: React.Dispatch<React.SetStateAction<{}>>,
  setLoadings: React.Dispatch<React.SetStateAction<{ roleNames: boolean; }>>,
  setRoleNamesError: React.Dispatch<React.SetStateAction<boolean>>,
  source: any
}
export async function getRoleNames({
  unmounted,
  setRoleNames,
  setLoadings,
  setRoleNamesError,
  source
}: getRoleNamesProps) {
  // get data
  await axios
    .get(
      _usr_const.ApiUrl + 'roles/get-role-names.json', {
      cancelToken: source.current.token
    }
    )
    .then((response) => {
      if (typeof response.data !== 'undefined') {
        if (!unmounted) {
          setRoleNames(response.data.role_names);
          setLoadings({ roleNames: false });
        }
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
      if (!unmounted) {
        setRoleNamesError(true);
      }
    })
    .finally(() => {
      return Promise.resolve(1);
    });
}

type handleChangeRoleActionProps = {
  event?: React.ChangeEvent<HTMLInputElement>;
  checked?: boolean;
  roleKey?: string;
  formData: any;
}

export function handleChangeRoleAction({
  event,
  checked,
  roleKey,
  formData
}: handleChangeRoleActionProps): { [key: string]: string[] } {
  let role: { [key: string]: string[] } = Object.assign({}, formData.role);
  if (roleKey !== undefined) {
    const key: string = roleKey;
    if (role[key] === undefined) {
      role[key] = [];
    }
    if (event !== undefined) {
      if (checked === true) {
        role[key].push(event.target.name);
      } else {
        if (event.target.name === 'view') {
          role[key] = role[key].filter((action: string) => action !== 'edit');
          role[key] = role[key].filter((action: string) => action !== 'delete');
          role[key] = role[key].filter((action: string) => action !== 'csv_download');
        }
        role[key] = role[key].filter((action: string) => action !== event.target.name);
      }
    }
  }
  return role;
}