import React, { useState, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayProppress } from '../../atoms/OverlayProgress';
import { flashMessageSuccess, flashMessageError } from '../../atoms/FlashMessage';
import { zshDialog } from '../../atoms/ZshDialog';
import * as _usr_const from '../../config/usr-constant';
import * as _filter from '../../helper/filter';
import * as _form from '../../helper/form';
import * as _data_actions from '../../helper/dataActions';
import * as _debug from '../../helper/debug';
import { Memo } from '../../types/model';
import Paper from '@material-ui/core/Paper';
import DataTable, {
  DataTableActionsProps,
  DataTableHandleClickCellProps,
  DataTableFuncTbodyRowProps
} from '../../components/View/DataTable';
import { DataTableFiltersProps } from '../../components/View/DataTableFilters';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Notifications from '@material-ui/icons/Notifications';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ZshDateTimePicker from '../../components/Form/ZshDateTimePicker';
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';

type MemosProps = {
  commonParentId: number;
  customerId: number;
}

type MemoFormDataProps = {
  id: string;
  object_common_parent_id: string;
  customer_id: string;
  status: number | '';
  user_id: string;
  content: string;
  notice_datetime: null | string,
  pickers: {
    notice_datetime: null | string,
  },
}

const initialData: Memo = {
  id: 0,
  object_common_parent_id: 0,
  customer_id: 0,
  model_display: '',
  status_display: '',
  status: '',
  user_id: 0,
  user_name: '',
  content: '',
  notice_datetime: '',
  trim_content: '',
};

const initialFormDara: MemoFormDataProps = {
  id: '',
  object_common_parent_id: '',
  customer_id: '',
  status: '',
  user_id: '',
  content: '',
  notice_datetime: null,
  pickers: {
    notice_datetime: null,
  },
};

export default function Memos({ commonParentId, customerId }: MemosProps) {

  const setOverlayProppress = useSetRecoilState(overlayProppress);
  const setFlashMessageSuccess = useSetRecoilState(flashMessageSuccess);
  const setFlashMessageError = useSetRecoilState(flashMessageError);
  const setZshDialog = useSetRecoilState(zshDialog);

  const source = useRef(axios.CancelToken.source());
  const unmounted = useRef(false);

  const [data, setData] = useState<Memo>(initialData);
  const [formData, setFormData] = useState<MemoFormDataProps>(initialFormDara);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [dataTableCallbackParams, setDataTableCallbackParams] = useState({
    getData: () => { },
    query: {},
  });
  const [userList, setUserList] = useState<{ [key: string]: string } | undefined>(undefined);
  const [showNoticeDate, setShowNoticeDate] = useState<boolean>(false);

  const clickCell = (callbackParams: DataTableHandleClickCellProps<Memo>): void => {
    setData(callbackParams.record);
    setDialogType('view');
    setDataTableCallbackParams({
      ...dataTableCallbackParams,
      ...callbackParams
    });
    setShowDialog(true);
  }

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    params: DataTableFuncTbodyRowProps<Memo>): void => {
    event.stopPropagation();
    _data_actions.showConfirmDialog({
      message: '削除してよろしいですか？¥nこの操作は元に戻せません。',
      closeText: 'キャンセル',
      agreeText: '削除する',
      funcAgree: () => {
        handleDeletePost(params);
      },
      setZshDialog
    });
  }

  const handleDeletePost = (params: DataTableFuncTbodyRowProps<Memo>): void => {
    if (params.record !== undefined && params.record.id) {
      const selected: number[] = [Number(params.record.id)];
      _data_actions.deleteData({
        deleteUrl: 'memos/delete/',
        selected: selected,
        getData: params.getData,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      });
    } else {
      setFlashMessageError('削除対象を取得できませんでした');
    }
  }

  const handleViewClose = (): void => {
    setShowDialog(false);
    setDialogType('');
    setFormData(initialFormDara);
    setShowNoticeDate(false);
    setData(initialData);
  }

  const handleEdit = (): void => {
    const showNoticeDate: boolean = data.notice_datetime ? true : false;
    const tmpformData: MemoFormDataProps = _form.setFormData<MemoFormDataProps, Memo>(formData, data);
    setDialogType('edit');
    setShowNoticeDate(showNoticeDate);
    setFormData(tmpformData);
  }

  const handleAdd = (params: any): void => {
    let tmpData: Memo = Object.assign({}, data);
    tmpData['object_common_parent_id'] = commonParentId;
    tmpData['customer_id'] = customerId;
    tmpData['status'] = 1;
    tmpData['user_id'] = '';
    const tmpformData: MemoFormDataProps = _form.setFormData<MemoFormDataProps, Memo>(formData, tmpData);
    setDialogType('add');
    setDataTableCallbackParams(params);
    setData(tmpData);
    setFormData(tmpformData);
    setShowDialog(true);
  }

  const handleShowNoticeDateToggle = (): void => {
    let tmpformData: MemoFormDataProps = Object.assign({}, formData);
    if (showNoticeDate) {
      tmpformData['notice_datetime'] = null;
      tmpformData['pickers']['notice_datetime'] = null;
      setShowNoticeDate(false);
      setFormData(tmpformData);
    } else {
      let setNoticeDatatime = moment().hour(0).minutes(0).second(0);
      tmpformData['notice_datetime'] = setNoticeDatatime.toISOString(true);
      tmpformData['pickers']['notice_datetime'] = setNoticeDatatime.toISOString(true);
      setShowNoticeDate(true);
      setFormData(tmpformData);
    }
  }

  const handleChange = (name: any) => (event: any): void => {
    const value: any = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleDatePickerChange = (date: any, name: string): void => {
    const tmpFormData: MemoFormDataProps = _form.handleDatePickerChange<MemoFormDataProps>(formData, date, name);
    setFormData(tmpFormData);
  }

  const formFinished = (): void => {
    setOverlayProppress(false);
    dataTableCallbackParams.getData();
    handleViewClose();
  }

  const handleEditSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'memos',
        id: data.id,
        method: 'put',
        formData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      },
    );
  }

  const handleAddSubmit = (): void => {
    _form.handleSubmit(
      {
        action: 'memos',
        formData,
        callbackSuccess: formFinished,
        setOverlayProppress,
        setFlashMessageSuccess,
        setFlashMessageError
      },
    );
  }

  const showTbodyRow = (params: DataTableFuncTbodyRowProps<Memo>): JSX.Element => {
    return (
      <div className="data-table-inline-content">
        <div className="data-table-inline-content-text">
          <div>【{params.record.model_display}メモ】</div>
          <div>{params.record.trim_content}</div>
          <div>&lt; {params.record.status_display} &gt;</div>
          {
            params.record.notice_datetime !== null &&
            <div>
              <span style={{ paddingRight: '3px' }}>
                <Notifications style={{ fontSize: 18 }} />
              </span>
              {_filter.ShFilter(params.record.notice_datetime, 'YMDHm')}
            </div>
          }
          {
            params.record.user_name !== '' &&
            <div>(担当：{params.record.user_name})</div>
          }
        </div>
        <div className="data-table-inline-btn-group">
          <IconButton aria-label="Delete" onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(event, params)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    );
  }

  // clean up
  useEffect(() => {
    const clSource = Object.assign({}, source.current);
    return () => {
      // cancel axios get
      clSource.cancel();
      unmounted.current = true;
    }
  }, []);

  // get user list
  useEffect(() => {
    if (typeof userList === 'undefined') {
      axios
        .get(_usr_const.ApiUrl + 'memos/get-user-list.json', {
          cancelToken: source.current.token
        })
        .then((results: any) => {
          if (!unmounted.current) {
            setUserList(results.data.user_list);
          }
        })
        .catch((error: any) => {
          _debug.debugAxiosError(error);
          if (!unmounted.current) {
            setUserList({});
          }
        });
    }
  }, [userList]);

  const jsonPath: string = 'memos/index/' + commonParentId + '/index.json';

  const dataTableParams: DataTableFiltersProps[] = [
    {
      key: 'status',
      title: '状況',
      type: 'inlineSelect',
      options: [
        {
          text: '未対応',
          value: 1,
        },
        {
          text: '完了',
          value: 10,
        },
      ],
    },
  ];

  const dialogContents: any = {
    view: {
      title: 'メモ詳細',
      agreeText: '編集',
      agreeFunc: handleEdit,
      type: 'view',
    },
    edit: {
      title: 'メモ編集',
      agreeText: '保存',
      agreeFunc: handleEditSubmit,
      type: 'form',
    },
    add: {
      title: 'メモ追加',
      agreeText: '保存',
      agreeFunc: handleAddSubmit,
      type: 'form',
    },
  };
  const viewDialogType: string = dialogContents[dialogType] !== undefined ? dialogContents[dialogType].type : '';
  const dialogTitle: string = dialogContents[dialogType] !== undefined ? dialogContents[dialogType].title : '';
  const dialogAgreeText: string = dialogContents[dialogType] !== undefined ? dialogContents[dialogType].agreeText : '';
  const dialogAgreeFunc: () => void = dialogContents[dialogType] !== undefined ? dialogContents[dialogType].agreeFunc : () => { };

  const regex: RegExp = /([\n])/g;
  const nl2br = (str: string): any[] => {
    return str.split(regex).map((line: any, i: number) => {
      return line.match(regex) ? <br key={i} /> : line;
    });
  }

  const actions: DataTableActionsProps[] = [
    {
      title: '登録',
      func: handleAdd,
      color: 'primary',
    },
  ];

  const props: any = {
    commonParentId,
    customerId
  };

  return (
    <Paper className="content-2 memos">
      <h2 className="h-2">すべてのメモ</h2>
      {
        commonParentId !== 0 &&
        <DataTable<Memo>
          checkbox={false}
          jsonPath={jsonPath}
          urlQuery={false}
          dataTableParams={dataTableParams}
          parentProps={props}
          handleClickCell={clickCell}
          funcTbodyRow={showTbodyRow}
          actions={actions}
          filterGroupsInline={true}
          limit={5}
          size="small"
        />
      }
      <Dialog
        fullWidth={true}
        open={showDialog}
        onClose={handleViewClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          {
            viewDialogType === 'view' &&
            <table className="table-1">
              <tbody>
                <tr>
                  <th>種別</th>
                  <td>{data.model_display}</td>
                </tr>
                <tr>
                  <th>状況</th>
                  <td>{data.status_display}</td>
                </tr>
                <tr>
                  <th>通知日時</th>
                  <td>
                    {
                      data.notice_datetime !== '' &&
                      <span>
                        {_filter.ShFilter(data.notice_datetime, 'YMDHm')}
                      </span>
                    }
                  </td>
                </tr>
                <tr>
                  <th>担当</th>
                  <td>{data.user_name}</td>
                </tr>
                <tr>
                  <th>内容</th>
                  <td>{nl2br(data.content)}</td>
                </tr>
              </tbody>
            </table>
          }
          {
            viewDialogType === 'form' &&
            <form className="form-content dialog-form">
              <div className="view-content-in form-content">
                {
                  dialogType === 'edit' &&
                  <div className="form-input-group">
                    <FormControl>
                      <InputLabel htmlFor="status">状況</InputLabel>
                      <Select
                        value={formData.status}
                        onChange={handleChange('status')}
                        className="form-input prefecture"
                        inputProps={{
                          name: 'status',
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="1">未対応</MenuItem>
                        <MenuItem value="10">完了</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                }
                <div className="form-input-group">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showNoticeDate}
                        onChange={handleShowNoticeDateToggle}
                        value="1"
                        color="primary"
                      />
                    }
                    label="通知"
                  />
                </div>
                {
                  showNoticeDate &&
                  <div className="form-input-group">
                    <ZshDateTimePicker
                      label="通知"
                      name="notice_datetime"
                      value={formData.pickers.notice_datetime}
                      handleChange={(date: Date | null) => handleDatePickerChange(date, 'notice_datetime')}
                      format="YYYY/MM/DD HH:mm"
                      views={['date', 'hours']}
                    />
                  </div>
                }
                <div className="form-input-group">
                  <FormControl>
                    <InputLabel htmlFor="status">担当</InputLabel>
                    <Select
                      value={formData.user_id}
                      onChange={handleChange('user_id')}
                      className="form-input prefecture"
                      inputProps={{
                        name: 'user_id',
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      {
                        typeof userList !== 'undefined' &&
                        Object.keys(userList).map((key: string) => (
                          <MenuItem value={key} key={key}>{userList[key]}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="form-input-group">
                  <TextField
                    label="内容"
                    value={formData.content}
                    onChange={handleChange('content')}
                    margin="normal"
                    className="form-input form-textarea"
                    multiline={true}
                    fullWidth
                  />
                </div>
              </div>
            </form>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose} color="default">
            閉じる
            </Button>
          <Button onClick={dialogAgreeFunc} color="primary" autoFocus>
            {dialogAgreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}